import { createGlobalStyle, css } from "styled-components";
import NunitoRegular from "assets/fonts/Nunito-Regular.ttf";
import NunitoSemiBold from "assets/fonts/Nunito-SemiBold.ttf";
import NunitoBold from "assets/fonts/Nunito-Bold.ttf";
import NunitoExtraBold from "assets/fonts/Nunito-ExtraBold.ttf";
import { Fonts, Colors, ColorScheme } from "./contracts";

const general = css`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
  }
  html,
  body,
  #root {
    scroll-behavior: smooth;
  }
  body {
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    background: #fff;
    font-family: ${Fonts.NunitoRegular}, sans-serif;
    font-size: 16px;
    color: ${ColorScheme.Text};
    font-weight: normal;
    overflow-x: hidden;
  }
  body.no-scroll {
    overflow: hidden;
  }

  a {
    text-decoration: none;
  }
  button {
    font-family: ${Fonts.NunitoExtraBold};
    border: 0;
    cursor: pointer;
    background-color: transparent;
  }
  input,
  textarea {
    font-family: ${Fonts.NunitoRegular};
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: ${Fonts.NunitoExtraBold};
    color: ${ColorScheme.Header};
  }
  ul {
    list-style: none;
  }
`;

const animations = css`
  @keyframes ZoomIn {
    to {
      transform: scale(1.05);
    }
  }

  @keyframes ModalPopIn {
    from {
      transform: scale(0.2);
    }
    to {
      transform: scale(1);
    }
  }

  @keyframes ModalSlideUp {
    from {
      transform: translateY(240px);
    }
    to {
      transform: translateY(0%);
    }
  }

  @keyframes Rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }
`;

const fontface = css`
  @font-face {
    font-family: "Nunito-Regular";
    src: url(${NunitoRegular});
  }
  @font-face {
    font-family: "Nunito-SemiBold";
    src: url(${NunitoSemiBold});
  }
  @font-face {
    font-family: "Nunito-Bold";
    src: url(${NunitoBold});
  }
  @font-face {
    font-family: "Nunito-ExtraBold";
    src: url(${NunitoExtraBold});
  }
`;

const toastify = css`
  .Toastify {
    .toastsuccess {
      border-radius: 4px;
      background-color: ${Colors.Light3};
      color: ${ColorScheme.Header};
    }

    .toasterror {
      border-radius: 4px;
      background-color: ${Colors.Dark3};
      color: #fff;
    }

    .Toastify__toast-body {
      font-family: ${Fonts.NunitoSemiBold};
      font-size: 14px;
      padding: 0 16px;
      svg {
        fill: currentColor;
      }
    }

    button {
      color: currentColor;
    }

    .Toastify__progress-bar--animated {
      background: currentColor;
    }
  }
`;

export default createGlobalStyle`
    ${general};
    ${fontface};
    ${toastify};
    ${animations};
`;
