import React, { useRef, useCallback, useEffect, useState } from "react";
import ReactSelect, {
  Props as SelectProps,
  GroupBase,
  SelectInstance,
} from "react-select";
import { useField } from "@unform/core";
import { OptionBase } from "contracts/Common";

import * as S from "./styles";

interface Props extends SelectProps<OptionBase, false, GroupBase<OptionBase>> {
  name: string;
  id?: string;
  label?: string;
  placeholder?: string;
  onChange?: (option: OptionBase | null) => void;
}

export const Select: React.FC<Props> = ({
  name,
  id,
  label,
  options,
  placeholder = " ",
  onChange,
  ...rest
}) => {
  const selectRef = useRef(null);

  const { fieldName, defaultValue, registerField, error } = useField(name);
  const [hasSelection, setHasSelection] = useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_lastUpdate, setLastUpdate] = useState<Date>(new Date());

  const handleChange = useCallback(
    (option: OptionBase | null): void => {
      onChange && onChange(option);
      setHasSelection(option !== null);
    },
    [onChange]
  );

  const LabelComponent = useCallback((): JSX.Element => {
    if (!label) return <></>;
    return <S.FieldLabel htmlFor={id || fieldName}>{label}</S.FieldLabel>;
  }, [fieldName, id, label]);

  const ErrorComponent = useCallback((): JSX.Element => {
    if (!error) return <></>;
    return <S.FieldError>{error}</S.FieldError>;
  }, [error]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref: any) => {
        if (rest.isMulti) {
          if (!ref?.state?.selectValue) {
            return [];
          }
          return ref?.state?.selectValue.map(
            (option: OptionBase) => option.value
          );
        }

        return ref?.state?.selectValue[0]?.value || "";
      },
      setValue: (
        ref: SelectInstance<OptionBase, false, GroupBase<OptionBase>>,
        option: OptionBase
      ) => {
        ref?.setValue(option, "select-option", option);
        setLastUpdate(new Date());
      },
      clearValue(ref: any) {
        ref?.setValue(null, "select-option", null);
        setLastUpdate(new Date());
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  return (
    <S.Container>
      <LabelComponent />
      <ReactSelect
        className={hasSelection ? "has-selection" : ""}
        ref={selectRef}
        id={id || fieldName}
        name={fieldName}
        options={options}
        styles={S.DefaultSelectStyle}
        placeholder={placeholder}
        onChange={handleChange}
        defaultValue={defaultValue}
        isClearable={true}
        {...rest}
      />
      <ErrorComponent />
    </S.Container>
  );
};
