import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import {
  PaginateGalleriesActions as Actions,
  PaginateGalleriesState as State,
} from "store/ducks/galleries";
import { GalleryCard } from "components/Galleries";
import { Paginator } from "components/Shared";
import * as S from "./styles";

export const Main: React.FC = () => {
  const dispatch = useDispatch();
  const [query, setQuery] = useState({
    page: 1,
    limit: 9,
    content: "images",
  });

  const {
    data: galleries,
    pagination,
    loading,
  } = useSelector<RootStateOrAny, State>((state) => state.paginateGalleries);

  const handlePageChange = useCallback((page: number): void => {
    setQuery((state) => ({ ...state, page }));
  }, []);

  const fetchGalleries = useCallback((): void => {
    dispatch(Actions.request(query));
  }, [dispatch, query]);

  useEffect(() => {
    fetchGalleries();
  }, [fetchGalleries]);

  return (
    <S.Main>
      <S.Restrainer>
        <S.Header>
          <S.Title>
            Galerias de fotos {loading && <S.ActivityIndicator />}
          </S.Title>
        </S.Header>
        <S.GalleriesGrid>
          {galleries.map((gallery, index) => (
            <GalleryCard key={index} gallery={gallery} zoomOnHover={true} />
          ))}
        </S.GalleriesGrid>
        <S.PaginatorContainer>
          <Paginator pagination={pagination} onPageChange={handlePageChange} />
        </S.PaginatorContainer>
      </S.Restrainer>
    </S.Main>
  );
};
