import React from "react";

import * as C from "components/Pages/Sports/Gym";
import { PageBanner } from "components/Shared";
import Scaffold from "layouts/Scaffold";

export const GymPage: React.FC = () => {
  return (
    <Scaffold>
      <PageBanner
        image={require("assets/images/gym-banner.jpg")}
        title="Academia"
        subtitle="Confira Informações e horários."
      />
      <C.Main />
      <C.RelatedArticles />
    </Scaffold>
  );
};
