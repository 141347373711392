import { all, takeLatest } from "redux-saga/effects";

// ducks
import { PaginateArticleCategoriesTypes } from "../ducks/articleCategories";
import { FetchArticleTypes, PaginateArticlesTypes } from "../ducks/articles";
import { PaginateHomepageBannersTypes } from "../ducks/homepageBanners";
import { FetchGalleryTypes, PaginateGalleriesTypes } from "../ducks/galleries";
import { FetchSearchTypes } from "../ducks/generalSearch/";
import {
  SendContactMessageTypes,
  SendOmbudsmanMessageTypes,
} from "../ducks/messages";
import { FetchVideoTypes, PaginateVideosTypes } from "../ducks/videos";

// sagas
import { paginateArticleCategoriesRequest } from "./articleCategories";
import { fetchArticleRequest, paginateArticlesRequest } from "./articles";
import { paginateHomepageBannersRequest } from "./homepageBanners";
import { fetchGalleryRequest, paginateGalleriesRequest } from "./galleries";
import { fetchSearchRequest } from "./generalSearch";
import {
  SendContactMessageRequest,
  SendOmbudsmanMessageRequest,
} from "./messages";
import { fetchVideoRequest, paginateVideosRequest } from "./videos";

export default function* rootSaga() {
  yield all([
    // articles
    takeLatest(
      PaginateArticleCategoriesTypes.REQUEST,
      paginateArticleCategoriesRequest
    ),
    takeLatest(FetchArticleTypes.REQUEST, fetchArticleRequest),
    takeLatest(PaginateArticlesTypes.REQUEST, paginateArticlesRequest),
    // homepage banners
    takeLatest(
      PaginateHomepageBannersTypes.REQUEST,
      paginateHomepageBannersRequest
    ),
    // galleries
    takeLatest(FetchGalleryTypes.REQUEST, fetchGalleryRequest),
    takeLatest(PaginateGalleriesTypes.REQUEST, paginateGalleriesRequest),
    // messages
    takeLatest(SendContactMessageTypes.REQUEST, SendContactMessageRequest),
    takeLatest(SendOmbudsmanMessageTypes.REQUEST, SendOmbudsmanMessageRequest),
    // videos
    takeLatest(FetchVideoTypes.REQUEST, fetchVideoRequest),
    takeLatest(PaginateVideosTypes.REQUEST, paginateVideosRequest),
    // General Search
    takeLatest(FetchSearchTypes.REQUEST, fetchSearchRequest),
  ]);
}
