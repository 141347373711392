import styled from "styled-components";
import { Colors } from "styles/contracts";

export const Container = styled.div``;

export const Text = styled.p`
  &:not(:last-child) {
    margin-bottom: 26px;
  }
`;

export const Links = styled.ul``;

export const LinkItem = styled.li`
  margin-bottom: 16px;
`;

export const Link = styled.a`
  color: ${Colors.Red3};
  text-decoration: underline;
`;
