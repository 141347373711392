import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { ReactComponent as CaretDown } from "assets/icons/caret-down.svg";
import { Colors, Fonts } from "styles/contracts";

// handlers

const baseHandlerStyle = css`
  color: ${Colors.Red4};
  font-family: ${Fonts.NunitoBold};
  font-size: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;
  cursor: pointer;
`;

export const LinkHandler = styled(Link)`
  ${baseHandlerStyle}
`;

export const ExternalLinkHandler = styled.a`
  ${baseHandlerStyle}
`;

export const ActionHandler = styled.button`
  ${baseHandlerStyle}
`;

// in case of accordion

export const AccordionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

interface IAccordionHandler {
  isActive?: boolean;
}

const getAccordionActiveStyle = ({ isActive }: IAccordionHandler) => {
  if (!isActive) return css``;
  return css`
    svg {
      transform: rotate(180deg);
    }
  `;
};

export const AccordionHandler = styled.button<IAccordionHandler>`
  ${baseHandlerStyle};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${(props) => getAccordionActiveStyle(props)};
`;

export const CaretDownIcon = styled(CaretDown)``;

export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;

  animation: animateOptionsContainer 300ms ease-out;
  @keyframes animateOptionsContainer {
    from {
      max-height: 0;
      opacity: 0;
    }
    to {
      max-height: 1000px;
      opacity: 1;
    }
  }
`;

const baseOptionStyle = css`
  color: ${Colors.Dark3};
  font-family: ${Fonts.NunitoBold};
  font-size: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;
  cursor: pointer;
`;

export const LinkOption = styled(Link)`
  ${baseOptionStyle}
`;

export const ExternalLinkOption = styled.a`
  ${baseOptionStyle}
`;

export const ActionOption = styled.button`
  ${baseOptionStyle}
`;
