import styled from "styled-components";
import { Colors, ColorScheme } from "styles/contracts";
import { FieldContainer } from "styles/components";
export { FieldError, FieldLabel } from "styles/components";

export const Container = styled(FieldContainer)`
  input {
    border-radius: 6px;
    background: ${Colors.Light2};
    border: 1px solid ${Colors.Light5};
    color: ${ColorScheme.Text};
    height: 3rem;
    padding: 0 16px;
    font-size: inherit;
  }
`;
