import React, { useState } from "react";

import { Modal, ModalCloseButton } from "components/Shared";

import iconPDf from "../../../../assets/images/pdfIcon.png";
import * as S from "./styles";

export const Popup: React.FC = () => {
  const [open, setOpen] = useState(false);
  return (
    <Modal isOpen={open} onClickOutside={() => setOpen(false)}>
      <S.ModalContent>
        <S.ModalHeader>
          <S.GroupHeader>
            <ModalCloseButton onClick={() => setOpen(false)} />
            <h4>Processo Licitatório do Clube dos Funcionários da CSN</h4>
          </S.GroupHeader>
        </S.ModalHeader>
        <S.ModalBody>
          <S.Item href="/site/downloads/publicacao-diario-oficial.pdf">
            <img src={iconPDf} alt="pdf" />
            Publicação no Diário Oficial da União
          </S.Item>
          <S.Item href="/site/downloads/edital-licitacao-05-abr.pdf">
            <img src={iconPDf} alt="pdf" />
            Minuta do Edital de Licitação
          </S.Item>
        </S.ModalBody>
      </S.ModalContent>
    </Modal>
  );
};
