import React from "react";

import { PaginatedArticle } from "contracts/Articles";

import * as S from "./styles";

interface Props {
  article: PaginatedArticle;
  zoomOnHover?: boolean;
}

export const ArticleCard: React.FC<Props> = ({ article, zoomOnHover }) => {
  return (
    <S.ArticleCard
      zoomOnHover={zoomOnHover}
      to={`/noticias/${article.id}/${article.slug}`}
    >
      <S.ImageContainer>
        <S.CardImage src={article.image_url} alt={article.title} />
      </S.ImageContainer>
      <S.CardTitle>{article.title}</S.CardTitle>
      <S.CardFooter>
        <S.CategoryBadge>{article.category.name}</S.CategoryBadge>
      </S.CardFooter>
    </S.ArticleCard>
  );
};
