import { call, put } from "@redux-saga/core/effects";

import { api, apiErrorHandler, notify } from "services";
import { FetchSearchActions as Actions, FetchSearchRequestAction as RequestAction } from "store/ducks/generalSearch";

export function* fetchSearchRequest(action: any) {
  const { postData, onSuccess, onFailure }: RequestAction = action;
  try {
    const { data } = yield call(api.post, `sitemap`, postData);
    onSuccess && onSuccess();
    yield put(Actions.success(data));
  } catch (error) {
    const { errorMessage } = apiErrorHandler(error);
    onFailure && onFailure();
    notify("error", errorMessage);
    yield put(Actions.failure(errorMessage));
  }
}
