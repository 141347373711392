import React, { useState } from "react";

import { Accordion } from "components/Shared";
import {
  DocsEdital07,
  DocsEdital08,
  DocsEdital09,
  DocsEdital09a,
  DocsEdital09b,
  DocsEdital09c,
} from "data/cbcCovenant";

import * as S from "./styles";

const getDownloadUrl = (filename: string): string => {
  const baseUrl = "/site/cbc-documentos";
  return `${baseUrl}/${filename}`;
};

export const Main: React.FC = () => {
  const [edital7, setEdital7] = useState<boolean>(false);
  const [edital8, setEdital8] = useState<boolean>(false);
  const [edital9, setEdital9] = useState<boolean>(false);
  const [edital9a, setEdital9a] = useState<boolean>(false);
  const [edital9b, setEdital9b] = useState<boolean>(false);
  const [edital9c, setEdital9c] = useState<boolean>(false);
  return (
    <S.Main>
      <S.Restrainer>
        <S.MainContainer>
          <S.Title>Convênio CBC - Documentos</S.Title>
          <S.Accordions>
            <S.GroupAccordionItem>
              <Accordion
                key={1}
                title={`Edital 09 Lista 01`}
                isOpen={edital9a}
                onClick={() =>
                  edital9a ? setEdital9a(false) : setEdital9a(true)
                }
              >
                {DocsEdital09a.map((document, index) => (
                  <S.Document
                    key={index}
                    target="_blank"
                    href={getDownloadUrl(document.filename)}
                  >
                    {document.name}
                  </S.Document>
                ))}
              </Accordion>
            </S.GroupAccordionItem>

            <S.GroupAccordionItem>
              <Accordion
                key={2}
                title={`Edital 09 Lista 02`}
                isOpen={edital9b}
                onClick={() =>
                  edital9b ? setEdital9b(false) : setEdital9b(true)
                }
              >
                {DocsEdital09b.map((document, index) => (
                  <S.Document
                    key={index}
                    target="_blank"
                    href={getDownloadUrl(document.filename)}
                  >
                    {document.name}
                  </S.Document>
                ))}
              </Accordion>
            </S.GroupAccordionItem>

            {/* ##################################### */}

            <S.GroupAccordionItem>
              <Accordion
                key={3}
                title={`Edital 09 Lista 03`}
                isOpen={edital9c}
                onClick={() =>
                  edital9c ? setEdital9c(false) : setEdital9c(true)
                }
              >
                {DocsEdital09c.map((document, index) => (
                  <S.Document
                    key={index}
                    target="_blank"
                    href={getDownloadUrl(document.filename)}
                  >
                    {document.name}
                  </S.Document>
                ))}
              </Accordion>
            </S.GroupAccordionItem>

            {/* ########################### */}
            <S.GroupAccordionItem>
              <Accordion
                key={4}
                title={`Edital 09`}
                isOpen={edital9}
                onClick={() => (edital9 ? setEdital9(false) : setEdital9(true))}
              >
                {DocsEdital09.map((document, index) => (
                  <S.Document
                    key={index}
                    target="_blank"
                    // href={getDownloadUrl(document.filename)}
                    //href={document.link}
                    href={
                      document.link
                        ? document.link
                        : getDownloadUrl(document.filename)
                    }
                  >
                    {document.name}
                  </S.Document>
                ))}
              </Accordion>
            </S.GroupAccordionItem>

            {/* ########################### */}
            <S.GroupAccordionItem>
              <Accordion
                key={5}
                title={`Edital 08`}
                isOpen={edital8}
                onClick={() => (edital8 ? setEdital8(false) : setEdital8(true))}
              >
                {DocsEdital08.map((document, index) => (
                  <S.Document
                    key={index}
                    target="_blank"
                    href={getDownloadUrl(document.filename)}
                  >
                    {document.name}
                  </S.Document>
                ))}
              </Accordion>
            </S.GroupAccordionItem>

            {/* 333333333333333333333333333       */}
            <S.GroupAccordionItem>
              <Accordion
                key={6}
                title={`Edital 07`}
                isOpen={edital7}
                onClick={() => (edital7 ? setEdital7(false) : setEdital7(true))}
              >
                {DocsEdital07.map((document, index) => (
                  <S.Document
                    key={index}
                    target="_blank"
                    href={getDownloadUrl(document.filename)}
                  >
                    {document.name}
                  </S.Document>
                ))}
              </Accordion>
            </S.GroupAccordionItem>
          </S.Accordions>

          {/* <S.Documents>
            {covenantDocuments.map((document, index) => (
              <S.Document
                key={index}
                target="_blank"
                href={getDownloadUrl(document.filename)}
              >
                {document.name}
              </S.Document>
            ))}
          </S.Documents> */}
        </S.MainContainer>
      </S.Restrainer>
    </S.Main>
  );
};
