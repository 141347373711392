import React from "react";

import * as S from "./styles";

export const Main: React.FC = () => {
  return (
    <S.Main>
      <S.Restrainer>
        <S.MainContainer>
          <S.ContentBlock>
            <S.Title>Ginástica Artística</S.Title>
            <S.Text>
              A ginástica artística, também conhecida no Brasil por ginástica
              olímpica, é uma modalidade de ginástica onde os atletas realizam
              um conjunto de exercícios em aparelhos oficiais. Os movimentos dos
              ginastas devem ser elegantes e demonstrar força, agilidade,
              flexibilidade, coordenação, equilíbrio e controle do corpo.
            </S.Text>
            <S.ContentBlock>
              <S.Subtitle>A importância dos atestados médicos</S.Subtitle>
              <S.Text>
                É obrigatório entregar os atestados médicos para a prática das
                atividades esportivas no clube, de acordo com a LEI 2.014/92 de
                15/07/1992.
              </S.Text>
              <S.Text>
                O Atestado médico deve ser específico a cada uma das
                modalidades, evitando quaisquer problemas de saúde aos
                associados.
              </S.Text>
              <S.Text>
                Para verificar suas condições de saúde e a aptidão para a
                prática da atividade física pretendida, o Departamento de
                Esportes recomenda que o associado procure o médico de sua
                confiança para fazer todos os exames necessários
              </S.Text>

              <S.RedSubtitle>
                • As atividades esportivas para não sócios são permitidas apenas
                para menores de 18 anos.
              </S.RedSubtitle>
            </S.ContentBlock>
          </S.ContentBlock>
          <S.ColumnsView>
            <S.ContentBlock>
              <S.Subtitle>Horário das Aulas</S.Subtitle>
              <S.Text>
                Professora Cláudia (24) 9 9991-1089
                <br />
                Professora Sylvana (24) 9 9833-5290
                <br />
              </S.Text>
              <S.Text>
                <span>(Segunda e Quarta-feira)</span>
                <br />
                9h00 às 10h00 (a partir de 7 anos) - Turma lotada.
                <br />
                10h00 às 11h00 (4 à 6 anos) - Turma Lotada.
                <br />
                16h30 às 18h00 (apartir de 10 anos)
                <br />
                18h10 às 19h10 (5 à 12 anos) - Turma Lotada (não enviar para
                aula experimental0)
                <br />
                19h10 às 20h10 (4 à 7 anos) - Lotada
                <br />
                <br />
                <span>(Sexta-feira)</span>
                <br />
                18h00 ás 19h00h (4 à 6 anos) - Lotada
                <br />
                18h00 ás 19h30 (a partir de 7 anos) -Lotada
                <br />
                <br />
                (Sábado)
                <br />
                9h00 ás 10h00 (4 à 6 anos) - Lotada
                <br />
                10h00 ás 11h30 (a partir de 7 anos) - Lotada
                <br />
              </S.Text>

              <S.RedSubtitle>
                Lista de espera direto com as Professoras
              </S.RedSubtitle>
              <br />
              <S.Text>
                <span>Valores para sócios</span>
                <br />
                Mensalidade: R$ 163,00 (2x na semana, 1h de aula)
                <br />
                Mensalidade: R$ 206,00 (2x na semana, 1h30min de aula)
                <br />
                Mensalidade: R$ 91,00 (Sexta de 18h00 às 19h00)
                <br />
                Mensalidade: R$ 107,00 (Sexta de 18h00 às 19h30)
                <br />
                Mensalidade: R$ 91,00 (Sábado de 09h00 às 10h00)
                <br />
                Mensalidade: R$ 107,00 (Sábado de 10h00 às 11h30)
                <br />
              </S.Text>

              <S.Text>
                <span>Matrícula: R$ 50,00</span>
                <br />
                <span>Valores para não sócios</span>
                <br />
                Mensalidade: R$ 217,00 (2x na semana, 1h de aula)
                <br />
                Mensalidade: R$ 226,00 (2x na semana, 1h30min de aula)
                <br />
                Mensalidade: R$ 109,00 (Sexta de 18h00 às 19h00)
                <br />
                Mensalidade: R$ 116,00 (Sexta de 18h00 às 19h30)
                <br />
                Mensalidade: R$ 109,00 (Sábado de 09h00 às 10h00)
                <br />
                Mensalidade: R$ 116,00 (Sábado de 10h00 às 11h30)
                <br />
                <br />
                <span>Irmãos tem 10% de desconto</span>
              </S.Text>

              <S.Text>Local: Ginásio poliesportivo</S.Text>
            </S.ContentBlock>
          </S.ColumnsView>
        </S.MainContainer>
      </S.Restrainer>
    </S.Main>
  );
};
