import React from "react";
import { faq } from "data/faq";
import * as S from "./styles";

export const Main: React.FC = () => {
  return (
    <S.Main>
      <S.Restrainer>
        <S.MainContainer>
          <S.Title>Dúvidas frequentes</S.Title>
          <S.FAQList>
            {faq.map((item, index) => (
              <S.FAQItem key={index}>
                <S.Question>{item.question}</S.Question>
                <S.Answer dangerouslySetInnerHTML={{ __html: item.answer }} />
              </S.FAQItem>
            ))}
          </S.FAQList>
        </S.MainContainer>
      </S.Restrainer>
    </S.Main>
  );
};
