import React from "react";
import * as S from "./styles";

export const Main: React.FC = () => {
  return (
    <S.Main>
      <S.Restrainer>
        <S.MainContainer>
          <S.ContentBlock>
            <S.Title>Consultoria de corrida</S.Title>
            <S.Text>
              Com a ajuda de um profissional especializado, você prepara seu
              corpo para conquistar sua Corrida Perfeita, evitando dores e
              lesões.
            </S.Text>

            <S.ContentBlock>
              <S.Subtitle>A importância dos atestados médicos</S.Subtitle>
              <S.Text>
                É obrigatório entregar os atestados médicos para a prática das
                atividades esportivas no clube, de acordo com a LEI 2.014/92 de
                15/07/1992.
              </S.Text>
              <S.Text>
                O Atestado médico deve ser específico a cada uma das
                modalidades, evitando quaisquer problemas de saúde aos
                associados.
              </S.Text>
              <S.Text>
                Para verificar suas condições de saúde e a aptidão para a
                prática da atividade física pretendida, o Departamento de
                Esportes recomenda que o associado procure o médico de sua
                confiança para fazer todos os exames necessários
              </S.Text>
            </S.ContentBlock>
          </S.ContentBlock>
          <S.ColumnsView>
            <S.ContentBlock>
              <S.Subtitle>Horário das Aulas</S.Subtitle>
              <S.Text>
                <strong>Professora Breitner:</strong>
                <br />
                (Segunda à Sexta-feira)
                <br />
                07:00 ás 10:00
                <br />
                18:00 ás 21:00
                <br />
              </S.Text>
              <S.Text>
                OBS: Durante a Pandemia, os horários estarão sujeitos às
                determinações das autoridades
              </S.Text>
            </S.ContentBlock>
          </S.ColumnsView>
          <S.ContentBlock>
            <S.Text>
              <br />
              <br />
              <span>Mensalidade Sócio:</span>
              <br />
              <span>(Aula de 30 minutos)</span>
              <br />
              R$ 193,60 (aulas 1x na semana)
              <br />
              R$ 242,00 (aulas 2x na semana)
              <br />
              R$ 326,70 (aulas 3x na semana)
              <br />
              <br />
              <span>Mensalidade Não Sócio:</span>
              <br />
              <span>(Aula de 30 minutos)</span>
              <br />
              R$ 217,80 (aulas 1x na semana)
              <br />
              R$ 266,20 (aulas 2x na semana)
              <br />
              R$ 366,70 (aulas 3x na semana)
              <br />
            </S.Text>
            <S.Text>
              Matrícula sócio: R$ 20,00
              <br />
              matrícula não sócio: R$ 30,00
            </S.Text>
            <S.Text>
              <strong>Local:</strong> PET Clube dos Funcionários
            </S.Text>

            <S.Text>
              OBS: PREENCHER TERMO DE RESPONSABILIDADE PARA PRÁTICA DE ATIVIDADE
              FÍSICA.
            </S.Text>
          </S.ContentBlock>
        </S.MainContainer>
      </S.Restrainer>
    </S.Main>
  );
};
