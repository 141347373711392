import React from "react";

import * as S from "./styles";

export const Main: React.FC = () => {
  return (
    <S.Main>
      <S.Restrainer>
        <S.MainContainer>
          <S.ContentBlock>
            <S.Title>Treinamento Funcional</S.Title>
            <S.Text>
              O treinamento funcional se tornou recentemente uma nova moda, mas,
              acredite, ele é um dos tipos de treinamento mais antigos
              disponíveis.
            </S.Text>
            <S.Text>
              Inclusive, o treinamento funcional teve início na reabilitação
              física e foi criado por fisioterapeutas para potencializar a
              recuperação do paciente após uma lesão.
            </S.Text>

            <S.ContentBlock>
              <S.Subtitle>A importância dos atestados médicos</S.Subtitle>
              <S.Text>
                É obrigatório entregar os atestados médicos para a prática das
                atividades esportivas no clube, de acordo com a LEI 2.014/92 de
                15/07/1992.
              </S.Text>
              <S.Text>
                O Atestado médico deve ser específico a cada uma das
                modalidades, evitando quaisquer problemas de saúde aos
                associados.
              </S.Text>
              <S.Text>
                Para verificar suas condições de saúde e a aptidão para a
                prática da atividade física pretendida, o Departamento de
                Esportes recomenda que o associado procure o médico de sua
                confiança para fazer todos os exames necessários
              </S.Text>
            </S.ContentBlock>

            <S.ContentBlock>
              <S.Subtitle>Horário das Aulas</S.Subtitle>
              <S.Text>
                Professora Breitner (24) 9 9909-3829
                <br />
                (Segunda, quarta e sexta-feira)
              </S.Text>
              <S.Text>
                7h às 8h
                <br />
                18h às 19h
                <br />
              </S.Text>
              <S.Subtitle>Mensalidades:</S.Subtitle>
              <span>Aulas para sócios:</span>(1h de aula)
              <br />
              <S.Text>
                1 vez na semana - R$ 119,00
                <br />
                2 vezes na semana - R$ 180,00
                <br />
                3 vezes na semana - R$ 240,00
                <br />
              </S.Text>
              <S.Text>
                Aulas diárias avulso - R$ 25,00
                <br />
                Personal - R$ 70,00
                <br />
              </S.Text>
            </S.ContentBlock>
          </S.ContentBlock>
        </S.MainContainer>
      </S.Restrainer>
    </S.Main>
  );
};
