import React from "react";
import * as S from "./styles";

export const Main: React.FC = () => {
  return (
    <S.Main>
      <S.Restrainer>
        <S.MainContainer>
          <S.ContentBlock>
            <S.Title>Handball</S.Title>
            <S.Text>
              Existem várias explicações para a origem do handebol. Já na Grécia
              antiga, há indícios de um esporte praticado com as mãos, cuja bola
              tinha o tamanho de uma maçã. No entanto, o surgimento da
              modalidade da forma como hoje é praticada deu-se na metade do
              século XIX. Simultaneamente, um esporte similar ao atual handebol
              começou a ser jogado em várias localidades diferentes.
            </S.Text>
            <S.Text>
              A mais relevante dessas práticas destacou-se na Alemanha.
              Denominado raftball, era disputado em um gramado com dimensões de
              futebol. A partir de então, mas ainda como handebol de campo, a
              modalidade foi difundida pela Europa e passou a integrar o
              programa dos Jogos Olímpicos de Berlim, em 1936, na Alemanha.
            </S.Text>
            <S.Text>
              Porém, o crescimento do futebol e as dificuldades impostas pelo
              inverno rigoroso acabaram levando o handebol para o salão, e o
              esporte passou a ser disputado por apenas sete pessoas
              (inicialmente, eram onze praticantes).
            </S.Text>
            <S.Text>
              Em razão dessa fase de transição, a estréia da modalidade em Jogos
              Olímpicos só aconteceu em 1972, em Munique, na Alemanha, apenas
              com a competição masculina. As mulheres já disputariam medalha
              logo na edição de estréia, quatro anos mais tarde, no Canadá.
            </S.Text>
            <S.Text>
              A prática foi bem aceita e, em 1940, foi fundada a Federação
              Paulista de Handebol, com Otto Schemeling como seu primeiro
              presidente. O esporte só passou a ser disputado no salão com o
              passar dos anos, mas chamou a atenção da Confederação Brasileira
              de Desportos (antiga CBD), que, na época, regulava todo o esporte
              nacional.
            </S.Text>
            <S.Text>
              Foi criado, então, um departamento específico para a nova
              modalidade dentro da entidade, que passou a organizar campeonatos.
              O surgimento de uma confederação própria, porém, só aconteceria em
              1979, quando foi fundada a Confederação Brasileira de Handebol
              (CBHb).
            </S.Text>
            <S.Text>
              Apesar de ser bastante praticado no país, especialmente em escolas
              primárias, o handebol profissional nunca alcançou grandes
              resultados internacionais. Tanto no feminino quanto no masculino,
              a seleção verde-amarela sempre enfrentou dificuldades para chegar
              longe tanto nos Campeonatos Mundiais quanto nos Jogos Olímpicos,
              especialmente contra os europeus. Individualmente, porém, o Brasil
              tem se destacado. O carioca Bruno Souza, por exemplo, faz sucesso
              no Velho Continente atuando na liga alemã, a mais poderosa da
              atualidade. Em 2003, ele chegou a ser eleito o terceiro melhor
              jogador do mundo.
            </S.Text>

            <S.Subtitle>A importância dos atestados médicos</S.Subtitle>
            <S.Text>
              É obrigatório entregar os atestados médicos para a prática das
              atividades esportivas no clube, de acordo com a LEI 2.014/92 de
              15/07/1992.
            </S.Text>
            <S.Text>
              O Atestado médico deve ser específico a cada uma das modalidades,
              evitando quaisquer problemas de saúde aos associados.
            </S.Text>
            <S.Text>
              Para verificar suas condições de saúde e a aptidão para a prática
              da atividade física pretendida, o Departamento de Esportes
              recomenda que o associado procure o médico de sua confiança para
              fazer todos os exames necessários.
            </S.Text>
          </S.ContentBlock>
        </S.MainContainer>
      </S.Restrainer>
    </S.Main>
  );
};
