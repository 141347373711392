import React from "react";
import Scaffold from "layouts/Scaffold";
import { PageBanner } from "components/Shared";
import * as C from "components/Pages/Club/Anthem";

export const ClubAnthemPage: React.FC = () => {
  return (
    <Scaffold>
      <PageBanner image={require("assets/images/club-anthem-banner.jpg")} />
      <C.Main />
    </Scaffold>
  );
};
