import React from "react";

import * as S from "./styles";

export const Main: React.FC = () => {
  const srcLkn = [
    {
      id: 9,
      text: "DECLARAÇÃO DE IGUALDADE SALARIAL",
      link: "https://www.clubedosfuncionarios.com.br/site/downloads/DECLARACAO-DE-IGUALDADE-SALARIAL.PDF",
    },
    {
      id: 10,
      text: "ATA ASSEMBLEIA GERAL ORDINARIA - ELEIÇÃO PRESIDENTE E VICE - MANDATO 2021-2023 E PRESTAÇÃO DE CONTAS",
      link: "https://www.clubedosfuncionarios.com.br/site/downloads/ATA-ASSEMBLEIA-GERAL-ORDINARIA-ELEICAO-PRESIDENTE-E-VICE-MANDATO-2021-2023-E-PRESTACAO-DE-CONTAS.PDF",
    },
    {
      id: 11,
      text: "ATA Nº 31 - ASSEMBLEIA DE ASSOCIADOS - PROPRIETÁRIOS 2023",
      link: "https://www.clubedosfuncionarios.com.br/site/downloads/ATA-N-31-ASSEMBLEIA-DE-ASSOCIADOS-PROPRIETARIOS-2023.PDF",
    },
    {
      id: 12,
      text: "ATA Nº 68 - ASSEMBLEIA GERAL ORDINARIA - ELEIÇÃO PRESIDENTE E VICE - MANDATO 2017-2019 E PRESTAÇÃO DE CONTAS",
      link: "https://www.clubedosfuncionarios.com.br/site/downloads/ATA-N-68-ASSEMBLEIA-GERAL-ORDINARIA-ELEICAO-PRESIDENTE-E-VICE-MANDATO-2017-2019-E-PRESTACAO-DE-CONTAS.PDF",
    },
    {
      id: 13,
      text: "ATA Nº 70 - ASSEMBLEIA GERAL ORDINARIA - ELEIÇÃO PRESIDENTE E VICE - MANDATO 2019-2021 E PRESTAÇÃO DE CONTAS",
      link: "https://www.clubedosfuncionarios.com.br/site/downloads/ATA-N-70-ASSEMBLEIA-GERAL-ORDINARIA-ELEICAO-PRESIDENTE-E-VICE-MANDATO-2019-2021-E-PRESTACAO-DE-CONTAS.PDF",
    },
    {
      id: 14,
      text: "ATA Nº 71 - ASSEMBLEIA GERAL ORDINARIA - ELEIÇÃO  CONSELHO DELIBERATIVO 2020-2024 E PRESTAÇÃO DE CONTAS",
      link: "https://www.clubedosfuncionarios.com.br/site/downloads/ATA-N-71-ASSEMBLEIA-GERAL-ORDINARIA-ELEICAO-CONSELHO-DELIBERATIVO-2020-2024-E-PRESTACAO-DE-CONTAS.PDF",
    },
    {
      id: 15,
      text: "ATA Nº 72 - ASSEMBLEIA GERAL ORDINARIA - ELEIÇÃO CONSELHO DELIBERATIVO 2022-2026 E PRESTAÇÃO DE CONTAS",
      link: "https://www.clubedosfuncionarios.com.br/site/downloads/ATA-N-72-ASSEMBLEIA-GERAL-ORDINARIA-ELEICAO-CONSELHO-DELIBERATIVO-2022-2026-E-PRESTACAO-DE-CONTAS.PDF",
    },
    {
      id: 16,
      text: "ATA Nº 73 - ASSEMBLEIA GERAL ORDINARIA - ELEIÇÃO PRESIDENTE E VICE - MANDATO 2023-2025 E PRESTAÇÃO DE CONTAS",
      link: "https://www.clubedosfuncionarios.com.br/site/downloads/ATA-N-73-ASSEMBLEIA-GERAL-ORDINARIA-ELEICAO-PRESIDENTE-E-VICE-MANDATO-2023-2025-E-PRESTACAO-DE-CONTAS.PDF",
    },
    {
      id: 17,
      text: "ATA Nº 922 - DIRETORIA E SUBDIRETORIA - MANDATO 2021-2023",
      link: "https://www.clubedosfuncionarios.com.br/site/downloads/ATA-N-922-DIRETORIA-E-SUBDIRETORIA-MANDATO-2021-2023.PDF",
    },

    {
      id: 18,
      text: "ATA Nº 938 - COMISSÃO FISCAL- MANDATO 2022-2024",
      link: "https://www.clubedosfuncionarios.com.br/site/downloads/ATA-N-938-COMISSAO-FISCAL-MANDATO-2022-2024.PDF",
    },
    {
      id: 19,
      text: "ATA Nº 955 - DIRETORIA E SUBDIRETORIA- MANDATO 2023-2025",
      link: "https://www.clubedosfuncionarios.com.br/site/downloads/ATA-N-955-DIRETORIA-E-SUBDIRETORIA-MANDATO-2023-2025.PDF",
    },
    {
      id: 20,
      text: "ESTATUTO-VIGENTE",
      link: "https://www.clubedosfuncionarios.com.br/site/downloads/ESTATUTO-VIGENTE.pdf",
    },

    // {
    //   id: 9,
    //   text: "Ata da Assembéia Geral de eleição de Presidente e Vice 2023/2025",
    //   link: "https://www.clubedosfuncionarios.com.br/site/downloads/ATA-ASSEMBLEIA-GERAL-ELEICAO-PRESIDENTE-E-VICE 2023-2025.PDF",
    // },
    // {
    //   id: 8,
    //   text: "Ata de posse Diretoria 17/09",
    //   link: "https://www.clubedosfuncionarios.com.br/site/downloads/ata-de-posse-2017-2019.pdf",
    // },
    // {
    //   id: 7,
    //   text: "Ata de posse Diretoria 19/21",
    //   link: "https://www.clubedosfuncionarios.com.br/site/downloads/ata-de-posse-2019-2021.pdf",
    // },
    // {
    //   id: 6,
    //   text: "Ata da Diretoria",
    //   link: "https://www.clubedosfuncionarios.com.br/site/downloads/ata-da-diretoria.pdf",
    // },
    // {
    //   id: 5,
    //   text: "Ata da Presidência",
    //   link: "https://www.clubedosfuncionarios.com.br/site/downloads/ata-da-presidencia.pdf",
    // },
    // {
    //   id: 4,
    //   text: "Estatuto do Clube",
    //   link: "https://www.clubedosfuncionarios.com.br/site/downloads/estatuto-cfcsn.pdf",
    // },
    // {
    //   id: 3,
    //   text: "Ata da Assembléria Geral de Prestação de Contas 2021",
    //   link: "https://www.clubedosfuncionarios.com.br/site/downloads/ATA_ASSEMBLEIA_GERAL_PRESTACAO_CONTAS-2021.PDF",
    // },
    // {
    //   id: 1,
    //   text: "Ata de Aprovação de Contas 2021",
    //   link: "https://www.clubedosfuncionarios.com.br/site/downloads/ATA_ASSEMBLEIA_GERAL_PRESTACAO_CONTAS-2021.PDF",
    // },
  ];

  return (
    <S.Main>
      <S.Restrainer>
        <S.MainContainer>
          <S.Title>Documentos e Atas</S.Title>
          <S.GroupLinks>
            {srcLkn.map((item) => (
              <S.ItemDoc key={item.id} href={item.link} target="_blank">
                {item.text}
              </S.ItemDoc>
            ))}
          </S.GroupLinks>
        </S.MainContainer>
      </S.Restrainer>
    </S.Main>
  );
};
