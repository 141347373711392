import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import SwiperCore, { Autoplay } from "swiper";
import { Swiper, SwiperSlide, SwiperProps } from "swiper/react";
import {
  PaginateHomepageBannersActions as Actions,
  PaginateHomepageBannersState as State,
} from "store/ducks/homepageBanners";
import * as S from "./styles";

/**
 * Leave touchStartPreventDefault as false.
 * Otherwise, mousedown event on NavigationBar will not be triggered
 */
const swiperProps: SwiperProps = {
  spaceBetween: 0,
  loop: true,
  speed: 600,
  touchStartPreventDefault: false,
  modules: [Autoplay],
  autoplay: {
    delay: 5000,
    pauseOnMouseEnter: true,
    disableOnInteraction: false,
  },
};

export const BannersCarousel: React.FC = () => {
  const dispatch = useDispatch();
  const [swiper, setSwiper] = useState<SwiperCore | null>(null);

  const { data: banners } = useSelector<RootStateOrAny, State>(
    (state) => state.paginateHomepageBanners
  );

  console.log("valor debanners agora: ", banners);

  const PrevButton = useCallback((): JSX.Element => {
    if (!swiper) return <></>;
    return (
      <S.Button whileHover={{ scale: 1.2 }} onClick={() => swiper?.slidePrev()}>
        <S.PrevIcon />
      </S.Button>
    );
  }, [swiper]);

  const NextButton = useCallback((): JSX.Element => {
    if (!swiper) return <></>;
    return (
      <S.Button whileHover={{ scale: 1.2 }} onClick={() => swiper?.slideNext()}>
        <S.NextIcon />
      </S.Button>
    );
  }, [swiper]);

  const fetchBanners = useCallback((): void => {
    dispatch(Actions.request({ limit: 20 }));
  }, [dispatch]);

  useEffect(() => {
    fetchBanners();
  }, [fetchBanners]);

  return (
    <S.BannersCarousel>
      <Swiper onSwiper={setSwiper} {...swiperProps}>
        {banners.map((banner) =>
          banner.url === null ? (
            <SwiperSlide key={banner.id}>
              <S.BannerImage src={banner.image_url} alt="Banner" />
            </SwiperSlide>
          ) : (
            <SwiperSlide key={banner.id}>
              <S.BannerLink href={banner.url}>
                <S.BannerImage src={banner.image_url} alt="Banner" />
              </S.BannerLink>
            </SwiperSlide>
          )
        )}
      </Swiper>
      <S.BannersOverlay>
        <S.CarouselControls>
          <PrevButton />
          <NextButton />
        </S.CarouselControls>
      </S.BannersOverlay>
    </S.BannersCarousel>
  );
};
