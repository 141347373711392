import React from "react";
import ReactMapGL, { Marker } from "react-map-gl";
import * as S from "./styles";

const TOKEN =
  "pk.eyJ1IjoiZm9jb2NvbXVuaWNhY2FvIiwiYSI6ImNqeHA5dXA2MjBmazMzYnA5dnRjeHRnd3kifQ.AmRrcNghNEmQOMaTN05f5A";

const viewport = {
  latitude: -22.527924,
  longitude: -44.109093,
  zoom: 15,
  bearing: 0,
  pitch: 0,
};

export const Main: React.FC = () => {
  return (
    <S.Main>
      <S.Restrainer>
        <S.MainContainer>
          <S.Title>MVV - Missão, Visão e Valores</S.Title>

          <S.Subtitle>Missão:</S.Subtitle>
          <S.Text>
            Proporcionar aos sócios um ambiente seguro e acolhedor, promovendo
            momentos de lazer e integração que contribuem para a sua qualidade
            de vida, além de formar atletas e cidadãos melhores para a nossa
            sociedade.
          </S.Text>
          <S.Subtitle>Visão:</S.Subtitle>
          <S.Text>
            Permanecermos em pleno desenvolvimento e sermos reconhecidos como um
            dos 10 melhores clubes do Brasil.
          </S.Text>
          <S.Subtitle>Valores:</S.Subtitle>
          <S.Text>
            Ética, respeito, transparência e união são os valores sobre os quais
            o Clube dos Funcionários da CSN trabalha diariamente. Um clube,
            muitas famílias.
          </S.Text>
        </S.MainContainer>
      </S.Restrainer>
    </S.Main>
  );
};
