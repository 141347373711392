import React from "react";
import { useLocation, useRoutes } from "react-router-dom";
import routes from "./routes";

const AppRoutes: React.FC = () => {
  const location = useLocation();
  const myRoutes = useRoutes(routes({ isLoggedIn: true, location }));

  return <React.Fragment>{myRoutes}</React.Fragment>;
};

export default AppRoutes;
