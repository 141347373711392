import React from "react";

import { navigationMenuEntries } from "data/navigation_mobile";
import SearchBar from "layouts/Scaffold/SearchBar";

import MenuEntry from "./MenuEntry";
import * as S from "./styles";

const NavigationMenu: React.FC = () => {
  return (
    <S.NavigationMenu>
      <S.GroupSearch>
        <SearchBar />
      </S.GroupSearch>
      {navigationMenuEntries.map((entry) => (
        <MenuEntry key={entry.label} entry={entry} />
      ))}
    </S.NavigationMenu>
  );
};

export default NavigationMenu;
