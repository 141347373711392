import * as S from "./styles";

const AccessWidget: React.FC = () => {
  return (
    <S.AccessWidget>
      <S.GroupAccess>
        <S.PersonIcon />
        <S.WidgetTitle>Portal do Sócio:</S.WidgetTitle>

        <S.ButtonFoward
          target="_blank"
          href="https://portal.multiclubes.com.br/cfcsn/login.aspx"
        >
          Entrar
        </S.ButtonFoward>
        <S.SocialLink target="_blank" href="https://www.youtube.com/c/CFCSN">
          <img
            src={require("assets/icons/logo-canal-clube.png")}
            alt="Logo do canal do clube"
            className="logo-canal"
            title="Conheça nosso canal no Youtube, se inscreva e ative o sininho para receber as notificações"
          />
        </S.SocialLink>

        <S.SocialLink
          target="_blank"
          href="https://www.instagram.com/clubedosfuncionarios/"
          title="Confira as Fotos no Nosso Instagram"
        >
          <S.InstaIcon size={40} />
        </S.SocialLink>
        <S.SocialLink
          target="_blank"
          href="https://pt-br.facebook.com/clubedosfuncionarios"
          title="Curta nossa Página no Facebook"
        >
          <S.FaceIcon size={40} />
        </S.SocialLink>
      </S.GroupAccess>
    </S.AccessWidget>
  );
};

export default AccessWidget;
