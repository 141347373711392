export interface CovenantDocument {
  name: string;
  filename: string;
}

export const covenantDocuments: CovenantDocument[] = [
  // {
  //   name: "Relatório de Fincanças e Controle 2022",
  //   filename: "RELATORIO -DE-FINANCAS-CONTROLE-2022.PDF",
  // },
  {
    name: "Edital 07 - Convênio CBC - Eixo - Equipamentos e materiais exportivos",
    filename: "EXTRATO_TERMO_DE_EXECUCAO_EDITAL_07.PDF",
  },
  {
    name: "Edital 08 - Convênio CBC - Eixo - Recursos humanos",
    filename:
      "EXTRATO_TERMO_DE_EXECUCAO_EDITAL_08_EIXO_RECURSOS_HUMANOS_2020.PDF",
  },
  {
    name: "Edital 09 - Convênio CBC - Eixo - Equipamentos e materiais exportivos",
    filename: "EXTRATO_TERMO_DE_EXECUCAO_EDITAL_09.PDF",
  },
];
