import styled from "styled-components";
import { Colors } from "styles/contracts";

export const NavigationBar = styled.div`
  display: flex;
  flex-direction: row;
  position: sticky;
  bottom: 0;
  z-index: 99;
  width: 100%;
  border-radius: 8px;
  padding: 24px 40px;
  background: ${Colors.Red3};
  color: ${Colors.Light1};
`;

export const Menu = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;
