import React from "react";

import * as S from "./styles";

export const Main: React.FC = () => {
  return (
    <S.Main>
      <S.Restrainer>
        <S.MainContainer>
          <S.ContentBlock>
            <S.Title>Tênis</S.Title>
            <S.Text>
              O tênis, sob a direção de expedito gonçalves, sub diretor de
              tênis, continua satisfazendo os adeptos desta modalidade
              esportiva.
            </S.Text>
            <S.Text>
              Durante o ano são realizados torneios internos de reclassificação
              do ranking masculino/feminino adulto, infanto/juvenil até 18 anos
              e torneios interclubes.
            </S.Text>
            <S.Text>
              A direção esportiva do clube convida você e sua família a fazerem
              parte deste grupo, na certeza que serão bem recebidos. Contamos
              com professores capacitados e uma excelente infra-estrutura. Mais
              informações junto a secretaria do clube ou departamento de tênis,
              localizado junto às quadras.
            </S.Text>
            <S.Subtitle>A origem do Tênis</S.Subtitle>
            <S.Text>
              Presumem-se para o tênis ou outro jogo que foi seu precursor, uma
              origem remotíssima, com vestígios nas civilizações egípcias e
              persas, muitos séculos antes de Cristo. Entretanto, como origem
              histórica, aponta-se a criação na Índia, no ano de 1874, do jogo
              então denominado SPHAIRISTIKE, pelo major Walter Clopton do
              exército britânico, que patenteou o jogo baseado no longue-paume,
              jogo praticado na França nos séculos XV e XVI. Clopton inspirou-se
              neste jogo para criar o tênis e, após a boa receptividade do
              SPHAIRISTIKE na Índia, foi o mesmo levado para a Inglaterra, onde
              iniciou sua evolução técnica, transformando-se no law-tenis, e daí
              chegando ao ponto em que hoje se encontra. O law-tenis era
              praticado em terrenos gramados e dele, como se disse, surgiu o
              tênis moderno. A maior competição de tênis do mundo é o Campeonato
              de Wimbledon, lançado em 1877. O tênis é jogado individualmente
              (simples) ou em duplas. No Brasil, o esporte é dirigido pela
              Confederação Brasileira de Tênis e tem federações especializadas
              em vários Estados.
            </S.Text>

            <S.ContentBlock>
              <S.Subtitle>A importância dos atestados médicos</S.Subtitle>
              <S.Text>
                É obrigatório entregar os atestados médicos para a prática das
                atividades esportivas no clube, de acordo com a LEI 2.014/92 de
                15/07/1992.
              </S.Text>
              <S.Text>
                O Atestado médico deve ser específico a cada uma das
                modalidades, evitando quaisquer problemas de saúde aos
                associados.
              </S.Text>
              <S.Text>
                Para verificar suas condições de saúde e a aptidão para a
                prática da atividade física pretendida, o Departamento de
                Esportes recomenda que o associado procure o médico de sua
                confiança para fazer todos os exames necessários
              </S.Text>

              <S.RedSubtitle>
                • As atividades esportivas para não sócios são permitidas apenas
                para menores de 18 anos.
              </S.RedSubtitle>
            </S.ContentBlock>
            <S.Subtitle>Horário das aulas</S.Subtitle>
            <S.Text>
              Professor Thales (24) 9.9889-5240
              <br />
              (Aulas às Sextas-feiras)
            </S.Text>
            <S.Text>
              19h:00 às 20h:00
              <br />
              20h:00 às 21h:00
              <br />
            </S.Text>
            <S.Subtitle>Mensalidade</S.Subtitle>
            <S.Text>
              <span>Individual: </span> R$ 320,00
              <br />
              <span>Dupla: </span> R$ 220,00
              <br />
              <span>Coletivo: </span> R$ 180,00 (4 alunos)
              <br />
              <span>Aulas 1 vez na semana, com duração de 1h</span>
            </S.Text>
            <br />
            <hr />
            <S.RedSubtitle>
              Aula experimsental deve ser marcada direto com o professor
            </S.RedSubtitle>
            <br />
          </S.ContentBlock>
          <S.ContentBlock>
            <S.Subtitle>Professor Edison (24) 9.9908-0280</S.Subtitle>
            <S.Text>
              <span>(Quarta-feira)</span> - 18h00 às 21h00
              <br />
              <span>(Quinta-feira)</span> - 17h00 às 20h00
              <br />
              <br />
              <span>Matrículas para sócios:</span>
              <br />
              <span>Dupla: </span>R$ 320,00 (1 hora de Aula) <br />
              <span>Coletivo:(até 4 alunos) </span>R$ 210,00
              <br />
              <br />
              <span>Matrículas para Não sócios:</span>
              <br />
              <span>Dupla: </span>R$ 416,00 (1 hora de Aula) <br />
              <span>Coletivo:(até 4 alunos) </span>R$ 273,00 <br />
              <br />
              <S.RedSubtitle>
                Obs: Coletivo = 1 hora de aula semanal
              </S.RedSubtitle>
              <S.RedSubtitle>
                Obs: Individual = 1 hora ou 30 minutos aula semanal
              </S.RedSubtitle>
              <br />
              <br />
              <span>Aula Experimental</span>
            </S.Text>
          </S.ContentBlock>
          <S.ContentBlock>
            <S.Subtitle>Prof. Érico (24) 9 9822-6607</S.Subtitle>
            <S.Text>
              (Segunda-feira)
              <br />
              07h00 às 10h00
              <br />
              14h00 às 17h00
              <br />
            </S.Text>
            <S.Text>
              (Terça-feira)
              <br />
              16h00 às 17h00
              <br />
            </S.Text>
            <S.Text>
              (Quarta-feira)
              <br />
              07h00 às 10h00
              <br />
              14h00 às 15h00
              <br />
            </S.Text>
            <S.Text>
              (Sexta-feira)
              <br />
              07h00 às 10h00
              <br />
              9h00 às 10h00 (2 vagas para criança)
              <br />
              17h00 às 18h00
            </S.Text>
            <S.Subtitle>Mensalidades:</S.Subtitle>
            <S.Text>
              <span>Aulas Individuais</span> (1h por semana)
              <br />
              <span>Aulas individuais:</span> Sócios - R$ 320,00 / Não Sócio: R$
              440,00
              <br />
              <span>Aulas Coletivas:</span> Sócios - R$ 180,00 / Não Sócio: R$
              220,00 (até 4 alunos)
              <br />
              <span>Dupas:</span> Sócios - R$ 220,00 / Não Sócio: R$ 260,00
              <br />
            </S.Text>

            <S.Text>
              <span>Matrícula para não sócios: </span>R$ 50,00
            </S.Text>
            <S.Text>
              <span>Local:</span> PET - Quadra 01
            </S.Text>
          </S.ContentBlock>
        </S.MainContainer>
      </S.Restrainer>
    </S.Main>
  );
};
