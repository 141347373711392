import React, { useCallback, useRef, useState } from "react";
import { NavigationMenuEntry } from "contracts/Common";
import * as S from "./styles";

interface Props {
  entry: NavigationMenuEntry;
  deapth: number;
}

const SubmenuEntry: React.FC<Props> = ({ entry, deapth = 0 }) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const accordionContainer = useRef<HTMLDivElement>(null);

  const HandlerComponent = useCallback(
    ({ entry }: { entry: NavigationMenuEntry }): JSX.Element => {
      if (entry?.path) {
        return <S.LinkHandler to={entry.path}>{entry.label}</S.LinkHandler>;
      }

      if (entry?.externalPath) {
        return (
          <S.ExternalLinkHandler href={entry.externalPath} target="_blank">
            {entry.label}
          </S.ExternalLinkHandler>
        );
      }

      if (entry?.action) {
        return (
          <S.ActionHandler onClick={entry.action}>
            {entry.label}
          </S.ActionHandler>
        );
      }

      if (entry?.children) {
        return (
          <S.AccordionContainer ref={accordionContainer}>
            <S.AccordionHandler
              isActive={isActive}
              onClick={() => setIsActive((s) => !s)}
            >
              <span>{entry.label}</span> <S.CaretDownIcon />
            </S.AccordionHandler>
            {isActive && (
              <S.OptionsContainer>
                {entry.children.map((entry, index) => (
                  <SubmenuEntry key={index} entry={entry} deapth={deapth + 1} />
                ))}
              </S.OptionsContainer>
            )}
          </S.AccordionContainer>
        );
      }

      return <></>;
    },
    [deapth, isActive]
  );

  return <HandlerComponent entry={entry} />;
};

export default SubmenuEntry;
