import React from "react";

import * as C from "components/Pages/ClubEvents/Articles";
import Scaffold from "layouts/Scaffold";

export const EventsPage: React.FC = () => {
  return (
    <Scaffold>
      <C.Main />
    </Scaffold>
  );
};
