import styled from "styled-components";
import { Link } from "react-router-dom";
import { Colors, Fonts, ICard } from "styles/contracts";
import { getCardZoomOnHoverStyle } from "styles/functions";

export const ArticleCard = styled(Link)<ICard>`
  display: flex;
  flex-direction: column;
  height: 100%;
  ${(props) => getCardZoomOnHoverStyle(props)};
`;

export const ImageContainer = styled.figure`
  display: flex;
  overflow: hidden;
  border-radius: 4px;
  margin-bottom: 24px;
`;

export const CardImage = styled.img`
  max-width: 100%;
  height: auto;
`;

export const CardTitle = styled.h4`
  font-family: ${Fonts.NunitoBold};
  font-size: 18px;
  margin-bottom: 24px;
`;

export const CardFooter = styled.div`
  margin-top: auto;
`;

export const CategoryBadge = styled.span`
  display: inline-flex;
  font-family: ${Fonts.NunitoSemiBold};
  background: ${Colors.Red3};
  color: ${Colors.Light1};
  padding: 6px 14px;
  border-radius: 18px;
`;
