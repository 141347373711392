import React from "react";

import * as S from "./styles";

interface LinkItem {
  title: string;
  downloadUrl: string;
}

const links: LinkItem[] = [
  {
    title: "Ata da 26ª Assembléia Ordinária",
    downloadUrl: "ata-assembleia-socios-proprietarios.pdf",
  },
  {
    title: "1ª Ata da 27ª Assembléia Ordinária",
    downloadUrl: "ata27.pdf",
  },
  {
    title: "2ª Ata da 27ª Assembléia Ordinária",
    downloadUrl: "ata27-2.pdf",
  },
  {
    title: "1ª Ata da 28ª Assembléia Ordinária",
    downloadUrl: "ata28.pdf",
  },
  {
    title: "2ª Ata da 28ª Assembléia Ordinária",
    downloadUrl: "ata28-2.pdf",
  },
  {
    title: "1ª Ata da 30ª Assembléia Ordinária",
    downloadUrl: "ata30.pdf",
  },
];

export const GeneralAssembly: React.FC = () => {
  return (
    <S.Container>
      <S.Text>
        É constituída pelos sócios e presidida pelo presidente do Conselho
        Deliberativo.
      </S.Text>
      <S.Links>
        {links.map((link, index) => (
          <S.LinkItem key={index}>
            {link?.downloadUrl && (
              <S.Link
                href={`/site/downloads/${link.downloadUrl}`}
                target="_blank"
              >
                {link.title}
              </S.Link>
            )}
          </S.LinkItem>
        ))}
      </S.Links>
    </S.Container>
  );
};
