import styled from "styled-components";
import { Colors, ScreenSize } from "styles/contracts";
export { Restrainer } from "styles/components";

export const RelatedArticles = styled.div`
  background: ${Colors.Light3};
  padding: 64px 0 96px 0;
`;

export const Title = styled.h3`
  font-size: 26px;
  margin: 32px 0;
`;

export const ArticlesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 42px;

  @media ${ScreenSize.TabletAndDown} {
    grid-template-columns: 1fr 1fr;
  }

  @media ${ScreenSize.Mobile} {
    grid-template-columns: 1fr;
  }
`;
