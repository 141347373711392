import React from "react";

import { bornMembers, greatConcil } from "data/administration";

import * as S from "./styles";

export const GreatConcil: React.FC = () => {
  return (
    <S.Container>
      <S.MembersTitle>Grandes conselheiros</S.MembersTitle>
      <S.Members>
        {greatConcil.map((member, index) => (
          <S.Member key={index}>
            <S.ImageContainer>
              <S.Image
                src={`/site/images/greate-concil/${member.image}`}
                alt={`${member.name} 2`}
              />
            </S.ImageContainer>
            <S.Name>{member.name}</S.Name>
            {member?.role && <S.Role>{member.role}</S.Role>}
          </S.Member>
        ))}
      </S.Members>
      <S.MembersTitle>Membros natos</S.MembersTitle>
      <S.Members>
        {bornMembers.map((member, index) => (
          <S.Member key={index}>
            <S.ImageContainer>
              <S.Image
                src={`/site/images/born-members/${member.image}`}
                alt={member.name}
              />
            </S.ImageContainer>
            <S.Name>{member.name}</S.Name>
            {member?.role && <S.Role>{member.role}</S.Role>}
          </S.Member>
        ))}
      </S.Members>
      <S.Content>
        <S.Title>Constituição</S.Title>

        <S.TextGrid>
          <div>
            <S.Subtitle>Membros natos</S.Subtitle>
            <S.Text>
              20 sócios mais antigos
              <br />
              Os ex-presidentes do Clube
              <br />
              Os sócios Beneméritos
            </S.Text>
          </div>
          <div>
            <S.Subtitle>Membros temporários</S.Subtitle>
            <S.Text>
              O presidente do Clube
              <br />O Presidente do Conselho Deliberativo
            </S.Text>
          </div>
        </S.TextGrid>
        <S.Subtitle>Principais atribuições</S.Subtitle>
        <S.Text>
          Em grau de recurso, conhecer e julgar decisões do Conselho
          Deliberativo. Homologar proposta de concessão de títulos de Sócio
          Benemérito ou Honorário. Ratificar proposta, do Conselho Deliberativo,
          para destituição de Presidente e/ou Vice-presidente do Clube. Apreciar
          relatório anual de Diretoria.
        </S.Text>
      </S.Content>
    </S.Container>
  );
};
