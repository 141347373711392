import React from "react";

import * as S from "./styles";

const SubFooter: React.FC = () => {
  return (
    <S.SubFooter>
      <S.Restrainer>
        <S.Container>
          <S.Text>Todos os direitos reservados</S.Text>
          <S.Text>
            Desenvolvido por{" "}
            <S.Link href="https://fococomunicacao.com.br">
              Foco Comunicação
            </S.Link>
          </S.Text>
        </S.Container>
      </S.Restrainer>
    </S.SubFooter>
  );
};

export default SubFooter;
