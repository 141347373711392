import { toast } from "react-toastify";

type NotificationType = "error" | "success";

export function notify(type: NotificationType, message: string): void {
  if (type === "error") {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toasterror",
    });
  }
  if (type === "success") {
    toast.success(message, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toastsuccess",
    });
  }
}
