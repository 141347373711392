import styled, { css } from "styled-components";
import { motion } from "framer-motion";
import { ReactComponent as CaretLeft } from "assets/icons/caret-left.svg";
import { ReactComponent as CaretRight } from "assets/icons/caret-right.svg";
import { Colors } from "styles/contracts";
export { Restrainer, ActivityIndicator } from "styles/components";

export const ArticlesCarousel = styled.div``;

export const Title = styled.h3`
  font-size: 30px;
  margin: 32px 0;
`;

// carousel

interface ICarouselRestrainer {
  zoomOnHover?: boolean;
}

const getBaseRestrainerStyle = ({ zoomOnHover }: ICarouselRestrainer) => {
  if (!zoomOnHover) {
    return css`
      margin: 0 auto;
      max-width: 1100px;
      @media screen and (max-width: 1100px) {
        padding: 0 16px;
      }
    `;
  }

  return css`
    margin: -16px auto 0 auto;
    max-width: 1132px;
    .swiper-slide {
      padding: 16px;
    }
  `;
};

export const CarouselRestrainer = styled.div<ICarouselRestrainer>`
  width: 100%;
  ${(props) => getBaseRestrainerStyle(props)};
  .swiper-slide {
    height: auto;
  }
`;

export const CarouselContainer = styled.div`
  position: relative;
  overflow-x: visible;
  padding-bottom: 60px;
`;

export const Separator = styled.hr`
  height: 1px;
  border: none;
  background: ${Colors.Light6};
`;

export const CarouselControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  inset: 0 -92px 0 -92px;
  pointer-events: none;
  z-index: 1;
  @media screen and (max-width: 1270px) {
    display: none;
  }
`;

export const Button = styled(motion.button)`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${Colors.Light1};
  border: 2px solid ${Colors.Dark5};
  width: 44px;
  height: 44px;
  flex: 0 0 44px;
  border-radius: 22px;
  pointer-events: all;
`;

export const PrevIcon = styled(CaretLeft)``;
export const NextIcon = styled(CaretRight)``;
