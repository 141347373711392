import React from "react";

import * as S from "./styles";

export const Main: React.FC = () => {
  return (
    <S.Main>
      <S.Restrainer>
        <S.MainContainer>
          <S.Title>O Hino do Clube</S.Title>
          <S.Text>
            O tempo não apaga a imagem
            <br />
            Que eu guardo no meu coração
            <br />
            Clube dos Funcionários
            <br />
            Um alvi-grená campeão
          </S.Text>
          <S.Text>
            Tantas vitórias e lutas
            <br />
            Associados se encantam
            <br />
            Sua presença bem vinda
            <br />
            Na Vila de Volta Redonda
            <br />
            Seu passado, seu presente
            <br />
            Seu futuro vencedor
          </S.Text>
          <S.Text>
            Gerações que alegremente
            <br />
            Reconhecem seu valor
            <br />
            Escolinhas na infância
            <br />
            Trabalho de dedicação
            <br />
            Sua bandeira tremulando
            <br />
            Com todo amor e paixão.
          </S.Text>

          <S.DownloadContainer>
            <S.DownloadButton href="/site/audio/hino.mp3" target="_blank">
              Baixe o hino em formato MP3
            </S.DownloadButton>
          </S.DownloadContainer>
        </S.MainContainer>
      </S.Restrainer>
    </S.Main>
  );
};
