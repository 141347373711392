import styled from "styled-components";

export const GroupAreaSearch = styled.div`
  display: block;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 95%;
  max-width: 1100px;
  margin: -20px auto 30px auto;

  form {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    input.field {
      width: 100%;
      background: #fff;
      @media screen and (max-width: 1024px) {
        width: 98%;
      }
    }
    @media screen and (max-width: 1024px) {
      width: 80%;
      height: 50px;
    }
  }
`;

export const GroupField = styled.div`
  display: flex;
  width: 100%;
  padding: 10px;
  @media screen and (max-width: 1024px) {
    width: 100%;

    margin: 0;
  }
`;
export const GroupButton = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  width: 10%;
  padding: 10px 0;
`;

export const BtnSubmit = styled.button`
  background: #000;
  color: #fff;
  padding: 14px 20px;
  border-radius: 5px;
  font-weight: normal;
`;
