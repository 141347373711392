import React from "react";
import * as S from "./styles";

export const Main: React.FC = () => {
  return (
    <S.Main>
      <S.Restrainer>
        <S.MainContainer>
          <S.ContentBlock>
            <S.Title>Futebol Feminino</S.Title>
            <S.Text>
              As primeiras referências de partidas de futebol disputadas por
              mulheres surgiram nos anos 20. Os registros de jornais mostram a
              prática, ainda de forma muito tímida, no Rio de Janeiro, São Paulo
              e Rio Grande do Norte.
            </S.Text>
            <S.Text>
              Apenas em 1983 a modalidade foi regulamentada. Com isso, foi
              permitido que se pudesse competir, criar calendários, utilizar
              estádios, ensinar nas escolas. Clubes como o Radar e Saad surgem
              como pioneiros no profissionalismo. Eram alguns dos times
              competitivos da época.
            </S.Text>
            <S.Text>
              Hoje o esporte é amplamente difundido, com as mulheres
              demonstrando talento e habilidade dentro das 4 linhas. Venha
              treinar com a gente !
            </S.Text>

            <S.ContentBlock>
              <S.Subtitle>A importância dos atestados médicos</S.Subtitle>
              <S.Text>
                É obrigatório entregar os atestados médicos para a prática das
                atividades esportivas no clube, de acordo com a LEI 2.014/92 de
                15/07/1992.
              </S.Text>
              <S.Text>
                O Atestado médico deve ser específico a cada uma das
                modalidades, evitando quaisquer problemas de saúde aos
                associados.
              </S.Text>
              <S.Text>
                Para verificar suas condições de saúde e a aptidão para a
                prática da atividade física pretendida, o Departamento de
                Esportes recomenda que o associado procure o médico de sua
                confiança para fazer todos os exames necessários
              </S.Text>
            </S.ContentBlock>
          </S.ContentBlock>
          <S.ColumnsView>
            <S.ContentBlock>
              <S.Subtitle>Horário das Aulas</S.Subtitle>
              <S.Text>Nenhum período informado</S.Text>
            </S.ContentBlock>
          </S.ColumnsView>
          <S.ContentBlock>
            <S.Text>Local: PET Clube dos Funcionários</S.Text>

            <S.Text>
              OBS: PREENCHER TERMO DE RESPONSABILIDADE PARA PRÁTICA DE ATIVIDADE
              FÍSICA.
            </S.Text>
          </S.ContentBlock>
        </S.MainContainer>
      </S.Restrainer>
    </S.Main>
  );
};
