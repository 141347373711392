import React from "react";
import * as S from "./styles";

export const Main: React.FC = () => {
  return (
    <S.Main>
      <S.Restrainer>
        <S.MainContainer>
          <S.ContentBlock>
            <S.Title>
              Consultoria de Corrida com a professora Breitner
            </S.Title>
            <S.Text>
            O hábito de correr têm se tornado cada vez mais populare no país, seja por prazer ou para competir profissionalmente. Consequentemente, o mercado de produtos e serviços voltados para esse público também vem se destacando.
            </S.Text>
            <S.Text>
            Com um auxílio de um profissional, você prepara seu corpo por completo para conquistar sua Corrida Perfeita, evitando as dores no corpo e lesões.
            </S.Text>

            <S.ContentBlock>
              <S.Subtitle>A importância dos atestados médicos</S.Subtitle>
              <S.Text>É obrigatório entregar os atestados médicos para a prática das atividades esportivas no clube, de acordo com a LEI 2.014/92 de 15/07/1992.</S.Text>
              <S.Text>O Atestado médico deve ser específico a cada uma das modalidades, evitando quaisquer problemas de saúde aos associados.</S.Text>
              <S.Text>Para verificar suas condições de saúde e a aptidão para a prática da atividade física pretendida, o Departamento de Esportes recomenda que o associado procure o médico de sua confiança para fazer todos os exames necessários</S.Text>
            </S.ContentBlock>  
            
          </S.ContentBlock>
          <S.ColumnsView>
            <S.ContentBlock>
              <S.Subtitle>Horário das Aulas</S.Subtitle>
              <S.Text>
                <strong>Professor Breitner:</strong><br/>
                (Segunda à Sexta-feira)<br/>
                7h00 ás 10h<br/>
                18h00 às 21h<br/>
              </S.Text>
              
              
            </S.ContentBlock>
          </S.ColumnsView>
          <S.ContentBlock>
              <S.Text>
                <br/><br/>
                <span>Mensalidade Sócio:</span><br/>
                <span>(Aulas de 30 minutos)</span><br/>
                R$ 193,60 (aula 1x na semana)<br/>
                R$ 242,00 (aula 2x na semana)<br/>
                R$ 326,70 (aula 3x na semana)<br/><br/>

                <span>Mensalidade Não Sócio:</span><br/>
                <span>(Aulas de 30 minutos)</span><br/>
                R$ 217,80 (aula 1x na semana)<br/>
                R$ 266,20 (aulas 2x na semana)<br/>
                R$ 363,00,00 (aulas 3x na semana)<br/>
              </S.Text>
              <S.Text>
              Matrícula sócio: R$20,00<br/>
              matrícula não sócio: R$ 30,00
              </S.Text>
              <S.Text>
                <strong>Local:</strong> PET Clube dos Funcionários
              </S.Text>
          </S.ContentBlock>
        </S.MainContainer>
      </S.Restrainer>
    </S.Main>
  );
};
