import React, { useCallback, useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";

import loadImg from "assets/loading/loading.gif";
import { GalleryImageCard } from "components/Galleries";
import { Modal } from "components/Shared";
import { GalleryImage } from "contracts/Galleries";
import { baseURL } from "services";
import { FetchGalleryActions as Actions, FetchGalleryState as State } from "store/ducks/galleries";

import * as S from "./styles";

interface Props {
  galleryId: string | number;
}

export const Main: React.FC<Props> = ({ galleryId }) => {
  const dispatch = useDispatch();
  const { data: gallery, loading } = useSelector<RootStateOrAny, State>(
    (state) => state.fetchGallery
  );

  const [selectedImage, setSelectedImage] = useState<GalleryImage | null>(null);
  const [loadImage, setLoadImage] = useState<boolean>(false);

  const fetchGallery = useCallback((): void => {
    dispatch(Actions.request(galleryId));
  }, [dispatch, galleryId]);

  useEffect(() => {
    fetchGallery();
  }, [fetchGallery]);

  function handleImageNav(
    data: GalleryImage,
    fullGallery: any,
    action: string
  ) {
    setLoadImage(true);
    const currentRegister = fullGallery.filter(
      (element: GalleryImage) => element.id === data.id
    );
    const indexKey = fullGallery.indexOf(currentRegister[0]);

    const next = fullGallery[indexKey + 1];
    const prev = fullGallery[indexKey - 1];
    if (action === "next") {
      setSelectedImage(next);
    }
    if (action === "prev") {
      setSelectedImage(prev);
    }
  }

  return (
    <S.Main>
      <S.Restrainer>
        <S.Header>
          <S.Title>
            Fotos da galeria {loading && <S.ActivityIndicator />}
          </S.Title>
        </S.Header>

        <S.ImagesGrid>
          {gallery?.images.map((image, index) => (
            <GalleryImageCard
              key={index}
              image={image}
              zoomOnHover={true}
              onClick={(i) => setSelectedImage(i)}
            />
          ))}
        </S.ImagesGrid>
      </S.Restrainer>

      <Modal
        onClickOutside={() => setSelectedImage(null)}
        isOpen={!!selectedImage}
      >
        <S.ModalContent>
          <S.CloseButton onClick={() => setSelectedImage(null)}>
            &times;
          </S.CloseButton>
          {selectedImage && (
            <React.Fragment>
              <S.ImageContainer>
                <S.Image
                  src={selectedImage.image_url}
                  onLoad={() => setLoadImage(false)}
                />
                {loadImage && (
                  <S.AreaLoad>
                    <img src={loadImg} alt="load" />
                  </S.AreaLoad>
                )}
                <S.NavLinks
                  onClick={() =>
                    handleImageNav(selectedImage, gallery?.images, "next")
                  }
                  className="next"
                >
                  🠺
                </S.NavLinks>
                <S.NavLinks
                  onClick={() =>
                    handleImageNav(selectedImage, gallery?.images, "prev")
                  }
                  className="prev"
                >
                  🠸
                </S.NavLinks>
              </S.ImageContainer>
              <S.DownloadButton
                href={`${baseURL.api}/gallery-images/${selectedImage.id}/download`}
              >
                Fazer download <S.DownloadIcon />
              </S.DownloadButton>
            </React.Fragment>
          )}
        </S.ModalContent>
      </Modal>
    </S.Main>
  );
};
