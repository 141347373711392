import React from "react";
import Scaffold from "layouts/Scaffold";
import * as C from "components/Pages/Articles/Articles";

export const ArticlesPage: React.FC = () => {
  return (
    <Scaffold>
      <C.Main />
    </Scaffold>
  );
};
