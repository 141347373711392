import React from "react";

import * as C from "components/Pages/Home";
import Scaffold from "layouts/Scaffold";

export const HomePage: React.FC = () => {
  return (
    <Scaffold>
      <C.Popup />
      <C.BannersCarousel />

      <C.ArticlesCarousel />
      <C.Galleries />
      <C.Videos />
      {/* <C.Partners /> */}
    </Scaffold>
  );
};
