export interface AccountApproval {
  year: number;
  documents: {
    name: string;
    filename: string;
  }[];
}

export const approvals: AccountApproval[] = [
  {
    year: 2023,
    documents: [
      {
        name: "Aprovação de contas",
        filename: "CONTAS-2023.PDF",
      },
    ],
  },
  {
    year: 2022,
    documents: [
      {
        name: "Aprovação de contas",
        filename: "CONTAS-2022.PDF",
      },
    ],
  },
  {
    year: 2021,
    documents: [
      {
        name: "Aprovação de contas",
        filename: "CONTAS-2021.PDF",
      },
    ],
  },
  {
    year: 2020,
    documents: [
      {
        name: "Aprovação de contas",
        filename: "CONTAS_2020.PDF",
      },
    ],
  },
  {
    year: 2019,
    documents: [
      {
        name: "Aprovação de contas",
        filename: "CONTAS_2019.PDF",
      },
    ],
  },
  {
    year: 2018,
    documents: [
      {
        name: "Aprovação de contas",
        filename: "CONTAS_2018.PDF",
      },
    ],
  },
  {
    year: 2017,
    documents: [
      {
        name: "Aprovação de contas",
        filename: "CONTAS_2017.PDF",
      },
    ],
  },
];
