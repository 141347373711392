import styled from "styled-components";

interface IOverlay {
  isOpen?: boolean;
  padding?: string;
}

export const ModalOverlay = styled.div<IOverlay>`
  display: ${(props) => (props.isOpen ? "block" : "none")};
  padding: ${(props) => props.padding || "32px"};
  background-color: #000000aa;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 99;
  pointer-events: none;
`;

export const ContentContainer = styled.div.attrs({
  className: "content-wrapper",
})`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  div:first-child {
    pointer-events: all;
  }
`;

export const CloseButton = styled.button`
  background-color: transparent;
  margin-left: auto;
  :hover {
    animation: ZoomIn 200ms linear;
    animation-fill-mode: forwards;
  }
`;
