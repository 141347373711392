import styled from "styled-components";
import { ScreenSize } from "styles/contracts";
export { Restrainer } from "styles/components";

export const Partners = styled.div`
  padding: 64px 0 86px 0;
`;

export const Title = styled.h2`
  text-align: center;
  font-size: 30px;
  margin-bottom: 72px;
`;

export const PartnersGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 42px;
  max-width: 720px;
  width: 100%;
  margin: 0 auto;

  @media ${ScreenSize.SmallTabletAndDown} {
    max-width: 320px;
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const Partner = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 300ms ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
`;

export const PartnerImage = styled.img`
  max-width: 100%;
  height: auto;
`;
