import React from "react";

import * as S from "./styles";

export const Main: React.FC = () => {
  return (
    <S.Main>
      <S.Restrainer>
        <S.MainContainer>
          <S.ContentBlock>
            <S.Title>Futevôlei</S.Title>
            <S.Text>
              O futevôlei é uma modalidade de esporte de areia praticada
              geralmente em quadras montadas nas orlas. O esporte foi originado
              nas praias do Rio de Janeiro por volta de 1960 e, ao longo do
              tempo, cresceu dentro do Brasil, assim como na Europa, na Ásia e
              nos Estados Unidos.
            </S.Text>
            <S.Text>
              É um esporte com bola, numa quadra de vólei (bem parecida com a de
              vôlei de praia) com as medidas de 9 m de largura e 18 de
              comprimento, dividida ao meio por uma rede com 2,20 m de altura. É
              jogado em sistemas de duplas (2x2), trios (3x3) ou quartetos (4x4)
              masculinos, femininos ou mistos.
            </S.Text>
            <S.Text>
              Deve-se tocar a bola com qualquer parte do corpo, exceto os
              braços, antebraços e as mãos como no futebol. Cada dupla pode dar
              até 3 toques na bola, sendo que um jogador não pode tocar duas
              vezes seguidas tal como no vôlei ou vôlei de praia. O jogo é
              disputado, como no vôlei, em disputa de sets (de 18 pontos sem
              vantagem) em que os pontos são marcados quando a bola cai na
              quadra adversária, é desviada para fora das quadras pelos mesmos
              ou bate em qualquer outro jogador e bate no chão.
            </S.Text>

            <S.RedSubtitle>
              • As atividades esportivas para não sócios são permitidas apenas
              para menores de 18 anos.
            </S.RedSubtitle>
            <S.Subtitle>Professor: Breitner (24) 9.9909-3829</S.Subtitle>
            <S.Text>
              (Terça e Quinta-feira)
              <br />
              17h00 às 18h00
              <br />
              18h00 às 19h00
              <br />
            </S.Text>
            <S.Subtitle>Mensalidade:</S.Subtitle>
            <S.Text>
              <span>Sócios</span>
              <br />
              R$ 120,00 (2x na semana)
              <br />
              R$ 75,00 (1x na semana)
              <br />
              Diária R$ 20,00
            </S.Text>
            <S.Text>
              <span>Não Sócios</span>
              <br />
              Matrícula: 50,00
              <br />
              R$ 145,00 (2x na semana) <br />
              R$ 90,00 (1x na semana) <br />
              Diária R$ 22,00
              <br />
              12 anos - Turma mista
            </S.Text>
            <S.Text>
              <span>Idade mínia: </span> 12 anos - Turma mista
            </S.Text>

            <S.Text>
              • Preencher termo de responsabilidade para a prática de atividade
              física.
              <br />
              <br />
              Local: PET
            </S.Text>
          </S.ContentBlock>
        </S.MainContainer>
      </S.Restrainer>
    </S.Main>
  );
};
