import { call, put } from "@redux-saga/core/effects";
import { api, notify, apiErrorHandler } from "services";
import {
  SendContactMessageActions as Actions,
  SendContactMessageRequestAction as RequestAction,
} from "store/ducks/messages";

export function* SendContactMessageRequest(action: any) {
  const { postData, onSuccess, onFailure }: RequestAction = action;
  try {
    yield call(api.post, "/messages/contact", postData);
    onSuccess && onSuccess();
    notify("success", "Mensagem enviada com sucesso!");
    yield put(Actions.success());
  } catch (error) {
    const { errorMessage, validationErrors } = apiErrorHandler(error);
    onFailure && onFailure();
    notify("error", errorMessage);
    yield put(Actions.failure(errorMessage, validationErrors));
  }
}
