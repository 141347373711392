import React from "react";
import { PaginatedVideo } from "contracts/Videos";
import * as S from "./styles";

interface Props {
  video: PaginatedVideo;
  onClick: (video: PaginatedVideo) => void;
  zoomOnHover?: boolean;
}

export const VideoCard: React.FC<Props> = ({ video, onClick, zoomOnHover }) => {
  return (
    <S.VideoCard zoomOnHover={zoomOnHover} onClick={() => onClick(video)}>
      <S.ImageContainer>
        <S.CardImage src={video.image_url} alt={video.title} />
      </S.ImageContainer>
      <S.CardTitle>{video.title}</S.CardTitle>
    </S.VideoCard>
  );
};
