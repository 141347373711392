import { css } from "styled-components";
import { ICard, ScreenSize } from "./contracts";

export const getCardZoomOnHoverStyle = ({ zoomOnHover }: ICard) => {
  if (!zoomOnHover) return css``;
  return css`
    @media ${ScreenSize.SmallTabletAndUp} {
      transition: transform 200ms ease-in-out;
      &:hover {
        transform: scale(1.05);
      }
    }
  `;
};
