import styled from "styled-components";

export { ModalContent, ModalHeader, ModalBody } from "styles/components/modals";

export const GroupHeader = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Item = styled.a`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #000;
  margin-bottom: 15px;
  img {
    margin-right: 10px;
  }
`;
