import styled from "styled-components";
import { Colors, Fonts, ScreenSize } from "styles/contracts";
export { Restrainer } from "styles/components";

export const SubFooter = styled.div`
  background: ${Colors.Dark3};
  color: ${Colors.Light1};
  padding: 18px 0 30px 0;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media ${ScreenSize.Mobile} {
    gap: 16px;
    flex-direction: column;
  }
`;

export const Text = styled.p`
  font-family: ${Fonts.NunitoBold};
  font-size: 14px;
`;

export const Link = styled.a`
  color: currentColor;
`;
