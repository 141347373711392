import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";

import { Input } from "components/Shared";
import { useThrottle } from "hooks";
import {
  PaginateArticleCategoriesActions as Actions,
  PaginateArticleCategoriesState as State,
} from "store/ducks/articleCategories";

import * as S from "./styles";

import "swiper/css/autoplay";
import "swiper/css/navigation";

export interface FilterProps {
  category?: number;
  search?: string;
}

interface Props {
  isBusy: boolean;
  onChange?: (props: FilterProps) => void;
}

const SearchAndFilter: React.FC<Props> = ({ onChange, isBusy }) => {
  const formRef = useRef<FormHandles>(null);
  const dispatch = useDispatch();

  const { data: categories } = useSelector<RootStateOrAny, State>(
    (state) => state.paginateArticleCategories
  );

  const [filter, setFilter] = useState<FilterProps>({});
  const throttledFilter = useThrottle<FilterProps>(filter, 500);

  const handleSearch = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      setFilter((state) => ({
        ...state,
        category: 5,
        search: event.target.value,
      }));
    },
    []
  );

  const fetchCategories = useCallback((): void => {
    dispatch(Actions.request());
  }, [dispatch]);

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  useEffect(() => {
    if (onChange) {
      onChange(throttledFilter);
    }
  }, [throttledFilter, onChange]);

  return (
    <S.SearchAndFilter>
      <S.Restrainer maxWidth="720px">
        <S.Title>Buscar Evento {isBusy && <S.ActivityIndicator />}</S.Title>
        <Form ref={formRef} onSubmit={() => {}}>
          <Input
            type="search"
            name="search"
            placeholder="Buscar"
            onChange={handleSearch}
          />
        </Form>
      </S.Restrainer>
      <S.Restrainer maxWidth="720px"></S.Restrainer>
    </S.SearchAndFilter>
  );
};

export default SearchAndFilter;
