import React from "react";

import * as C from "components/Pages/Sports/Jogging";
import { PageBanner } from "components/Shared";
import Scaffold from "layouts/Scaffold";

export const JoggingPage: React.FC = () => {
  return (
    <Scaffold>
      <PageBanner
        image={require("assets/images/jogging-banner.jpg")}
        title="Consultoria de Corrida"
        subtitle="Confira informações e horários"
      />
      <C.Main />
      <C.RelatedArticles />
    </Scaffold>
  );
};
