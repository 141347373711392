import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { Autoplay } from "swiper";
import { Swiper, SwiperProps, SwiperSlide } from "swiper/react";

import { Input } from "components/Shared";
import { useThrottle } from "hooks";
import { PaginateArticleCategoriesActions as Actions, PaginateArticleCategoriesState as State } from "store/ducks/articleCategories";

import * as S from "./styles";

import "swiper/css/autoplay";
import "swiper/css/navigation";

export interface FilterProps {
  category?: number;
  search?: string;
}

interface Props {
  isBusy: boolean;
  onChange?: (props: FilterProps) => void;
}

const swiperProps: SwiperProps = {
  spaceBetween: 16,
  slidesPerView: 4,
  modules: [Autoplay],
  autoplay: {
    delay: 2000,
    disableOnInteraction: true,
  },
  breakpoints: {
    1: {
      slidesPerView: 1,
    },
    540: {
      slidesPerView: 2,
    },
    640: {
      slidesPerView: 4,
    },
  },
};

const SearchAndFilter: React.FC<Props> = ({ onChange, isBusy }) => {
  const formRef = useRef<FormHandles>(null);
  const dispatch = useDispatch();
  const { data: categories } = useSelector<RootStateOrAny, State>(
    (state) => state.paginateArticleCategories
  );
  const [filter, setFilter] = useState<FilterProps>({});
  const throttledFilter = useThrottle<FilterProps>(filter, 500);

  const handleSearch = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      setFilter((state) => ({ ...state, search: event.target.value }));
    },
    []
  );

  const handleCategoryChange = useCallback(
    (categoryId: number): void => {
      if (filter?.category === categoryId) {
        return setFilter((state) => ({ ...state, category: undefined }));
      }
      return setFilter((state) => ({ ...state, category: categoryId }));
    },
    [filter?.category]
  );

  const fetchCategories = useCallback((): void => {
    dispatch(Actions.request());
  }, [dispatch]);

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  useEffect(() => {
    if (onChange) {
      onChange(throttledFilter);
    }
  }, [throttledFilter, onChange]);

  return (
    <S.SearchAndFilter>
      <S.Restrainer maxWidth="720px">
        <S.Title>Últimas notícias {isBusy && <S.ActivityIndicator />}</S.Title>
        <Form ref={formRef} onSubmit={() => {}}>
          <Input
            type="search"
            name="search"
            placeholder="Buscar"
            onChange={handleSearch}
          />
        </Form>
      </S.Restrainer>
      <S.Restrainer maxWidth="720px">
        <S.Categories>
          <S.CategoriesTitle>Categorias:</S.CategoriesTitle>
          <Swiper {...swiperProps}>
            {categories.map((c) => (
              <SwiperSlide key={c.id}>
                <S.CategoryButton
                  slot="wrapper-start"
                  isActive={filter?.category === c.id}
                  onClick={() => handleCategoryChange(c.id)}
                >
                  {c.name}
                </S.CategoryButton>
              </SwiperSlide>
            ))}
          </Swiper>
        </S.Categories>
      </S.Restrainer>
    </S.SearchAndFilter>
  );
};

export default SearchAndFilter;
