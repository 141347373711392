import styled from "styled-components";

import { Colors } from "styles/contracts";

export { Restrainer } from "styles/components";

export const Main = styled.div`
  background: ${Colors.Light3};
  padding: 64px 0 96px 0;
`;

export const MainContainer = styled.div`
  background: ${Colors.Light1};
  border-radius: 8px;
  padding: 48px 60px 96px 60px;
`;

export const Title = styled.h4`
  font-size: 26px;
  margin-bottom: 24px;
`;

export const Documents = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Document = styled.a`
  display: block;
  width: 95%;
  margin-bottom: 8px;
  color: ${Colors.Dark3};
  text-decoration: underline;
  paddng: 3px;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;
export const Accordions = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
`;
export const GroupAccordionItem = styled.div`
display:block;
width:100%
height:auto;
margin-bottom:10px;
`;
