import React from "react";

import { NavigationMenuEntry } from "contracts/Common";
import { footerMenu } from "data/navigation";
import MenuEntry from "./MenuEntry";
import InfoBar from "./InfoBar";
import SubFooter from "./SubFooter";
import * as S from "./styles";

const group1: NavigationMenuEntry[] = footerMenu.slice(0, 5);
const group2: NavigationMenuEntry[] = footerMenu.slice(5, 8);
const group3: NavigationMenuEntry[] = footerMenu.slice(8);

const group4: NavigationMenuEntry[] = [
  {
    label: "Outros Links",
    children: [
      {
        label: "Tour 360º",
        externalPath: "https://www.clubedosfuncionarios.com.br/tour/",
      },
      {
        label: "Portal do Sócio",
        externalPath: "https://portal.multiclubes.com.br/cfcsn/login.aspx",
      },
      {
        label: "Instagram",
        externalPath: "https://www.instagram.com/clubedosfuncionarios/",
      },
      {
        label: "Facebook",
        externalPath: "https://www.facebook.com/clubedosfuncionarios",
      },
    ],
  },
];

const Footer: React.FC = () => {
  return (
    <S.Footer>
      <S.NavigationContainer>
        <S.Restrainer>
          <S.GroupsGrid>
            {[group1, group2, group3, group4].map((group, index) => (
              <S.Group key={index}>
                {group.map((entry, index) => (
                  <MenuEntry key={index} entry={entry} />
                ))}
              </S.Group>
            ))}
          </S.GroupsGrid>
        </S.Restrainer>
      </S.NavigationContainer>
      <InfoBar />
      <SubFooter />
    </S.Footer>
  );
};

export default Footer;
