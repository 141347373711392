export interface BudgetsPattern {
  year: number;
  documents: {
    name: string;
    filename: string;
  }[];
}

export const budgets: BudgetsPattern[] = [
  {
    year: 2024,
    documents: [
      {
        name: "Orçamento 2024",
        filename: "ORCAMENTO_2024.PDF",
      },
    ],
  },
  {
    year: 2023,
    documents: [
      {
        name: "Orçamento 2023",
        filename: "ORCAMENTO_2023.PDF",
      },
    ],
  },
  {
    year: 2022,
    documents: [
      {
        name: "Orçamento 2022",
        filename: "ORCAMENTO_2022.PDF",
      },
    ],
  },
  {
    year: 2021,
    documents: [
      {
        name: "Orçamento 2021",
        filename: "ORCAMENTO-2021.PDF",
      },
    ],
  },
];
