import styled, { css } from "styled-components";

import { ReactComponent as Caret } from "assets/icons/caret-down.svg";
import { Colors, Fonts } from "styles/contracts";

interface IAccordion {
  isOpen?: boolean;
}

const getAccordionStyles = ({ isOpen }: IAccordion) => {
  if (!isOpen) return css``;
  return css`
    > button svg {
      transform: rotate(180deg);
    }
    > div {
      max-height: 1000px;
      overflow-y: scroll;
      padding: 26px 26px;
      ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
      }
    }
  `;
};

export const Accordion = styled.div<IAccordion>`
  display: flex;
  flex-direction: column;
  ${(props) => getAccordionStyles(props)};
`;

export const Header = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: ${Colors.Light3};
  padding: 18px 24px;
  border-radius: 4px;
`;

export const Title = styled.h4`
  font-family: ${Fonts.NunitoBold};
  font-size: 16px;
`;

export const CaretIcon = styled(Caret)``;

export const Body = styled.div`
  max-height: 0;
  overflow: hidden;
  padding: 0 26px;
  transition: all 300ms ease-in-out;
`;
