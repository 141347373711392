import React from "react";
import Scaffold from "layouts/Scaffold";
import { PageBanner } from "components/Shared";
import * as C from "components/Pages/Club/Pet";

export const PetPage: React.FC = () => {
  return (
    <Scaffold>
      <PageBanner image={require("assets/images/club-pet-banner.jpg")} />
      <C.Main />
    </Scaffold>
  );
};
