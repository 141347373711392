import React from "react";

import * as S from "./styles";

export const Main: React.FC = () => {
  return (
    <S.Main>
      <S.Restrainer>
        <S.MainContainer>
          <S.ContentBlock>
            <S.Title>Voley</S.Title>
            <S.Text>
              O voleibol foi criado no ano de 1895 por William G. Morgan, então
              Diretor de Educação Física da Associação Cristã de Moços, de
              Holyoke. Massachusetts-USA. O nome original da nova modalidade
              esportiva, por motivos não conhecidos, era MINONETTE. Morgan tinha
              sob sua direção classes numerosa de homens de idade já um tanto
              avançada, dentro eles homens de negócios. O basquetebol, outro
              esporte que recentemente aparecera e que rapidamente se difundira,
              era muito enérgico e extenuante. Morgan achou, então, que seria
              melhor para seus alunos, notadamente os mais velhos, a prática de
              um jogo menos fatigante que o da bola ao cesto. Levantou, pois,
              uma rede semelhante à de tênis, a uma altura de um metro e oitenta
              e três centímetros sobre a qual uma câmara de bola de basquetebol
              era batida, surgindo assim o esporte que futuramente seria
              denominado de voleibol. A bola primitiva não satisfez, em virtude
              de ser muito leve, e a de basquetebol completa, que também foi
              usada, ao contrário, era muito pesada, machucando as mãos dos
              praticantes.
            </S.Text>

            <S.RedSubtitle>
              • As atividades esportivas para não sócios são permitidas apenas
              para menores de 18 anos.
            </S.RedSubtitle>
          </S.ContentBlock>

          <S.ContentBlock>
            <S.Subtitle>ESCOLINHA DE VÔLEI FEMININO</S.Subtitle>
            <S.Text>
              <span>Professora Patrícia - 99963-1904 </span>
              <br />
              <br />
              <span>(Segunda e Quarta-feira)</span>
              <br />
              15h00 às 16h30 - Pré Equipe Infantil - (2008/2009)
              <br />
              18h00 às 19h30 - Pré Equipe Mirim - (2011/2012/2013)
              <br />
              19h30 às 20h30 - Escolinha - (2006/2007/2008) -{" "}
              <strong>Lotado</strong>
              <br />
            </S.Text>
            <S.Text>
              <span>Mensalidade sócio: </span>R$ 69,00 (2x na semana) - R$ 88,50
              (Equipe)
              <br />
              <br />
              <span>Matrícula não sócio:</span> R$ 50,00
              <br />
              <span>Mensalidade não sócio: </span>R$ 116,50 (2x na semana) - R$
              166,00 (Equipe)
              <br />
              <br />
            </S.Text>
          </S.ContentBlock>

          <S.ContentBlock>
            <S.Subtitle>VÔLEI MASCULINO</S.Subtitle>
            <S.Text>
              <span>Professor Fabrício </span>
              <br />
              <br />
              <span>Escolinha</span>
              <br />
              (Terça e Quinta-feira)
              <br />
              17h00 ás 18h00 (de 13 anos às 17 anos) - 9 Vagas
              <br />
              <br />
              <span>Equipe - Mediante Autorização do Professor</span>
              <br />
              Terça e Quinta-feira - 16h00 às 17h30 (Equipe Infantil)
              <br />
              Terça e Quinta-feira - 18h30 às 20h00 (Equipe Infanto)
              <br />
              <br />
            </S.Text>
            <S.Text>
              <span>Mensalidade sócio:</span>
              <br />
              Escolinha: R$ 69,00 <br />
              Equipe: R$ 88,50 <br />
              <br />
              <br />
              <span>Matrícula não sócio:</span> R$ 50,00
              <br />
              <span>Mensalidades:</span>
              <br />
              Escolinha: R$ 116,50
              <br />
              Equipe: R$ 166,00
              <br />
              <br />
            </S.Text>
          </S.ContentBlock>
          <S.ContentBlock>
            <S.Subtitle>VÔLEI</S.Subtitle>
            <S.Text>
              <span>Professora Anielle França</span>
              <br />
              <br />
              (Segunda e Quarta-feira)
              <br />
              17h00 às 18h00 (2008/2009/2010) 13 à 15 anos (Limite 20 Alunos) -
              Turma Lotada
              <br />
              18h00 às 19h00 (2011/2012/2013) 10 à 12 anos (Limite 20 Alunos) -
              Turma Lotada
              <br />
              <br />
              (Terça e Quinta-feira)
              <br />
              <br />
              17h00 às 18h00 (2008/2009/2010) 13 à 17 anos (Limite 20 Alunos) -
              Turma Lotada
              <br />
              18h00 às 19h00 (2013/2014/2015) 08 à 10 anos (Limite 15 Alunos) -
              Turma Lotada
              <br />
              <S.RedSubtitle>Seguir Lista de Espera</S.RedSubtitle>
              <br />
            </S.Text>

            <S.Text>
              <span>Valores para sócios</span>
              <br />
              Mensalidade: R$ 76,00 (2x na semana) <br />
              Mensalidade: R$ 97,00 (3x na semana)
            </S.Text>
            <S.Text>
              <span>Valores para não sócios</span>
              <br />
              Matrícula: R$ 50,00
              <br />
              Mensalidade: R$ 129,00 (2x na semana) <br />
              Mensalidade: R$ 183,00 (3x na semana) <br />
            </S.Text>
            <S.Text>Local: Quadra Externa</S.Text>
          </S.ContentBlock>

          <S.ContentBlock>
            <br />
            <S.Subtitle>VÔLEI FEMININO EQUIPE</S.Subtitle>
            <S.Text>
              <span>
                Professores: Fabrício - Patrícia (24) 99963-1904 - Anielli (24)
                998285409
              </span>
            </S.Text>
            <S.Text>
              <span>Equipe Mirim (2009/2010)</span>
              <br />
              (Segunda e Quarta-feira)
              <br />
              16h30 às 18h00
              <br />
              (Sexta-feira)
              <br />
              14h00 às 15h30
            </S.Text>
            <S.Text>
              <span>Equipe Infantil (2007/2008)</span>
              <br />
              (Segunda e Quarta-feira)
              <br />
              14h45 às 16h30
              <br />
              (Sexta-feira)
              <br />
              15h00 às 16h30
            </S.Text>
            <S.Text>
              <span>Equipe Infanto (2005/2006)</span>
              <br />
              (Segunda e Quarta-feira)
              <br />
              19h00 às 21h00
              <br />
              (Sexta-feira)
              <br />
              16h30 às 16h00
            </S.Text>
            <S.Text>
              <span>Observações:</span>
              <br />
              • Equipes infanto e infantil são isentos de mensalidades (após
              avaliação do professor)
              <br />• Matrículas mediante autorização do professor
            </S.Text>
            <S.Text>
              <span>Valores para Equipe</span>
              <br />
              Mensalidade sócio: R$ 97,00 (3x na semana)
              <br />
              <br />
              Matrículo não sócio: R$ 50,00
              <br />
              Mensalidade não sócio: R$ 183,00 (3x na semana)
              <br />
            </S.Text>
          </S.ContentBlock>
          <S.ContentBlock>
            <S.Subtitle>A importância dos atestados médicos</S.Subtitle>
            <S.Text>
              É obrigatório entregar os atestados médicos para a prática das
              atividades esportivas no clube, de acordo com a LEI 2.014/92 de
              15/07/1992.
            </S.Text>
            <S.Text>
              O Atestado médico deve ser específico a cada uma das modalidades,
              evitando quaisquer problemas de saúde aos associados.
            </S.Text>
            <S.Text>
              Para verificar suas condições de saúde e a aptidão para a prática
              da atividade física pretendida, o Departamento de Esportes
              recomenda que o associado procure o médico de sua confiança para
              fazer todos os exames necessários
            </S.Text>
          </S.ContentBlock>
        </S.MainContainer>
      </S.Restrainer>
    </S.Main>
  );
};
