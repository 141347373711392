import React from "react";

import * as S from "./styles";

export const Main: React.FC = () => {
  return (
    <S.Main>
      <S.Restrainer>
        <S.MainContainer>
          <S.Title>PET - Praça de Esportes Tabajaras </S.Title>
          <S.Text>
            A Praça de Esportes Tabajaras é um espaço de lazer e diversão e
            conta com uma área de 144.000 m²
          </S.Text>
          <S.Text>
            Caracteríticas da praça de esportes:
            <br />
          </S.Text>
          <S.Text>
            1 ginásio poliesportivo para 1.300 pessoas sentadas(arquibancadas)
            e/ou 3.600 pessoas em pé na quadra
            <br />
            3 campos de futebol(sendo um sintético)
            <br />
            5 quadras de Tênis(saibro)
            <br />
            3 quadras poliesportivas
            <br />
            2 quadras de Padel
            <br />
            1 parque aquático com 4 piscinas (sendo 1 olímpica oficial, 1 tanque
            de saltos, 1 recreativa aquecida e 1 infantil)
            <br />
            1 área aberta(área verde) de aproximadamente 9.000m² para
            estacionamento ou para eventos até 20.000 pessoas
            <br />
            2 saunas(vapor e seca)
            <br />
            5 bares de atendimento
            <br />
            4 salões para festas
            <br />
            2 churrasqueiras
            <br />
            1 salão de beleza
            <br />
            1 academia
            <br />
            1 restaurante
            <br />1 área exclusiva para estacionamento com capacidade para 368
            carros
          </S.Text>
          <S.Text>
            <span>Endereço:</span> Rua Hiroshi Matsuda Filho, S/N - Vila Santa
            Cecília - Volta Redonda/RJ
            <br />
            CEP: 27261-260 (Antiga Rua 90)
            <br />
            <span>Telefone:</span> (24)2102-2750
          </S.Text>
          <S.Subtitle>Horário de funcionamento</S.Subtitle>
          <S.Text>
            <span>Segunda a sábado:</span> 6h:30 as 23h
            <br />
            <span>Feriados e Domingos:</span> 6h:30 as 20h
          </S.Text>
        </S.MainContainer>
      </S.Restrainer>
    </S.Main>
  );
};
