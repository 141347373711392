import styled from "styled-components";
import { Colors, Fonts, ScreenSize } from "styles/contracts";
import { BaseLinkButton } from "styles/components";
export { Restrainer, ActivityIndicator } from "styles/components";

export const Galleries = styled.div`
  padding: 26px 0 92px 0;
`;

export const Title = styled.h3`
  font-size: 30px;
  margin: 32px 0;
`;

export const GalleriesGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0 42px;
  @media ${ScreenSize.Mobile} {
    grid-template-columns: 1fr;
  }
`;

export const GalleriesFooter = styled.div`
  padding: 14px 0;
  display: flex;
  flex-direction: row;
  @media ${ScreenSize.Mobile} {
    justify-content: center;
  }
`;

export const MoreButton = styled(BaseLinkButton)`
  font-family: ${Fonts.NunitoSemiBold};
  border-radius: 24px;
  padding: 0 42px;
  color: ${Colors.Dark4};
  border: 1px solid ${Colors.Light6};
`;
