import React from "react";

import * as S from "./styles";

export const Main: React.FC = () => {
  return (
    <S.Main>
      <S.Restrainer>
        <S.MainContainer>
          <S.ContentBlock>
            <S.Title>Padel</S.Title>
            <S.Text>
              O Padel é um esporte de raquetes disputado em duplas muito similar
              ao tênis. As diferenças ficam por conta da quadra que possui
              paredes tanto no fundo como em parte das laterais que recolocam a
              bola em jogo dando mais emoção e dinamismo a cada ponto disputado,
              a bolinha nunca para! É um esporte que vem crescendo muito como
              opção de lazer para amadores já que o jogo não exige refinamento
              técnico e é de fácil aprendizado. Em poucos minutos é possível
              fazer um excelente exercício físico aliado a pura diversão.
            </S.Text>

            <S.ContentBlock>
              <S.Subtitle>A importância dos atestados médicos</S.Subtitle>
              <S.Text>
                É obrigatório entregar os atestados médicos para a prática das
                atividades esportivas no clube, de acordo com a LEI 2.014/92 de
                15/07/1992.
              </S.Text>
              <S.Text>
                O Atestado médico deve ser específico a cada uma das
                modalidades, evitando quaisquer problemas de saúde aos
                associados.
              </S.Text>
              <S.Text>
                Para verificar suas condições de saúde e a aptidão para a
                prática da atividade física pretendida, o Departamento de
                Esportes recomenda que o associado procure o médico de sua
                confiança para fazer todos os exames necessários
              </S.Text>

              <S.RedSubtitle>
                • As atividades esportivas para não sócios são permitidas apenas
                para menores de 18 anos.
              </S.RedSubtitle>
            </S.ContentBlock>
            <S.ContentBlock>
              <S.Subtitle>Horário das Aulas</S.Subtitle>
              <S.Text>
                <span>Professor Érico (24) 9 9822-6607</span>
                <br />
                (Segunda, terça e quarta-feira)
                <br />
                <br />
                7h00 às 10h00
                <br />
                16h00 às 19h00
                <br />
                <br />
                (Quinta-feira)
                <br />
                7h00 às 10h00
                <br />
                <br />
                (Sexta-feira)
                <br />
                18h00 às 19h00
                <br />
                <br />
                (Sábados)
                <br />
                7h00 às 9h00
                <br />
                <br />
              </S.Text>
              <S.Subtitle>Mensalidade</S.Subtitle>
              <S.Text>
                <span>Matrícula Não Sócio: </span>R$ 50,00
                <br />
                <br />
                Aulas Individuais:
                <br />
                <span>Sócio: </span>R$ 280,00
                <br />
                <span>Não sócio: </span>R$ 340,00
                <br />
                <br />
                Aulas em Dupla:
                <br />
                <span>Sócio: </span>R$ 220,00
                <br />
                <span>Não sócio: </span>R$ 260,00
                <br />
                <br />
                Aulas Coletivas/Escolinha:
                <br />
                (Até 4 alunos)
                <br />
                <span>Sócio: </span>R$ 160,00
                <br />
                <span>Não sócio: </span>R$ 220,00
                <br />
                <br />
                <span>* Aulas 1 vez na semana</span>
              </S.Text>
              <S.Text>Local: PET - Quadra 01</S.Text>
            </S.ContentBlock>
          </S.ContentBlock>
        </S.MainContainer>
      </S.Restrainer>
    </S.Main>
  );
};
