import styled from "styled-components";
import { Colors, ScreenSize } from "styles/contracts";
export { Restrainer } from "styles/components";

export const Footer = styled.footer``;

// Navigation

export const NavigationContainer = styled.div`
  background: ${Colors.Red4};
  color: ${Colors.Light1};
  padding: 60px 0;
`;

export const GroupsGrid = styled.div`
  display: grid;
  grid-template-areas: "group1 group2 group3 groop3";
  grid-template-columns: 1fr 1fr 2fr;
  gap: 42px;

  @media ${ScreenSize.TabletAndDown} {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "group1 group2"
      "group3 group3";
    gap: 24px;
  }

  @media ${ScreenSize.Mobile} {
    grid-template-columns: 1fr;
    grid-template-areas: "group1" "group2" "group3";
    gap: 0;
  }
`;

export const Group = styled.div`
  display: flex;
  flex-direction: column;
  &:nth-child(1) {
    grid-area: group1;
  }
  &:nth-child(2) {
    grid-area: group2;
  }
  &:nth-child(3) {
    grid-area: group3;
    .options-container {
      @media ${ScreenSize.SmallTabletAndUp} {
        display: block;
        columns: 2;
        column-gap: 42px;
      }
    }
  }
`;
