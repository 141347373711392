import React from "react";

import Magazine from "data/magazine";

import * as S from "./styles";

export const Main: React.FC = () => {
  return (
    <S.Main>
      <S.Restrainer>
        <S.MainContainer>
          <S.Title>Informa Clube</S.Title>
          <S.Text>
            Fique por dentro do que acontece no Clube dos Funcionários através
            do Informa Clube, a revista eletrônica disponibilizada mensalmente
            contento tudo que você precisa saber sobre as novidades do clube.
          </S.Text>
          <S.GroupEdicoes>
            {Magazine.map((item, i) => (
              <S.CardEdition key={i}>
                <a target={`_blank`} href={`/site/${item.url}${item.file}`}>
                  <img
                    src={`/site/${item.url}${item.thumb}`}
                    title={`Edição ${item.title}`}
                    alt="thumb"
                  />
                  <span>{item.title}</span>
                </a>
              </S.CardEdition>
            ))}
          </S.GroupEdicoes>
        </S.MainContainer>
      </S.Restrainer>
    </S.Main>
  );
};
