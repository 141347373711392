import { applyMiddleware, createStore } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import createSagaMiddleware from "redux-saga";
import storage from "redux-persist/lib/storage";
import { composeWithDevTools } from "@redux-devtools/extension";

import ducks from "./ducks";
import sagas from "./sagas";

const persistedReducer = persistReducer(
  {
    key: "clubedosfuncionarios.v1",
    whitelist: ["auth", "paginationParamsCache"],
    storage,
  },
  ducks
);

const sagaMiddleware = createSagaMiddleware({
  onError: (error) => {
    console.log("SAGA MIDDLEWARE ERROR", error?.message);
  },
});
// const isDevEnv = process.env.NODE_ENV === "development";

const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(sagaMiddleware))
);

const persistor = persistStore(store);

sagaMiddleware.run(sagas);

export { store, persistor };
