import styled from "styled-components";
import { Colors } from "styles/contracts";

export const Container = styled.div``;

export const Documents = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Document = styled.a`
  margin-bottom: 8px;
  color: ${Colors.Red3};
  text-decoration: underline;
`;
