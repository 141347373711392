import { call, put } from "@redux-saga/core/effects";
import { api, notify, apiErrorHandler } from "services";
import {
  FetchGalleryActions as Actions,
  FetchGalleryRequestAction as RequestAction,
} from "store/ducks/galleries";

export function* fetchGalleryRequest(action: any) {
  const { id, onSuccess, onFailure }: RequestAction = action;
  try {
    const { data } = yield call(api.get, `galleries/${id}`);
    onSuccess && onSuccess();
    yield put(Actions.success(data));
  } catch (error) {
    const { errorMessage } = apiErrorHandler(error);
    onFailure && onFailure();
    notify("error", errorMessage);
    yield put(Actions.failure(errorMessage));
  }
}
