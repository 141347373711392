import React from "react";
import * as S from "./styles";

export const Main: React.FC = () => {
  return (
    <S.Main>
      <S.Restrainer>
        <S.MainContainer>
          <S.Title>Cine 9 de Abril</S.Title>
          <S.Text>
            Um marco para a história de Volta Redonda foi a construção do Cine 9
            de Abril em 1959 e mais de 5 mil pessoas se reuniram na Vila Santa
            Cecília para sua inauguração. O espaço é considerado até hoje a
            maior sala em funcionamento no Brasil, com 1505 assentos e o design
            inovador para a época foi obra dos arquitetos Ricardo Tommasi e
            Glauco Oliveiros.
          </S.Text>
          <S.Text>
            Em sua inauguração, o cinema exibiu o filme "Noites de Mardigrás" e
            a renda adquirida foi doada a instituições de apoio à saúde da
            região. O espaço tem tamanha importância para a cidade que em 1985
            foi tombado pelo Patrimônio Histórico. Com isso, ficou impedida a
            descaracterização da sala ou seu uso para outros fins que não
            estivessem ligados à cultura. Atualmente o Cine 9 de Abril recebe
            diversas atrações culturais, tais como shows, espetáculos teatrais,
            stand ups, entre outras atividades, assim como a exibição de filmes
            nacionais e estrangeiros.
          </S.Text>
          <S.Text>
            No ano de 2015, foi elaborado um plano de restauração do cinema
            visando proporcionar mais conforto, segurança e acessibilidade. Para
            tanto, a equipe responsável pelo projeto consultou órgãos de
            tombamento e fez estudos de acústica e viabilidade de mercado. Com a
            proposta de restauração pronta, em outubro do mesmo ano um
            escritório de arquitetura assinou o contrato para a realização das
            mudanças. Espera-se que nos próximos anos o Cine possa ser um
            aparelho de nível internacional para o fomento da cultura em nossa
            região, assim como foi no período seguinte a sua inauguração.
          </S.Text>

          <S.Text>
            <span>Endereço:</span> Rua Quatorze, 235, Vila Santa Cecília, Volta
            Redonda/RJ
            <br />
            <span>Telefone:</span> (24) 3348-5014
          </S.Text>

          <S.Text>
            Saiba mais sobre o Cine 9 de Abril no{" "}
            <S.Link
              href="http://www.cine9deabril.wordpress.com/"
              target="_blank"
            >
              site oficial
            </S.Link>
            <br />
            Visite o{" "}
            <S.Link
              href="https://www.instagram.com/cine9deabril/"
              target="_blank"
            >
              perfil no Instagram
            </S.Link>{" "}
            do Cine 9 de Abril
          </S.Text>
        </S.MainContainer>
      </S.Restrainer>
    </S.Main>
  );
};
