import React from "react";

import * as C from "components/Pages/GeneralSearch";
import Scaffold from "layouts/Scaffold";

export const GeneralSearch: React.FC = () => {
  return (
    <Scaffold>
      <C.ResultPage />
    </Scaffold>
  );
};
