import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import React, { useCallback, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import * as Yup from "yup";

import { Input } from "components/Shared/Forms/Input";
import { useValidation } from "hooks";
import { FetchSearchActions as Actions } from "store/ducks/generalSearch";

import * as S from "./styles";

const SearchBar: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { handleFormErrors } = useValidation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const cookies = new Cookies();
  const onSuccess = useCallback((): void => {
    formRef?.current?.reset();
  }, []);

  const onSubmit = useCallback(async (data): Promise<void> => {
    try {
      const schema = Yup.object().shape({
        search: Yup.string().required("Informe o assunto da busca"),
      });
      cookies.set("userSearch", `${data.search}`, { path: "/" });
      const validData = await schema.validate(data, {
        abortEarly: false,
      });
      dispatch(Actions.request(validData, onSuccess));

      navigate("/sitemap");
      // window.open("/sitemap", "_blank");
    } catch (error) {
      handleFormErrors(error, formRef);
    }
  }, []);

  return (
    <>
      <S.GroupAreaSearch>
        <Form ref={formRef} onSubmit={onSubmit}>
          <S.GroupField>
            <Input
              className="field"
              name="search"
              placeholder="Busca no Site..."
            />
          </S.GroupField>
          <S.GroupButton>
            <S.BtnSubmit type="submit">Buscar</S.BtnSubmit>
          </S.GroupButton>
        </Form>
      </S.GroupAreaSearch>
    </>
  );
};
export default SearchBar;
