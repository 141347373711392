import React from "react";
import { useParams } from "react-router-dom";

import * as C from "components/Pages/ClubEvents/Article";
import Scaffold from "layouts/Scaffold";

type RouteParams = {
  id: string;
  slug: string;
};

export const EventPage: React.FC = () => {
  const { id } = useParams() as RouteParams;

  return (
    <Scaffold>
      <C.Main articleId={id} />
      <C.MoreArticles articleId={id} />
    </Scaffold>
  );
};
