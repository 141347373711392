import React from "react";

import * as S from "./styles";

export const Main: React.FC = () => {
  return (
    <S.Main>
      <S.Restrainer>
        <S.MainContainer>
          <S.ContentBlock>
            <S.Title>Xadrez</S.Title>
            <S.Text>
              O xadrez é um jogo de mesa de natureza recreativa e competitiva
              para dois jogadores. É praticado sobre um tabuleiro quadrado e
              dividido em 64 casas, alternadamente brancas e pretas. De um lado
              ficam as 16 peças brancas e de outro um mesmo número de peças
              pretas. Cada jogador tem direito a um lance por vez. O objetivo da
              partida é dar o xeque-mate no adversário.
            </S.Text>

            <S.Text>
              Sobre o professor: Leandro Nascimento atua na área desde 2019 e
              possui títulos estaduais, regionais e até nacional. Além disso,
              Leandro foi o treinador representante do Brasil no Pan-Americano
              de Xadrez da Juventude em 2021 com 2 atletas. Seus filhos, que
              também praticam xadrez, são atualmente os campeões invictos da
              região.
            </S.Text>

            <S.RedSubtitle>
              • As atividades esportivas para não sócios são permitidas apenas
              para menores de 18 anos.
            </S.RedSubtitle>

            <S.Text>
              <br />
              <strong>Horário das Aulas</strong>
              <br />
              Professor Leandro (24) 9.9248-9506
            </S.Text>

            <S.Text>Terça-feira: 19h00 às 21h00 ( 5 à 17 anos).</S.Text>

            <S.Text>
              <strong>Mensalidade:</strong>
              <br />
              Mensalidade Sócio: R$ 50,00
              <br />
              Mensalidade não Sócio: R$ 70,00
              <br />
            </S.Text>
            <S.Text>
              <br />
              <br />
              Local: Salão da Pet.
            </S.Text>
          </S.ContentBlock>
        </S.MainContainer>
      </S.Restrainer>
    </S.Main>
  );
};
