import React from "react";

import * as S from "./styles";

interface LinkItem {
  title: string;
  downloadUrl: string;
}

const links: LinkItem[] = [
  {
    title: "Organograma",
    downloadUrl: "ORGANOGRAMA.PDF",
  },
];

export const Charts: React.FC = () => {
  return (
    <S.Container>
      {/* <S.Text>
        É constituída pelos sócios e presidida pelo presidente do Conselho
        Deliberativo.
      </S.Text> */}
      <S.Links>
        {links.map((link, index) => (
          <S.LinkItem key={index}>
            {link?.downloadUrl && (
              <S.Link
                href={`/site/downloads/${link.downloadUrl}`}
                target="_blank"
              >
                {link.title}
              </S.Link>
            )}
          </S.LinkItem>
        ))}
      </S.Links>
    </S.Container>
  );
};
