import React from "react";

import * as S from "./styles";

export const Main: React.FC = () => {
  return (
    <S.Main>
      <S.Restrainer>
        <S.MainContainer>
          <S.ContentBlock>
            <S.Title>Beach Tennis</S.Title>
            <S.Text>
              O Beach Tennis é um esporte que faz uma mescla de tênis, vôlei de
              praia, badminton e frescobol. Sim, ele é real e tem caido no gosto
              do povo. O beach tennis é particado na areia e possui regras
              próprias proporcionando campeonatos disputadíssimos!
            </S.Text>
            <S.Text>
              Desde que chegou ao Brasil, em 2008, se popularizou não apenas em
              cidades do litoral, mas também em otros municipios.
            </S.Text>
            <S.Text>
              O tênis de praia, (Beach Tennis), é um esporte supernovo,
              inspirado no frescobol e Foi criado há apenas 34 anos na província
              italiana de Ravenna e pode ser jogado individualmente ou em
              duplas.
            </S.Text>
            <S.Text>
              O tênis de praia, (Beach Tennis), é um esporte supernovo,
              inspirado no frescobol e Foi criado há apenas 34 anos na província
              italiana de Ravenna e pode ser jogado individualmente ou em
              duplas.
            </S.Text>
            <S.Text>
              Em 1996, as regras foram padronizadas pela Federação Internacional
              de Tênis (ITF) e, desde então, campeonatos ocorrem em todo o mundo
              com atletas profissionais da modalidade.
            </S.Text>

            <S.ContentBlock>
              <S.Subtitle>A importância dos atestados médicos</S.Subtitle>
              <S.Text>
                É obrigatório entregar os atestados médicos para a prática das
                atividades esportivas no clube, de acordo com a LEI 2.014/92 de
                15/07/1992.
              </S.Text>
              <S.Text>
                O Atestado médico deve ser específico a cada uma das
                modalidades, evitando quaisquer problemas de saúde aos
                associados.
              </S.Text>
              <S.Text>
                Para verificar suas condições de saúde e a aptidão para a
                prática da atividade física pretendida, o Departamento de
                Esportes recomenda que o associado procure o médico de sua
                confiança para fazer todos os exames necessários
              </S.Text>
            </S.ContentBlock>

            <S.ContentBlock>
              <S.RedSubtitle>
                • As atividades esportivas para não sócios são permitidas apenas
                para menores de 18 anos.
              </S.RedSubtitle>
              <S.Subtitle>
                <strong>Horário das Aulas</strong>
              </S.Subtitle>
              <S.Text>
                <span>Professora Clarinha</span> - (24) 9 9811-1323
                <br />
                (Segunda, Quarta e Sexta-feira)
                <br />
                <br />
                07h00 ás 08h00
                <br />
                08h00 ás 09h00
                <br />
                09h00 ás 10h00 - Horário Kids.
                <br />
                10h00 ás 11h00
                <br />
                <br />
                14h00 ás 15h00
                <br />
                15h00 ás 16h00 - Horário Kids
                <br />
                16h00 ás 17h00
                <br />
                <br />
                <br />
                (Segunda e Quarta-feira)
                <br />
                08hs ás 09hs - Iniciante.
                <br />
                <br />
                <br />
                • Turma Kids - Máximo 8 Alunos
                <br />
                • Turma Adulto - Máximo 6 Alunos
                <br />• Turma Kids - <strong>Até os 17 anos.</strong>
                <br />
              </S.Text>
            </S.ContentBlock>

            <S.ContentBlock>
              <S.Subtitle>
                <strong>Mensalidade para Sócios</strong>
              </S.Subtitle>
              R$ 180,00 (2x na semana) - 1 hora de aula.
              <br />
              R$ 135,00 (1x na semana) - 1 hora de aula.
              <br />
              <S.Subtitle>
                <strong>Mensalidade Kids</strong>
              </S.Subtitle>
              R$ 160,00 (2x na semana) - 1 hora de aula.
              <br />
              R$ 115,00 (1x na semana) - 1 hora de aula.
              <br />
            </S.ContentBlock>
            <S.RedSubtitle>Obs: Somente para Associados</S.RedSubtitle>

            <S.ContentBlock>
              <S.Subtitle>
                <strong>Local</strong>
              </S.Subtitle>
              PET Clube dos Funcionários
              <br />
            </S.ContentBlock>
          </S.ContentBlock>
          <S.ContentBlock>
            <S.Title>Professor Thales (24) 9.9882-5240</S.Title>
            <S.Text>
              (Segunda e Quarta-feira)
              <br />
              09h00 às 10h00 - Limite de 6 alunos
              <br />
              16h00 às 17h00 - Limite de 6 alunos - Iniciante
              <br />
              18h00 às 19h00 - Limite de 6 alunos
              <br />
              19h00 às 20h00 - Limite de 6 alunos
              <br />
              20h00 às 21h00 - Limite de 6 alunos (Formando Turma)
              <br />
              <br />
              (Terça e Quinta-feira)
              <br />
              08h00 às 09h00 - Limite de 6 alunos
              <br />
              09h00 às 10h00 - Limite de 6 alunos - Iniciante
              <br />
              09h00 às 10h00 - Limite de 6 alunos - Thales e Daniel
              <br />
              16h00 às 17h00 - Limite de 6 alunos
              <br />
              18h00 às 19h00 - Limite de 6 alunos
              <br />
              19h00 às 20h00 - Limite de 6 alunos
              <br />
              20h00 às 21h00 - Limite de 6 alunos
              <br />
              <br />
              (Terça-feira)
              <br />
              07h00 às 08h00 - Limite de 6 alunos
              <br />
              <br />
              (Quarta-feira)
              <br />
              07h00 às 08h00 - Limite de 6 alunos
              <br />
              <br />
              (Quinta-feira)
              <br />
              19h00 às 20h00 - Limite de 6 alunos
              <br />
              <br />
              (Sexta-feira)
              <br />
              16h00 às 17h00 - Limite de 6 alunos
              <br />
              <br />
              <span>Mensalidades</span>
              <br />
              R$ 180,00 (2x na semana) - 1 hora de Aula
              <br />
              R$ 135,00 (1x na semana) - 1 hora de Aula
              <br />
              <S.RedSubtitle>Somente para sócios</S.RedSubtitle>
            </S.Text>
          </S.ContentBlock>
        </S.MainContainer>
      </S.Restrainer>
    </S.Main>
  );
};
