import { createReducer, createActions } from "reduxsauce";
import update from "immutability-helper";
import { PaginatedArticleCategory } from "contracts/Articles";
import { Pagination } from "contracts/Pagination";

const { Types, Creators } = createActions(
  {
    request: ["query", "onSuccess", "onFailure"],
    success: ["data", "pagination"],
    failure: ["errorMessage"],
    softReset: [],
    reset: [],
  },
  {
    prefix: "PAGINATE_ARTICLE_CATEGORIES_",
  }
);

export interface PaginateArticleCategoriesState {
  data: PaginatedArticleCategory[];
  pagination?: Pagination;
  loading: boolean;
  errorMessage: string | null;
}

export interface PaginateArticleCategoriesRequestAction {
  query?: Record<string, any>;
  onSuccess?: () => void;
  onFailure?: () => void;
}

interface SuccessAction {
  data: PaginatedArticleCategory[];
  pagination: Pagination;
}

interface FailureAction {
  errorMessage: string;
}

const INITIAL_STATE: PaginateArticleCategoriesState = {
  data: [],
  loading: false,
  errorMessage: null,
};

const request = (state: PaginateArticleCategoriesState) =>
  update(state, {
    loading: { $set: true },
    errorMessage: { $set: null },
  });

const success = (
  state: PaginateArticleCategoriesState,
  action: SuccessAction
) =>
  update(state, {
    loading: { $set: false },
    data: { $set: action.data },
    pagination: { $set: action.pagination },
  });

const failure = (
  state: PaginateArticleCategoriesState,
  action: FailureAction
) =>
  update(state, {
    loading: { $set: false },
    errorMessage: { $set: action.errorMessage },
  });

const softReset = (state: PaginateArticleCategoriesState) =>
  update(state, {
    loading: { $set: false },
  });

const reset = () => INITIAL_STATE;

export const paginateArticleCategories = createReducer(INITIAL_STATE, {
  [Types.REQUEST]: request,
  [Types.SUCCESS]: success,
  [Types.FAILURE]: failure,
  [Types.SOFT_RESET]: softReset,
  [Types.RESET]: reset,
});

export const PaginateArticleCategoriesTypes = Types;
export const PaginateArticleCategoriesActions = Creators;
