import React from "react";

import * as S from "./styles";

export const Main: React.FC = () => {
  return (
    <S.Main>
      <S.Restrainer>
        <S.MainContainer>
          <S.ContentBlock>
            <S.Title>Taekwondo</S.Title>
            <S.Text>
              Taekwondo, Tae Kwon Do ou Taekwon-Do é uma arte marcial coreana
              que surgiu há cerca de dois mil anos. Hoje em dia, é também um
              desporto difundido em todos os continentes. Nos Jogos Olímpicos de
              Seul, em 1988, teve seu "batismo de fogo", quando se converteu num
              desporto olímpico de exibição. Nos Jogos Olímpicos de Verão de
              1996, em Atlanta, já constava para a disputa de medalhas,
              consagrando-se como desporto olímpico oficial nas Olimpíadas de
              Sydney, em 2000.
            </S.Text>
            <S.Subtitle>Regras e Conceitos</S.Subtitle>
            <S.Text>
              Para praticar é necessário um juramento:
              <br />
              <br />
              Eu prometo:
              <br />
              Observar as regras do taekwondo.
              <br />
              Respeitar o instrutor e os meus superiores.
              <br />
              Nunca fazer mau uso do taekwondo.
              <br />
              Construir um mundo mais pacífico.
              <br />
              Ser campeão da liberdade e da justiça.
              <br />
              <br />
              Também faz parte da vida do Taekwondista, não só na academia como
              também na vida pessoal seguir os princípios do Taekwondo:
              <br />
              <br />
              Cortesia
              <br />
              Integridade
              <br />
              Perseverança
              <br />
              Auto-controle
              <br />
              Espírito Indominável
              <br />
            </S.Text>

            <S.ContentBlock>
              <S.Subtitle>A importância dos atestados médicos</S.Subtitle>
              <S.Text>
                É obrigatório entregar os atestados médicos para a prática das
                atividades esportivas no clube, de acordo com a LEI 2.014/92 de
                15/07/1992.
              </S.Text>
              <S.Text>
                O Atestado médico deve ser específico a cada uma das
                modalidades, evitando quaisquer problemas de saúde aos
                associados.
              </S.Text>
              <S.Text>
                Para verificar suas condições de saúde e a aptidão para a
                prática da atividade física pretendida, o Departamento de
                Esportes recomenda que o associado procure o médico de sua
                confiança para fazer todos os exames necessários
              </S.Text>

              <S.RedSubtitle>
                • As atividades esportivas para não sócios são permitidas apenas
                para menores de 18 anos.
              </S.RedSubtitle>
              <S.Subtitle>Horário das Aulas</S.Subtitle>
              <S.Text>
                <span>Mestre Ricardo Tomaz (24) 9.9828-8516</span>
                <br />
                (Segunda e quarta-feira)
                <br />
                19h00 às 20h00 <br />
                A partir dos 6 anos <br />
                <br />
                <span>Mensalidade para sócios: </span> R$ 90,00
                <br />
                <span>Mensalidade para não sócios: </span> R$ 130,00
                <br />
                <span>Taxa de Matrícula para não sócios: </span>R$ 50,00
              </S.Text>
              <S.Text>Local: Palco do ginásio.</S.Text>
            </S.ContentBlock>
          </S.ContentBlock>
        </S.MainContainer>
      </S.Restrainer>
    </S.Main>
  );
};
