import React from "react";
import { GalleryImage } from "contracts/Galleries";
import * as S from "./styles";

interface Props {
  image: GalleryImage;
  onClick: (image: GalleryImage) => void;
  zoomOnHover?: boolean;
}

export const GalleryImageCard: React.FC<Props> = ({
  image,
  onClick,
  zoomOnHover,
}) => {
  return (
    <S.ImageCard zoomOnHover={zoomOnHover} onClick={() => onClick(image)}>
      <S.ImageContainer>
        <S.CardImage src={image.image_thumb_url} />
      </S.ImageContainer>
    </S.ImageCard>
  );
};
