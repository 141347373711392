interface Member {
  name: string;
  image: string;
  role?: string;
}

export const viceDirectors: Member[] = [
  {
    name: "Adriano Teodoro Moutinho",
    image: "adriano_teodoro_moutinho_financas_e_controle.jpg",
    role: "Finanças e Controle",
  },
  {
    name: "Bruno Carvalho do Amaral",
    image: "bruno-carvalho-do-amaral.jpg",
    role: "Subdiretor de Esportes",
  },
  {
    name: "Carlos Serrão",
    image: "carlos_frederico_pserrao_xadrez.jpg",
    role: "Subdiretor de xadrez",
  },
  {
    name: "Caio Stocco Costa Lima",
    image: "caio_stocco_costa_lima_futebol.jpg",
    role: "Futebol",
  },
  {
    name: "Guilherme Luis Da Silva",
    image: "guilherme_luis_da_silva_sinuca.jpg",
    role: "Sinuca",
  },
  {
    name: "Helio Jose Silva De Farias",
    image: "helio_jose_silva_de_farias_comercial.jpg",
    role: "Comercial",
  },
  {
    name: "Jose Maria Galito",
    image: "jose-maria-galito.jpg",
    role: "Sauna",
  },
  {
    name: "Jose Fausto Ferreira Junior",
    image: "jose_fausto_ferreira_junior_patrimônio.jpg",
    role: "Patrimônio",
  },
  {
    name: "Leandro Ferreira Galito",
    image: "leandro_ferreira_galito_ti.jpg",
    role: "TI",
  },
  {
    name: "Luiz Edmundo Duarte",
    image: "luiz_edmundo_duarte_ouvidoria.jpg",
    role: "Ouvidoria",
  },
  {
    name: "Joao Arnaldo Dias Rosa",
    image: "no-photo.jpg",
    role: "Social",
  },
  {
    name: "Luciano Paschoalin M. De Oliveira",
    image: "luciano_paschoalin_m_de_oliveira_patrimonio.jpg",
    role: "Patrimônio",
  },
  {
    name: "Marcelo Dos Santos Barroso Filho",
    image: "marcelo_dos_santos_barroso_filho_tenis.jpg",
    role: "Tênis",
  },
  {
    name: "Marcelo Cezar Moreira",
    image: "marcelo_cezar_moreira_basquete.jpg",
    role: "Basquete",
  },
  {
    name: "Pedro Celso Franco De Barros",
    image: "pedro_celso_franco_de_barros_lutas.jpg",
    role: "Lutas",
  },
  {
    name: "Pedro Mansur Duarte De M. Marques",
    image: "pedro_mansur_duarte_de_m_marques_tenis.jpg",
    role: "Tênis",
  },
  {
    name: "Rafael Kenzo Ide Ramos",
    image: "rafael_kenzo_ide_ramos_comercial.jpg",
    role: "Comercial",
  },
  // {
  //   name: "Roberto Lourenço José",
  //   image: "roberto_lourenço_jose_social.jpg",
  //   role: "Social",
  // },
  {
    name: "Roger José Iglezias Peres",
    image: "roger_jose_iglezias_peres_acessibilidade.jpg",
    role: "Acessibilidade",
  },
  {
    name: "Sergio Luiz Do Amaral Salgueiro",
    image: "sergio_luiz_do_amaral_salgueiro_jurídico.jpg",
    role: "Jurídico",
  },
  {
    name: "Vinicius Baumgratz Fortes Oliveira",
    image: "vinicius_baumgratz_fortes_oliveira_finanças_e_controle.jpg",
    role: "Finanças e Controle",
  },
];
