import styled from "styled-components";

import { ReactComponent as Tour } from "assets/icons/tour.svg";
import { Restrainer } from "styles/components/layout";
import { Colors, Fonts } from "styles/contracts";

export const InfoBar = styled.section`
  background: ${Colors.Dark4};
  color: ${Colors.Light4};
  font-size: 14px;
  padding: 0;
  span {
    display: inline-block;
    color: ${Colors.Light1};
    font-family: ${Fonts.NunitoBold};
  }
`;

export const Container = styled(Restrainer)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const AreaTour = styled.a`
  display: flex;
  align-items: center;
  max-width: 250px;
  justify-content: center;
  padding: 10px 25px;
  background: #730001;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  font-size: large;
  svg {
    margin-right: 15px;
  }
`;

export const IconTour = styled(Tour)`
  width: 50px;
  height: auto;
  color: #fff;
  path {
    fill: currentColor;
  }
`;
