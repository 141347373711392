import React from "react";

import * as S from "./styles";

export const Main: React.FC = () => {
  return (
    <S.Main>
      <S.Restrainer>
        <S.MainContainer>
          <S.ContentBlock>
            <S.Title>Step</S.Title>
            <S.Text>
              Step é um exercício aeróbico praticado com o uso de uma pequena
              plataforma chamada step (em português, degrau). A altura da
              plataforma pode ser ajustada com a colocação de pequenas peças
              embaixo da mesma, dependendo da necessidade de cada praticante. As
              aulas de step tornaram-se muito populares na década de 1990 e
              muitas academias e ginásios oferecem programas de exercícios que
              incluem tais aulas. O praticante também pode intercalar a prática
              com outras atividades como caminhadas, por exemplo.
            </S.Text>

            <S.ContentBlock>
              <S.Subtitle>A importância dos atestados médicos</S.Subtitle>
              <S.Text>
                É obrigatório entregar os atestados médicos para a prática das
                atividades esportivas no clube, de acordo com a LEI 2.014/92 de
                15/07/1992.
              </S.Text>
              <S.Text>
                O Atestado médico deve ser específico a cada uma das
                modalidades, evitando quaisquer problemas de saúde aos
                associados.
              </S.Text>
              <S.Text>
                Para verificar suas condições de saúde e a aptidão para a
                prática da atividade física pretendida, o Departamento de
                Esportes recomenda que o associado procure o médico de sua
                confiança para fazer todos os exames necessários
              </S.Text>

              <S.RedSubtitle>
                • As atividades esportivas para não sócios são permitidas apenas
                para menores de 18 anos.
              </S.RedSubtitle>
            </S.ContentBlock>
          </S.ContentBlock>
        </S.MainContainer>
      </S.Restrainer>
    </S.Main>
  );
};
