interface Edital {
  name: string;
  filename: string;
  link?: string;
}

export const DocsEdital09c: Edital[] = [
  {
    name: `Minuta de Edital - Pregão eletronico CFCSN 01-2024 Lista 03 - 2ª parte`,
    filename: `minuta-de-edital-pregao-eletronico-cfcsn-01-2024-lista-03-2-parte.pdf`,
  },
  {
    name: `Minuta de edital - Pregão eletrônico CFCSN 02/2023 - Lista 3 assinada`,
    filename: `minuta-de-edital-pregao-eletronico-cfcsn-02-2023-lista-3-assinada.pdf`,
  },
  {
    name: `Ata da Sessão publicado pregão processo Nº 02-2023`,
    filename: `ATA-DA-SESSAO-PUBLICADO-PREGAO-PROCESSO-N02-2023.PDF`,
  },
  {
    name: `Ata julgamento assinado`,
    filename: `ATA-JULGAMENTO-ASSINADO.PDF`,
  },
  {
    name: `Ata de licitação versão certificada DOU Lista 3`,
    filename: `AVISO-DE-LICITACAO-VERSAO-CERTIFICADA-DOU-LISTA3.PDF`,
  },
  {
    name: `Lote 01 - Aditivo TRAUM artigos esportivos`,
    filename: `LOTE01-ADITIVO-TRAUM-ARTIGOS-ESPORTIVOS.PDF`,
  },
  {
    name: `Lote 01 - empresa TRAUM esportivo 1ª Etapa`,
    filename: `LOTE01-EMPRESA-TRAUM-ESPORTIVO-1ETAPA.PDF`,
  },
  {
    name: `Lote 01 - segundo aditivo TRAUM artigos esportivos`,
    filename: `LOTE01-SEGUNDO-ADITIVO-TRAUM-ARTIGOS-ESPORTIVOS.PDF`,
  },
  {
    name: `Lote 02 - aditivo Nova Capital`,
    filename: `LOTE02-ADITIVO-NOVA-CAPITAL.PDF`,
  },
  {
    name: `Lote 02 - empresa Nova Capital 1ª Etapa`,
    filename: `LOTE02-EMPRESA-NOVA-CAPITAL-1ETAPA.PDF`,
  },
  {
    name: `Lote 03 - empresa Floty equipamentos 1ª Etapa`,
    filename: `LOTE03-EMPRESA-FLOTY-EQUIPAMENTOS-1ETAPA.PDF`,
  },
  {
    name: `Lote 04 - empresa Recreonics 1ª Etapa`,
    filename: `LOTE04-EMPRESA-RECREONICS-1ETAPA.PDF`,
  },
  {
    name: `Lote 05 - Aditivo Traum artigos esportivos`,
    filename: `LOTE05-ADITIVO-TRAUM-ARTIGOS-ESPORTIVOS.PDF`,
  },
  {
    name: `Lote 05 - Empresa Traum artigos esportivos 1ª Etapa`,
    filename: `LOTE05-EMPRESA-TRAUM-ARTIGOS-ESPORTIVOS-1ETAPA.PDF`,
  },
  {
    name: `Lote 05 - Segundo aditivo Traum artigos esportivos`,
    filename: `LOTE05-SEGUNDO-ADITIVO-TRAUM-ARTIGOS-ESPORTIVOS.PDF`,
  },
  {
    name: `Lote 06 - Aditivo Markas de Resende assinado`,
    filename: `LOTE06-ADITIVO-MARKAS-DE-RESENDE-ASSINADO.PDF`,
  },
  {
    name: `Lote 06 - Empresa Markas de Resende 1ªEtapa`,
    filename: `LOTE06-EMPRESA-MARKAS-DE-RESENDE-1ETAPA.PDF`,
  },
  {
    name: `Lote 06 - Empresa Markas de Resende 1ª Etapa`,
    filename: `LOTE06-EMPRESAMARKASDERESENDE-1ªETAPA.PDF`,
  },
  {
    name: `Lote 07 - Aditivo Traum artigos esportivos`,
    filename: `LOTE07-ADITIVO-TRAUM-ARTIGOS-ESPORTIVOS.PDF`,
  },
  {
    name: `Lote 07 - Aditivo Traum artigos esportivos`,
    filename: `LOTE07-EMPRESATRAUMARTIGOSESPORTIVOS-1ETAPA.PDF`,
  },
  {
    name: `Lote 07 - Aditivo Traum artigos esportivos`,
    filename: `LOTE07-EMPRESA-TRAUM-ARTIGOS-ESPORTIVOS-1ETAPA.PDF`,
  },
  {
    name: `Lote 07 - Segundo aditivo Traum artigos esportivos`,
    filename: `LOTE07-SEGUNDO-ADITIVO-TRAUM-ARTIGOS-ESPORTIVOS.PDF`,
  },
  {
    name: `Lote 09 - Assinado Markas de Resende - Cancelado`,
    filename: `LOTE09-ASSINADOMARKASDERESENDE-CANCELADO.PDF`,
  },
  {
    name: `Lote 09 - Assinado Markas de Resende - Cancelado`,
    filename: `LOTE09-ASSINADO-MARKAS-DE-RESENDE-CANCELADO.PDF`,
  },
  {
    name: `Lote 10 - Empresa Brumo esportes 1ª Etapa`,
    filename: `LOTE10-EMPRESA-BRUMO-ESPORTES-1ETAPA.PDF`,
  },
  {
    name: `Lote 11 - Empresa Brumo esportes 1ª Etapa`,
    filename: `LOTE11-EMPRESA-BRUMO-ESPORTES-1ETAPA.PDF`,
  },
  {
    name: `Lote 12 - Aditivo Traum artigos esportivos`,
    filename: `LOTE12-ADITIVO-TRAUM-ARTIGOS-ESPORTIVOS.PDF`,
  },
  {
    name: `Lote 12 - Empresa Traum artigos esportivos 1ª Etapa`,
    filename: `LOTE12-EMPRESA-TRAUM-ARTIGOS-ESPORTIVOS-1ETAPA.PDF`,
  },
  {
    name: `Lote 12 - Segundo aditivo Traum artigos esportivos`,
    filename: `LOTE12-SEGUNDO-ADITIVO-TRAUM-ARTIGOS-ESPORTIVOS.PDF`,
  },
  {
    name: `Lote 12 - Segundo aditivo Traum artigos esportivos`,
    filename: `LOTE12-SEGUNDO-ADITIVO-TRAUM-ARTIGOS-ESPORTIVOS.PDF`,
  },
  {
    name: `Minuta de Edital - Pregão Eletronico CFCSN 01-2024 - Lista 03 - 2ª Parte`,
    filename: `MINUTA-DE-EDITAL-PREGAO-ELETRONICO-CFCSN-01-2024-LISTA-03-2PARTE.PDF`,
  },
  {
    name: `Aviso de licitação - Imprensa nacional 25-01`,
    filename: `AVISO-DE-LICITACAO-IMPRENSA-NACIONAL-25-01.PDF`,
  },
  {
    name: `Termo de adjudicação 2 da Lista III Cópia`,
    filename: `TERMO-DE-ADJUDICACAO-2-DA-LISTA-III-COPIA.PDF`,
  },
  {
    name: `Termo de adjudicação e homologação - lista III`,
    filename: `TERMO-DE-ADJUDICACAO-E-HOMOLOCAGAO-LISTA-III.PDF`,
  },
  {
    name: `Lote 01 - Nota fiscal 10856 -Floty Equipamentos Raias`,
    filename: `LOTE 01-NOTA-FISCAL-10856-FLOTY-EQUIPAMENTOS-RAIAS.PDF`,
  },
  {
    name: `Lote 02 - Nota fiscal 2578 - Nova Capital 2ª Nota`,
    filename: `LOTE 02-NOTA-FISCAL-2578-NOVA-CAPITAL-2-NOTA.PDF`,
  },
  {
    name: `Lote 02 - Nota fiscal 2556 - Nova Capital`,
    filename: `LOTE-02-NOTA-FISCAL-2556-NOVA-CAPITAL.PDF`,
  },
  {
    name: `Lote 03 - Nota fiscal 010321 - Floty Equipamentos`,
    filename: `LOTE-03-NOTA-FISCAL-010321-FLOTY-EQUIPAMENTO.PDF`,
  },
  {
    name: `Lote 04 - Nota fiscal 19765 - Recreonics`,
    filename: `LOTE-04-NOTA-FISCAL-19765-RECREONICS.PDF`,
  },
  {
    name: `Lote 06 - Nota fiscal 7283 - Markas de Resende`,
    filename: `LOTE-06-NOTA-FISCAL-7283-MARKAS-DE-RESENDE.PDF`,
  },
  {
    name: `Lote 10 - Nota fiscal 0268 - Brumo Esportes`,
    filename: `LOTE-10-NOTA-FISCAL-0268-BRUMO-ESPORTES.PDF`,
  },
  {
    name: `Lote 11 - Nota fiscal 0269 - Brumo Esportes`,
    filename: `LOTE-11-NOTA-FISCAL-0269-BRUMO-ESPORTES.PDF`,
  },
  {
    name: `Lote 12 - Comprovante TRAUM Artigos NF-5201`,
    filename: `LOTE-12-COMPROVANTE-TRAUM-ARTIGOS-NF-5201.PDF`,
  },
  {
    name: `Lote 11 - Comprovante BRUMO Esportes NF-269`,
    filename: `LOTE-11-COMPROVANTE-BRUMO-ESPORTES-NF-269.PDF`,
  },
  {
    name: `Lote 10 - Comprovante BRUMO Esportes NF-268`,
    filename: `LOTE-10-COMPROVANTE-BRUMO-ESPORTES-NF-268.PDF`,
  },
  {
    name: `Lote 07 - Comprovante TRAUM Artigos NF-5202`,
    filename: `LOTE-07-COMPROVANTE-TRAUM-ARTIGOS-NF-5202.PDF`,
  },
  {
    name: `Lote 06 - Comprovante Markas de Resende NF-7283`,
    filename: `LOTE-06-COMPROVANTE-MARKAS-DE-RESENDE-NF-7283.PDF`,
  },
  {
    name: `Lote 05 - Comprovante TRAUM Artigos NF-5203`,
    filename: `LOTE-05-COMPROVANTE-TRAUM-ARTIGOS-NF-5203.PDF`,
  },
  {
    name: `Lote 04 - Comprovante Recreonics NF-19765`,
    filename: `LOTE-04-COMPROVANTE-RECREONICS-NF-19765.PDF`,
  },
  {
    name: `Lote 03 - Comprovante Floty Equipamentos NF-10321`,
    filename: `LOTE-03-COMPROVANTE-FLOTY-EQUIPAMENTOS-NF-10321.PDF`,
  },
  {
    name: `Lote 02 - Comprovante Nova Capital Restante 5480,00`,
    filename: `LOTE-02-COMPROVANTE-NOVA-CAPITAL-RESTANTE-5480,00.PDF`,
  },
  {
    name: `Lote 02 - Comprovante Nova Capital Parcial 29.490,00`,
    filename: `LOTE-02-COMPROVANTE-NOVA-CAPITAL-PARCIAL-29.490,00.PDF`,
  },
  {
    name: `Lote 01 - Floty - Lista 03 - Comprovante Floty NF-10856-Raias`,
    filename: `LOTE-01-FLOTY-LISTA-03-COMPROVANTE-FLOTY-NF-10856-RAIAS.PDF`,
  },
  {
    name: `Lote 01 - Comprovante TRAUM Artigos NF-5200`,
    filename: `LOTE-01-COMPROVANTE-TRAUM-ARTIGOS-NF-5200.PDF`,
  },
  {
    name: `Lote 12 - Nota Fiscal 005201 - TRAUM Artigos`,
    filename: `LOTE-12-NOTA-FISCAL-005201-TRAUM-ARTIGOS.PDF`,
  },
  {
    name: `Lote 11 - Nota Fiscal 0269 - Brumo Esportes`,
    filename: `LOTE-11-NOTA-FISCAL-0269-BRUMO-ESPORTES.PDF`,
  },
  {
    name: `Lote 10 - Nota Fiscal 0268 - Brumo Esportes`,
    filename: `LOTE-10-NOTA-FISCAL-0268-BRUMO-ESPORTES.PDF`,
  },
  {
    name: `Lote 07 - Nota Fiscal 005202 - TRAUM Artigos`,
    filename: `LOTE-07-NOTA-FISCAL-005202-TRAUM-ARTIGOS.PDF`,
  },
  {
    name: `Lote 06 - Nota Fiscal 7.283 - Markas de Resende`,
    filename: `LOTE-06-NOTA-FISCAL-7.283-MARKAS-DE-RESENDE.PDF`,
  },
  {
    name: `Lote 05 - Nota Fiscal 005203 - TRAUM Artigos`,
    filename: `LOTE-05-NOTA-FISCAL-005203-TRAUM-ARTIGOS.PDF`,
  },
  {
    name: `Lote 04 - Nota Fiscal 19765 - Recreonics`,
    filename: `LOTE-04-NOTA-FISCAL-19765-RECREONICS.PDF`,
  },
  {
    name: `Lote 03 - Nota Fiscal - 010321 Floty Equioamentos`,
    filename: `LOTE-03-NOTA-FISCAL-010321-FLOTY-EQUIPAMENTO.PDF`,
  },
  {
    name: `Lote 02 - Nota Fiscal 2578 - Nova Capital 2ª Nota`,
    filename: `LOTE-02-NOTA-FISCAL-2578-NOVA-CAPITAL-2-NOTA.PDF`,
  },
  {
    name: `Lote 02 - Nota Fiscal 2556 - Nova Capital`,
    filename: `LOTE-02-NOTA-FISCAL-2556-NOVA-CAPITAL.PDF`,
  },
  {
    name: `Lote 01 - Nota Fiscal 10856 - Floty Equipamentos Raias`,
    filename: `LOTE-01-NOTA-FISCAL-10856-FLOTY-EQUIPAMENTOS-RAIAS.PDF`,
  },
  {
    name: `Lote 01 - Nota Fiscal 005200 - TRAUM Artigos`,
    filename: `LOTE-01-NOTA-FISCAL-005200-TRAUM-ARTIGOS.PDF`,
  },
];

export const DocsEdital09b: Edital[] = [
  {
    name: `NOTA FISCAL LOTE 01 - 4712`,
    filename: `NOTA-FISCAL-LOTE-01-4712.PDF`,
  },
  {
    name: `NOTA FISCAL LOTE 02 - 4716`,
    filename: `NOTA-FISCAL-LOTE-02-4716.PDF`,
  },
  {
    name: `NOTA FISCAL LOTE 03 - 527`,
    filename: `NOTA-FISCAL-LOTE-03-527.PDF`,
  },
  {
    name: `NOTA FISCAL LOTE 04 - 528`,
    filename: `NOTA-FISCAL-LOTE-04-528.PDF`,
  },
  {
    name: `NOTA FISCAL LOTE 05 - 2389`,
    filename: `NOTA-FISCAL-LOTE-05-2389.PDF`,
  },
  {
    name: `NOTA FISCAL LOTE 05 - 2396`,
    filename: `NOTA-FISCAL-LOTE-05-2396.PDF`,
  },
  {
    name: `NOTA FISCAL LOTE 08 - 529`,
    filename: `NOTA-FISCAL-LOTE-08-529.PDF`,
  },
  {
    name: `NOTA FISCAL LOTE 12 - 4713`,
    filename: `NOTA-FISCAL-LOTE-12-4713.PDF`,
  },
  {
    name: `NOTA FISCAL LOTE 13 - 4744`,
    filename: `NOTA-FISCAL-LOTE-13-4744.PDF`,
  },
  {
    name: `NOTA FISCAL LOTE 14 - 4789`,
    filename: `NOTA-FISCAL-LOTE-14-4789.PDF`,
  },
  {
    name: `NOTA FISCAL LOTE 15 - 530`,
    filename: `NOTA-FISCAL-LOTE-15-530.PDF`,
  },
  {
    name: `NOTA FISCAL LOTE 16 - 4714`,
    filename: `NOTA-FISCAL-LOTE-16-4714.PDF`,
  },
  {
    name: `NOTA FISCAL LOTE 17 - 4715`,
    filename: `NOTA-FISCAL-LOTE-17-4715.PDF`,
  },
  {
    name: `TERMO DE ADJUDICAÇÃO E HOMOLOGAÇÃO LISTA-II`,
    filename: `TERMO-DE-ADJUDICACAO-E-HOMOLOGACAO-LISTA-II.PDF`,
  },
  {
    name: `TERMO DE ADJUDICAÇÃO E HOMOLOGAÇÃO LOTE 8 - LISTA II`,
    filename: `TERMO-DE-ADJUDICACAO-E-HOMOLOGACAO-LOTE-8-LISTA-II.PDF`,
  },

  {
    name: `CONTRATO - LOTE 08`,
    filename: `lote-08-rbm-assinado-por-ambos.pdf`,
  },
  {
    name: `Processo Licitatório do Clube dos Funcionários da CSN - Minuta do Edital de Licitação 09/2021 (Lista II)`,
    filename: `edital-lista-2.pdf`,
  },
  {
    name: `Ata de Julgamento`,
    filename: `ata-de-Julgamento.pdf`,
  },
  {
    name: `Aviso de Licitação Imprensa Nacional`,
    filename: `aviso-de-licitacao-dou-imprensa-nacional.pdf`,
  },
  {
    name: `Contrato Lote 01`,
    filename: `contrato-lote-01.pdf`,
  },
  {
    name: `Contrato Lote 02`,
    filename: `contrato-lote-02.pdf`,
  },
  {
    name: `Contrato Lote 03`,
    filename: `contrato-lote-03.pdf`,
  },
  {
    name: `Contrato Lote 04`,
    filename: `contrato-lote-04.pdf`,
  },
  {
    name: `Contrato Lote 05`,
    filename: `contrato-lote-05.pdf`,
  },
  {
    name: `Contrato Lote 12`,
    filename: `contrato-lote-12.pdf`,
  },
  {
    name: `Contrato Lote 13`,
    filename: `contrato-lote-13.pdf`,
  },
  {
    name: `Contrato Lote 14`,
    filename: `contrato-lote-14.pdf`,
  },
  {
    name: `Contrato Lote 15`,
    filename: `contrato-lote-15.pdf`,
  },
  {
    name: `Contrato Lote 16`,
    filename: `contrato-lote-16.pdf`,
  },
  {
    name: `Contrato Lote 17`,
    filename: `contrato-lote-17.pdf`,
  },
];

export const DocsEdital09: Edital[] = [
  {
    name: `Termo de Execução Nº 30.2021 CFCSN - Edital 9`,
    filename: `termo-de-execucao-n-302021-cfcsn-edital-9.pdf`,
  },
  {
    name: `Itens Adquiridos`,
    filename: `minuta-de-edital-pregao-eletronico-cfcsn-02-2023-lista-3-assinada.pdf`,
    link: `https://www.cbclubes.org.br/ed9/60`,
  },
];
export const DocsEdital09a: Edital[] = [
  {
    name: `Lista de Itens Adquiridos`,
    filename: `Itens-adquiridos-edital9-lista1.pdf`,
  },
  {
    name: `Nota Fiscal 262 Fincanças e Controle - Pregão Eletrônico 2022`,
    filename: `nf-262-contrato-n-10-2022-pregao-eletronico-01-2022.pdf`,
  },
  {
    name: `Nota Fiscal 296 Fincanças e Controle - Pregão Eletrônico 2022`,
    filename: `nf-296-contrato-n07-2022-pregao-eletronico-01-2022.pdf`,
  },
  {
    name: `Nota Fiscal 563 Fincanças e Controle - Pregão Eletrônico 2022`,
    filename: `nf-563-contrato-n13-2022-pregao-eletronico-01-2022.pdf`,
  },
  {
    name: `Nota Fiscal 881 Fincanças e Controle - Pregão Eletrônico 2022`,
    filename: `nf-881-contrato-n12-2022-pregao-eletronico-01-2022.pdf`,
  },
  {
    name: `Nota Fiscal 1042 Fincanças e Controle - Pregão Eletrônico 2022`,
    filename: `nf-1042-contrato-n01-2022-pregao-eletronico-01-2022.pdf`,
  },
  {
    name: `Nota Fiscal 1158 Fincanças e Controle - Pregão Eletrônico 2022`,
    filename: `nf-1158-contrato-n08-2002-pregao-eletronico-01-2022.pdf`,
  },
  {
    name: `Nota Fiscal 1175 Fincanças e Controle - Pregão Eletrônico 2022`,
    filename: `nf-1175-contrato-n11-2022-pregao-eletronico-01-2022.pdf`,
  },
  {
    name: `Nota Fiscal 1817 Fincanças e Controle - Pregão Eletrônico 2022`,
    filename: `nf-1817-contrato-n14-2022-pregao-eletronico-01-2022.pdf`,
  },
  {
    name: `Nota Fiscal 1818 Fincanças e Controle - Pregão Eletrônico 2022`,
    filename: `nf-1818-contrato-n04-2022-pregao-eletronico-01-2022.pdf`,
  },
  {
    name: `Nota Fiscal 4556 Fincanças e Controle - Pregão Eletrônico 2022`,
    filename: `nf-4556-contrato-n06-2022-pregao-eletronico-01-2002.pdf`,
  },
  {
    name: `Nota Fiscal 15760 Fincanças e Controle - Pregão Eletrônico 2022`,
    filename: `nf-15760-contrato-n02-2022-pregao-eletronico-01-2022.pdf`,
  },
  {
    name: `Nota Fiscal 17530 Fincanças e Controle - Pregão Eletrônico 2022`,
    filename: `nf-17530-contrato-n09-2022-pregao-eletronico-01-2022.pdf`,
  },
  {
    name: `Relatório de Fincanças e Controle 2022`,
    filename: `RELATORIO -DE-FINANCAS-CONTROLE-2022.PDF`,
  },
  {
    name: `Processo Licitatório do Clube dos Funcionários da CSN - Publicação no Diário Oficial da União `,
    filename: `publicacao-diario-oficial-01.pdf`,
  },
  {
    name: `Processo Licitatório do Clube dos Funcionários da CSN - Minuta do Edital de Licitação `,
    filename: `minuta-edital-licitacao-01.pdf`,
  },
  {
    name: `Ata da Sessão Pública do Pregão 2022`,
    filename: `ata-da-sessao-publica-do-pregao-2022.pdf`,
  },
  {
    name: `Contrato Lote 1`,
    filename: `Contrato-Lote-1.pdf`,
  },
  {
    name: `Contrato Lote 2`,
    filename: `Contrato-Lote-2.pdf`,
  },
  {
    name: `Contrato Lote 3`,
    filename: `Contrato-Lote-3.pdf`,
  },
  {
    name: `Contrato Lote 4`,
    filename: `Contrato-Lote-4.pdf`,
  },
  {
    name: `Contrato Lote 7`,
    filename: `Contrato-Lote-7.pdf`,
  },
  {
    name: `Contrato Lote 8`,
    filename: `Contrato-Lote-8.pdf`,
  },
  {
    name: `Contrato Lote 9`,
    filename: `Contrato-Lote-9.pdf`,
  },
  {
    name: `Contrato Lote 10`,
    filename: `Contrato-Lote-10.pdf`,
  },
  {
    name: `Contrato Lote 11`,
    filename: `Contrato-Lote-11.pdf`,
  },
  {
    name: `Contrato Lote 12`,
    filename: `Contrato-Lote-12.pdf`,
  },
  {
    name: `Contrato Lote 14`,
    filename: `Contrato-Lote-14.pdf`,
  },
  {
    name: `Contrato Lote 15`,
    filename: `Contrato-Lote-15.pdf`,
  },
  {
    name: `Contrato Lote 16`,
    filename: `Contrato-Lote-16.pdf`,
  },
  {
    name: `Contrato Lote 17`,
    filename: `Contrato-Lote-17.pdf`,
  },
  {
    name: `Edital de Licitação`,
    filename: `Edital-de-Licitacao.pdf`,
  },
  {
    name: `Publicação Diário Oficial`,
    filename: `Publicacao-Diario-Oficial.pdf`,
  },
  {
    name: `Termo de Adjudicação e Homologação Lote 01, 03, 04, 08 e 09`,
    filename: `Termo-de-adjudicacao-e-homologacao-lote-01-04-03-08-e-09.pdf`,
  },
  {
    name: `Termo de Adjudicação e Homologação Lote 02, 07, 10, 11, 12, 14, 15, 16 e 17`,
    filename: `Termos-Adjudicacao-e-homologacao-lotes-02-07-10-14-16-11-12-15-e-17.pdf`,
  },
];
export const DocsEdital08: Edital[] = [
  {
    name: `Dados do Projeto 2023 - Edital 8 - Atualizado`,
    filename: `dados-do-projeto-2023-Edital-8-atualizado.pdf`,
  },
  {
    name: `Quadro demonstrativo transparencia do projeto edital 8 de 2022`,
    filename: `quadro-demontrativo-transparencia-do projeto-edital8- 2022-atualizado.pdf`,
  },
  {
    name: `Termo de Execução Nº 12.2020 - CSN - Edital 8 - Recursos Humanos`,
    filename: `termo-de-execucao-N122020-csn-edital8-recursos-humanos.pdf`,
  },
];
export const DocsEdital07: Edital[] = [
  {
    name: `CBC - Versão Aprovada e Assinada do Projeto CFCSN - Aquisição de Equipamentos e Materiais Esportivos 2ª fase Edital 7`,
    filename: `versao-aprovada-e-assinada-do-projeto-CFCSN_aquisicao-de-equipamentos-e-materiais-esportivos-2ªfase-edital-7.pdf`,
  },
  {
    name: `Publicação do Edital nº 000001/2020.`,
    filename: `METDIG-005-050820.pdf`,
  },
  {
    name: `Edital do Pregão Eletrônico nº 000001/2020.`,
    filename: `Edital-Pregao-Eletronico-000001-2020.pdf`,
  },
  {
    name: `Edital Retificado do Pregão Eletrônico nº 000001/2020.`,
    filename: `Edital-Retificado-do-Pregao-Eletronico-no-000001.2020.pdf`,
  },
  {
    name: `Nota Explicativa - Retificação Edital do Pregão Eletrônico nº 000001/2020.`,
    filename: `nota-explicativa-retificacao-edital-do-Pregao-Eletronico-no-000001.2020.pdf`,
  },
  {
    name: `Respostas aos Pedidos de Esclarecimentos 01 - Edital Pregão
     Eletrônico nº 000001/2020.`,
    filename: `Respostas-aos-Pedidos-de-Esclarecimentos-01-Edital-Pregao-Eletronico-no-000001.2020.pdf`,
  },
  {
    name: `Respostas aos Pedidos de Esclarecimentos 02 - Edital Pregão
    Eletrônico nº 000001/2020.`,
    filename: `respostas-aos-pedidos-de-esclarecimentos-02-Edital-Pregao-Eletronico-no-000001-2020.pdf`,
  },
  {
    name: `Publicação do Edital nº 00002/2020.`,
    filename: `Publicação_do_Edital_nº_00002.2020.pdf`,
  },
  {
    name: `Edital do Pregão Eletrônico nº 00002/2020.`,
    filename: `Edital_do_Pregão_Eletrônico_nº_00002.2020.pdf`,
  },
  {
    name: `Nota Explicativa - Início do Recebimento das Propostas.`,
    filename: `Nota_Explicativa_-_Recebimento_Propostas.pdf`,
  },
  {
    name: `Ata de Julgamento do Pregão Eletrônico nº 000001/2020.`,
    filename: `01_Ata_de_Julgamento_do_Pregao_Eletronico_n_000001.2020.pdf`,
  },
  {
    name: `Termo de Adjudicação do Pregão Eletrônico nº 000001/2020.`,
    filename: `02_Termo_de_Adjudicacao_do_Pregao_Eletronico.pdf`,
  },
  {
    name: `Termo de Homologação do Pregão Eletrônico nº 000001/2020.`,
    filename: `03_Termo_de Homologacao_do_Pregao_2020.pdf`,
  },
  {
    name: `Ata de Julgamento Edital do Pregão Eletrônico nº 00002/2020
      (Deserto).`,
    filename: `04_Ata_de_Julgamento_Edital_do_Pregao_Eletronico_n_00002.2020.pdf`,
  },
  {
    name: `Contrato Nº 01/2020 - Pregão Eletrônico nº 000001/2020.`,
    filename: `05_Contrato_N_01.2020.pdf`,
  },
  {
    name: `Contrato Nº 02/2020 - Pregão Eletrônico nº 000001/2020.`,
    filename: `06_Contrato_N_02.2020.pdf`,
  },
  {
    name: `Contrato Nº 03/2020 - Pregão Eletrônico nº 000001/2020.`,
    filename: `07_Contrato_ N_03.2020.pdf`,
  },
  {
    name: `Contrato Nº 04/2020 - Pregão Eletrônico nº 000001/2020.`,
    filename: `08_Contrato_N_04.2020.pdf`,
  },
  {
    name: `Contrato Nº 05/2020 - Pregão Eletrônico nº 000001/2020.`,
    filename: `09_Contrato_N_05.2020.pdf`,
  },
  {
    name: `Contrato Nº 06/2020 - Pregão Eletrônico nº 000001/2020.`,
    filename: `10_Contrato_N_06.2020.pdf`,
  },
  {
    name: `Contrato Nº 07/2020 - Pregão Eletrônico nº 000001/2020.`,
    filename: `11_Contrato_N_07.2020.pdf`,
  },
  {
    name: `Contrato Nº 08/2020 - Pregão Eletrônico nº 000001/2020.`,
    filename: `12_Contrato_N_08.2020.pdf`,
  },
  {
    name: `Contrato Nº 09/2020 - Pregão Eletrônico nº 000001/2020.`,
    filename: `13_Contrato_N_09.2020.pdf`,
  },
  {
    name: `Contrato Nº 10/2020 - Pregão Eletrônico nº 000001/2020.`,
    filename: `14_Contrato_ N_10.2020.pdf`,
  },
  {
    name: `Contrato Nº 11/2020 - Pregão Eletrônico nº 000001/2020.`,
    filename: `15_Contrato_N_11.2020.pdf`,
  },
  {
    name: `Contrato Nº 12/2020 - Pregão Eletrônico nº 000001/2020.`,
    filename: `16_Contrato_N_12.2020.pdf`,
  },
  {
    name: `Contrato Nº 13/2020 - Pregão Eletrônico nº 000001/2020.`,
    filename: `17_Contrato_N_13.2020.pdf`,
  },
  {
    name: `Contrato Nº 14/2020 - Pregão Eletrônico nº 000001/2020.`,
    filename: `18_Contrato_N_14.2020.pdf`,
  },
  {
    name: `Contrato Nº 15/2020 - Pregão Eletrônico nº 000001/2020.`,
    filename: `19_Contrato_N_15.2020.pdf`,
  },
  {
    name: `Contrato Nº 16/2020 - Pregão Eletrônico nº 000001/2020.`,
    filename: `20_Contrato_N_16.2020.pdf`,
  },
  {
    name: `Contrato Nº 17/2020 - Pregão Eletrônico nº 000001/2020.`,
    filename: `21_Contrato_N_17.2020.pdf`,
  },
  {
    name: `Contrato Nº 18/2020 - Pregão Eletrônico nº 000001/2020.`,
    filename: `22_Contrato_N_18.2020.pdf`,
  },
  {
    name: `Contrato Nº 19/2020 - Pregão Eletrônico nº 000001/2020.`,
    filename: `23_Contrato_N_19.2020.pdf`,
  },
  {
    name: `Contrato Nº 20/2020 - Pregão Eletrônico nº 000001/2020.`,
    filename: `24_Contrato_N_20.2020.pdf`,
  },
  {
    name: `Contrato Nº 21/2020 - Pregão Eletrônico nº 000001/2020.`,
    filename: `25_Contrato_N_21.2020.pdf`,
  },
  {
    name: `Termo Aditivo ao Contrato de Nº 01/2020 - Pregão Eletrônico nº
    000001/2020.`,
    filename: `26_Termo_Aditivo_ao_Contrato_de_N_01-2020-Pregao_Eletronico_n_000001_2020.pdf`,
  },
  {
    name: `Termo Aditivo ao Contrato de Nº 04/2020 - Pregão Eletrônico nº
    000001/2020.`,
    filename: `27-Termo_Aditivo_ao_Contrato_de_N_04-2020-Pregao_Eletronico_n_000001-2020.pdf`,
  },
  {
    name: `Termo Aditivo ao Contrato de Nº 08/2020 - Pregão Eletrônico nº
    000001/2020.`,
    filename: `28-Termo_Aditivo_ao_Contrato_de_N_08-2020_Pregao_Eletronico_n_000001-2020.pdf`,
  },
  {
    name: `Termo Aditivo ao Contrato de Nº 10/2020 - Pregão Eletrônico nº
    000001/2020.`,
    filename: `29-Termo_Aditivo_ao_Contrato_de_N_10-2020-Pregao_Eletronico_n_000001-2020.pdf`,
  },
  {
    name: `Termo Aditivo ao Contrato de Nº 11/2020 - Pregão Eletrônico nº
    000001/2020.`,
    filename: `30-Termo_Aditivo_ao_Contrato_de_N_11-2020-Pregao_Eletronico_n_000001-2020.pdf`,
  },
  {
    name: `Termo Aditivo ao Contrato de Nº 12/2020 - Pregão Eletrônico nº
    000001/2020.`,
    filename: `31-Termo_Aditivo_ao_Contrato_de_N_12-2020-Pregao_Eletronico_n_000001-2020.pdf`,
  },
  {
    name: `Termo Aditivo ao Contrato de Nº 15/2020 - Pregão Eletrônico nº
    000001/2020.`,
    filename: `32-Termo_Aditivo_ao_Contrato_de_N_15-2020-Pregao Eletronico_n_000001-2020.pdf`,
  },
  {
    name: `Termo Aditivo ao Contrato de Nº 16/2020 - Pregão Eletrônico nº
    000001/2020.`,
    filename: `33-Termo_Aditivo_ao_Contrato_de_N_16-2020-Pregao_Eletronico_n_000001-2020.pdf`,
  },
  {
    name: `NF 13544 - Contrato 01/2020 - Pregão Eletrônico nº 000001/2020.`,
    filename: `34-NF_13544_Contrato_01-2020-Pregao_Eletronico_n_000001-2020.pdf`,
  },
  {
    name: `NF 5227 - Contrato 02/2020 - Pregão Eletrônico nº 000001/2020.`,
    filename: `35-NF-5227_Contrato_02-2020-Pregao_Eletronico_n_000001-2020.pdf`,
  },
  {
    name: `NF 5238 - Contrato 02/2020 - Pregão Eletrônico nº 000001/2020.`,
    filename: `36-NF_5238_Contrato_02-2020_Pregao_Eletronico_n_000001-2020.pdf`,
  },
  {
    name: `NF 960 - Contrato 03/2020 - Pregão Eletrônico nº 000001/2020.`,
    filename: `37-NF_960_Contrato_03-2020_Pregao_Eletronico_n_000001-2020.pdf`,
  },
  {
    name: `NF 14085 - Contrato 04/2020 - Pregão Eletrônico nº 000001/2020.`,
    filename: `38-NF_14085_Contrato_04-2020_Pregao_Eletronico_n_000001-2020.pdf`,
  },
  {
    name: `NF 130898 - Contrato 04/2020 - Pregão Eletrônico nº 000001/2020.`,
    filename: `39-NF_130898_Contrato_04-2020_Pregao_Eletronico_n_000001-2020.pdf`,
  },
  {
    name: `NF 130909 - Contrato 04/2020 - Pregão Eletrônico nº 000001/2020.`,
    filename: `40-NF_130909_Contrato_04-2020_Pregao_Eletronico_n_000001.2020.pdf`,
  },
  {
    name: `NF 955 - Contrato 05/2020 - Pregão Eletrônico nº 000001/2020.`,
    filename: `41-NF_955_Contrato_05-2020_Pregao_Eletronico_n_000001-2020.pdf`,
  },
  {
    name: `NF 963 - Contrato 05/2020 - Pregão Eletrônico nº 000001/2020.`,
    filename: `42-NF_963_Contrato_05-2020_Pregao_Eletronico_n_000001-2020.pdf`,
  },
  {
    name: `NF 2457 - Contrato 06/2020 - Pregão Eletrônico nº 000001/2020.`,
    filename: `43-NF_2457_Contrato_06-2020_Pregao_Eletronico_n_000001-2020.pdf`,
  },
  {
    name: `NF 5217 - Contrato 07/2020 - Pregão Eletrônico nº 000001/2020.`,
    filename: `44-NF_5217_Contrato_07-2020_Pregao_Eletronico_n_000001-2020.pdf`,
  },
  {
    name: `NF 2749 - Contrato 08/2020 - Pregão Eletrônico nº 000001/2020.`,
    filename: `45-NF_2749_Contrato_08-2020_Pregao_Eletronico_n_000001-2020.pdf`,
  },
  {
    name: `NF 2750 - Contrato 08/2020 - Pregão Eletrônico nº 000001/2020.`,
    filename: `46-NF_2750_Contrato_08-2020_Pregao_Eletronico_n_000001-2020.pdf`,
  },
  {
    name: `NF 2757 - Contrato 08/2020 - Pregão Eletrônico nº 000001/2020.`,
    filename: `47-NF_2757_Contrato_07-2020_Pregao_Eletronico_n_000001-2020.pdf`,
  },
  {
    name: `NF 6521 - Contrato 09/2020 - Pregão Eletrônico nº 000001/2020.`,
    filename: `48-NF_6521_Contrato_09-2020_Pregao_Eletronico_n_000001-2020.pdf`,
  },
  {
    name: `NF 1501 - Contrato 10/2020 - Pregão Eletrônico nº 000001/2020.`,
    filename: `49-NF_1501_Contrato_10-2020_Pregao_Eletronico_n_000001-2020.pdf`,
  },
  {
    name: `NF 1503 - Contrato 10/2020 - Pregão Eletrônico nº 000001/2020.`,
    filename: `50-NF_1503_Contrato_10-2020_Pregao_Eletronico_n_000001-2020.pdf`,
  },
  {
    name: `NF 1535 - Contrato 10/2020 - Pregão Eletrônico nº 000001/2020.`,
    filename: `51-NF_1535_Contrato_10-2020_Pregao_Eletronico_n_000001.2020.pdf`,
  },
  {
    name: `NF 1562 - Contrato 10/2020 - Pregão Eletrônico nº 000001/2020.`,
    filename: `52-NF_1562_Contrato_10-2020_Pregao_Eletronico_n_000001-2020.pdf`,
  },
  {
    name: `NF 1558 - Contrato 10/2020 - Pregão Eletrônico nº 000001/2020.`,
    filename: `53-NF_1558_Contrato_10-2020_Pregao_Eletronico_n_000001-2020.pdf`,
  },
  {
    name: `NF 1560 - Contrato 10/2020 - Pregão Eletrônico nº 000001/2020.`,
    filename: `54-NF_1560_Contrato_10-2020_Pregao_Eletronico_n_000001-2020.pdf`,
  },
  {
    name: `NF 15443 - Contrato 11/2020 - Pregão Eletrônico nº 000001/2020.`,
    filename: `55-NF_15443_Contrato_11-2020_Pregao_Eletronico_n_000001-2020.pdf`,
  },
  {
    name: `NF 15090 - Contrato 12/2020 - Pregão Eletrônico nº 000001/2020.`,
    filename: `56-NF_15090_Contrato_12-2020_Pregao_Eletronico_n_000001-2020.pdf`,
  },
  {
    name: `NF 15091 - Contrato 12/2020 - Pregão Eletrônico nº 000001/2020.`,
    filename: `57-NF_15091_Contrato_12-2020_Pregao_Eletronico_n_000001-2020.pdf`,
  },
  {
    name: `NF 15181 - Contrato 12/2020 - Pregão Eletrônico nº 000001/2020.`,
    filename: `58-NF_15181_Contrato_12-2020_Pregao_Eletronico_n_000001-2020.pdf`,
  },
  {
    name: `NF 001 - Contrato 13/2020 - Pregão Eletrônico nº 000001/2020.`,
    filename: `59-NF_001_Contrato_13-2020_Pregao_Eletronico_n_000001-2020.pdf`,
  },
  {
    name: `NF 002 - Contrato 13/2020 - Pregão Eletrônico nº 000001/2020.`,
    filename: `60-NF_002_Contrato_13-2020_Pregao_Eletronico_n_000001-2020.pdf`,
  },
  {
    name: `NF 5218 - Contrato 14/2020 - Pregão Eletrônico nº 000001.2020.`,
    filename: `61-NF_5218_Contrato_14-2020_Pregao_Eletronico_n_000001-2020.pdf`,
  },
  {
    name: `NF 1521 - Contrato 15/2020 - Pregão Eletrônico nº 000001/2020.`,
    filename: `62-NF_1521_Contrato_15-2020_Pregao_Eletronico_n_000001-2020.pdf`,
  },
  {
    name: `NF 1561 - Contrato 15/2020 - Pregão Eletrônico nº 000001/2020.`,
    filename: `63-NF_1561_Contrato_15-2020_Pregao_Eletronico_n_000001-2020.pdf`,
  },
  {
    name: `NF 1778 - Contrato 16/2020 - Pregão Eletrônico nº 000001/2020.`,
    filename: `64-NF_1778_Contrato_16-2020_Pregao_Eletronico_n_000001-2020.pdf`,
  },
  {
    name: `NF 5219 - Contrato 17/2020 - Pregão Eletrônico nº 000001/2020.`,
    filename: `65-NF_5219_Contrato_17-2020_Pregao_Eletronico_ n_000001-2020.pdf`,
  },
  {
    name: `NF 5220 - Contrato 18/2020 - Pregão Eletrônico nº 000001/2020.`,
    filename: `66-NF_5220_Contrato_18-2020_Pregao_Eletronico_n_ 000001-2020.pdf`,
  },
  {
    name: `NF 22798 - Contrato 19/2020 - Pregão Eletrônico nº 000001/2020.`,
    filename: `67-NF_22798_Contrato_19-2020_Pregao_Eletronico_n_000001-2020.pdf`,
  },
  {
    name: `NF 22799 - Contrato 19/2020 - Pregão Eletrônico nº 000001/2020.`,
    filename: `68-NF_22799_Contrato_19.2020_Pregao_Eletronico_n_000001-2020.pdf`,
  },
  {
    name: `NF 5221 - Contrato 20/2020 - Pregão Eletrônico nº 000001/2020.`,
    filename: `69-NF_5221_Contrato_20-2020_Pregao_Eletronico_n_000001-2020.pdf`,
  },
  {
    name: `NF 5222 - Contrato 21/2020 - Pregão Eletrônico nº 000001/2020.`,
    filename: `70-NF_5222_Contrato_21-2020_Pregao_Eletronico_n_000001-2020.pdf`,
  },
];
