import React, { useCallback, useRef } from "react";

import Sidebar, { SidebarRef } from "./Sidebar";
import * as S from "./styles";

const MobileNavigation: React.FC = () => {
  const sidebarRef = useRef<SidebarRef | null>(null);

  const handleToggleMenu = useCallback((): void => {
    sidebarRef?.current?.toogle();
  }, []);

  return (
    <S.MobileNavigation>
      <S.MobileTopBar>
        <div className="groupIcons">
          <a href="https://www.youtube.com/c/CFCSN" target="_blank">
            <img
              className="youtube"
              src={require("assets/images/youtube-channel.png")}
              alt="logo-canal-youtube"
            />
          </a>
        </div>
        <div>
          <a
            href="https://www.instagram.com/clubedosfuncionarios/"
            target="_blank"
          >
            <S.InstaLogo size={35} />
          </a>
          <a
            href="https://www.facebook.com/clubedosfuncionarios"
            target="_blank"
          >
            <S.FaceLogo size={30} />
          </a>
        </div>
      </S.MobileTopBar>
      <S.TopContainer>
        <S.MenuButton role="button" type="button" onClick={handleToggleMenu}>
          <S.MenuIcon />
        </S.MenuButton>
        {/* <S.HomepageLink to="/">
          <S.LogoImage src={require("assets/images/logo.png")} alt="Logo" />
        </S.HomepageLink> */}
        <S.GroupIcons>
          <a href="/site">
            <S.LogoImage src={require("assets/images/logo.png")} alt="Logo" />
          </a>
          <div>
            <img
              className="icons"
              src={require("assets/images/top-100.png")}
              alt="top-100"
              title="top-100"
            />
            <a href="https://www.cbclubes.org.br/" target="_blank">
              <img
                className="icons last"
                src={require("assets/images/selo-cbc.png")}
                alt="selo-cbc"
                title="Selo CBC"
              />
            </a>
          </div>
        </S.GroupIcons>
      </S.TopContainer>
      <Sidebar ref={sidebarRef} />
    </S.MobileNavigation>
  );
};

export default MobileNavigation;
