import React, { useCallback } from "react";

import { Accordion } from "components/Shared";
import { budgets, BudgetsPattern } from "data/transparency";

import * as S from "./styles";

const getDownloadUrl = (filename: string): string => {
  const baseUrl = "/site/cbc-documentos";
  return `${baseUrl}/${filename}`;
};

const getIdentifier = (report: BudgetsPattern): string => {
  return `FINANCIAL_REPORT_${report.year}`;
};

interface Props {
  activeAccordion: string;
  onActivate: (activeAccordion: string) => void;
}
export const BudgetsDocuments: React.FC<Props> = ({
  activeAccordion,
  onActivate,
}) => {
  const DocumentList = useCallback(
    ({ report }: { report: BudgetsPattern }): JSX.Element => {
      return (
        <S.Documents>
          {report.documents.map((document, index) => (
            <S.Document
              key={index}
              target="_blank"
              href={getDownloadUrl(document.filename)}
            >
              {document.name}
            </S.Document>
          ))}
        </S.Documents>
      );
    },
    []
  );

  const handleAccordion = useCallback(
    (report: BudgetsPattern): void => {
      onActivate(getIdentifier(report));
    },
    [onActivate]
  );

  return (
    <S.Container>
      <S.Accordions>
        {budgets.map((report, index) => (
          <Accordion
            key={index}
            title={`Orçamento ${report.year}`}
            isOpen={activeAccordion === getIdentifier(report)}
            onClick={() => handleAccordion(report)}
          >
            <DocumentList report={report} />
          </Accordion>
        ))}
      </S.Accordions>
    </S.Container>
  );
};
