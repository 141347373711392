interface Member {
  name: string;
  image: string;
  role?: string;
}

export const benefactors: Member[] = [
  {
    name: "José Mauro Lemos",
    image: "jose-mauro-lemos.jpg",
    role: "Presidente",
  },
  {
    name: "Antônio Carlos Marins",
    image: "antonio-carlos-marins.jpg",
  },
  {
    name: "Arino Gonçalves de Oliveira",
    image: "arino-goncalves-de-oliveira.jpg",
  },
  {
    name: "Carlos Roberto Paiva",
    image: "carlos-roberto-paiva.jpg",
  },
  {
    name: "Djalma Gamis de Oliveira",
    image: "djalma-gamis-de-oliveira.jpg",
  },
  {
    name: "Evandro Ruy da Costa Lima",
    image: "evandro-ruy-da-costa-lima.jpg",
  },
  {
    name: "Expedito Gonçalves de Aquino",
    image: "expedito-goncalves-de-aquino.jpg",
  },
  {
    name: "Claudio Augusto de O. Ferreira",
    image: "claudio-augusto-de-o-ferreira.jpg",
  },
  {
    name: "Fernando Parente de Matos",
    image: "fernando-parente-de-matos.jpg",
  },
  // {
  //   name: "Jair Azevedo Lima",
  //   image: "jair-azevedo-lima.jpg",
  // },
];
