import React from "react";

import * as S from "./styles";

export const Main: React.FC = () => {
  return (
    <S.Main>
      <S.Restrainer>
        <S.MainContainer>
          <S.ContentBlock>
            <S.Subtitle>Horários de Funcionamento</S.Subtitle>
            <S.Text>
              Segunda à Sexta-feira - 06h00 às 22h00
              <br />
              Sábados - 07h00 às 13h00
              <br />
              Domingos - 08h00 às 12h00
              <br />
              Feriados - 07h00 às 13h00
              <br />
              <br />
              <span>Obs: Somente para sócios</span>
            </S.Text>
            <S.RedSubtitle>Limite:750 alunos</S.RedSubtitle>
            <S.RedSubtitle>
              Idade Mínima: 14 anos (Menores apenas com personal)
            </S.RedSubtitle>
            <S.Text>
              <span>Vantagens: </span>
              <br />
              Além da musculação o sócio terá direito a frequentar as seguintes
              aulas:
              <br />
              * Caminhada orientada com alongamento: 3ª e 5ª 07h00 às 08h00
              <br />
              * Aula de Ritimos: 3ª e 5ª 07h15 com a professora Viviane -Salão
              bocha
              <br />
              * Step: 3ª e 5ª 19h00 às 20h00 com a professora Fernanda
              <br />
            </S.Text>
            <S.Text>
              <span>Valores:</span>
              <br />
              Mensalidade: R$ 99,00
              <br />
              Diaria: R$ 20,00
              <br />
              Camisa: R$ 40,00 (Aguardando chegar)
              <br />
              Isento de taxa de matrícula
              <br />
              <br />
              <span>O personal deverá pagar uma taxa de R$ 90,00 mensal</span>
              <br />
              Aula experimental autorizada somente de segunda a sexta-feira
              (Sábados, domingos e feriados não liberar)
              <br />
            </S.Text>
          </S.ContentBlock>
        </S.MainContainer>
      </S.Restrainer>
    </S.Main>
  );
};
