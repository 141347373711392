import React from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import Cookies from "universal-cookie";

import { FetchSearchState as State } from "store/ducks/generalSearch";

import * as S from "./styles";

export const ResultPage: React.FC = () => {
  const { data: resp, loading } = useSelector<RootStateOrAny, State>(
    (state) => state.fetchGeneralSearch
  );
  const cookies = new Cookies();
  const articles = resp.data;
  const sitemap = resp.sitemap;

  const busca = cookies.get("userSearch");

  return (
    <>
      <S.Container>
        <S.PageTitle>Resultado da Pesquisa para "{busca}"</S.PageTitle>

        {loading && (
          <S.GroupLoading>
            <img src={require("assets/loading/loading.gif")} alt="loading" />
            <p>Nova Busca...</p>
          </S.GroupLoading>
        )}
        {articles && articles.length >= 1 && (
          <S.RespTitle>Artigos encontrados</S.RespTitle>
        )}
        {articles &&
          articles.map((element: any, index: number) => (
            <S.GroupResp href={`noticias/${element.id}/${element.slug}`}>
              {element.title}
            </S.GroupResp>
          ))}

        {sitemap && sitemap.length >= 1 && (
          <S.RespTitle className="last">
            Páginas do site ou documentos encontrados
          </S.RespTitle>
        )}

        {sitemap &&
          sitemap.map((element: any, index: number) => (
            <S.GroupResp href={element.link}>{element.title}</S.GroupResp>
          ))}

        {!sitemap && !articles && !loading && (
          <S.Warning>Você precisa informar um termo para pesquisa</S.Warning>
        )}

        {articles && articles.length === 0 && (
          <S.RespTitle>
            Nenhuma resposta para a busca nos "Artigos do site"
          </S.RespTitle>
        )}
        {sitemap && sitemap.length === 0 && (
          <S.RespTitle>
            Nenhuma resposta para a busca nas "Páginas do site".
          </S.RespTitle>
        )}
      </S.Container>
    </>
  );
};
