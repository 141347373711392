import styled from "styled-components";
export {
  FormRow,
  FormActions,
  Button,
  ActivityIndicator,
} from "styles/components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
