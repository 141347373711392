import React from "react";
import { useParams } from "react-router-dom";
import Scaffold from "layouts/Scaffold";
import * as C from "components/Pages/Articles/Article";

type RouteParams = {
  id: string;
  slug: string;
};

export const ArticlePage: React.FC = () => {
  const { id } = useParams() as RouteParams;

  return (
    <Scaffold>
      <C.Main articleId={id} />
      <C.MoreArticles articleId={id} />
    </Scaffold>
  );
};
