import React from "react";
import { PaginatedGallery } from "contracts/Galleries";

import * as S from "./styles";

interface Props {
  gallery: PaginatedGallery;
  zoomOnHover?: boolean;
}

export const GalleryCard: React.FC<Props> = ({ gallery, zoomOnHover }) => {
  return (
    <S.GalleryCard
      zoomOnHover={zoomOnHover}
      to={`/galerias/${gallery.id}/${gallery.slug}`}
    >
      <S.ImageContainer>
        <S.CardImage src={gallery.image_url} alt={gallery.title} />
        <S.Badge>
          <S.PhotoIcon /> <span>{gallery.meta.images_count} fotos</span>
        </S.Badge>
      </S.ImageContainer>
      <S.CardTitle>{gallery.title}</S.CardTitle>
    </S.GalleryCard>
  );
};
