import styled from "styled-components";

export const NavigationMenu = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 8px;
`;

export const GroupSearch = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 20px auto 30px auto;

  padding: 0px;
`;
