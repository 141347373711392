import { useEffect, useState } from "react";

export const useThrottle = <T extends unknown = {}>(
  value: T,
  delay: number
) => {
  const [throttledValue, setThrottledValue] = useState<T>(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setThrottledValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return throttledValue;
};
