interface Member {
  name: string;
  image: string;
  period: [number, number];
  deceased: boolean;
}

export const exPresidents: Member[] = [
  {
    name: "Demétrio Boueri",
    image: "demetrio-boueri.jpg",
    period: [1940, 1942],
    deceased: true,
  },
  {
    name: "Octávio da Costa Monteiro",
    image: "octavio-da-costa-monteiro.jpg",
    period: [1942, 1944],
    deceased: true,
  },
  {
    name: "Itagiba Nogueira de Sá",
    image: "itagiba-nogueira-de-sa.jpg",
    period: [1944, 1946],
    deceased: true,
  },
  {
    name: "Paulo César Gomes Martins",
    image: "paulo-cesar-gomes-martins.jpg",
    period: [1946, 1950],
    deceased: true,
  },
  {
    name: "José Marco Ferreira de Souza",
    image: "jose-marco-ferreira-de-souza.jpg",
    period: [1950, 1952],
    deceased: true,
  },
  {
    name: "Newton Coimbra Bittencourt Cotrin",
    image: "newton-coimbra-bittencourt-cotrin.jpg",
    period: [1952, 1967],
    deceased: true,
  },
  {
    name: "Benevenuto dos Santos Netto",
    image: "benevenuto-dos-santos-netto.jpg",
    period: [1967, 1978],
    deceased: true,
  },
  {
    name: "Ervin Michelstadter",
    image: "ervin-michelstadter.jpg",
    period: [1978, 1985],
    deceased: true,
  },
  {
    name: "Arino Gonçalves de Oliveira",
    image: "arino-goncalves-de-oliveira.jpg",
    period: [1985, 1991],
    deceased: false,
  },
  {
    name: "Fernando Antonio R. de Almeida",
    image: "fernando-antonio-almeida.jpg",
    period: [1991, 1995],
    deceased: true,
  },
  {
    name: "José Mauro Lemos",
    image: "jose-mauro-lemos.jpg",
    period: [1995, 1999],
    deceased: false,
  },
  {
    name: "Airton Lecio do Prado Castro",
    image: "airton-lecio-do-prado-castro.jpg",
    period: [1999, 2003],
    deceased: true,
  },
  {
    name: "Djalma Gamis de Oliveira",
    image: "djalma-gamis-de-oliveira.jpg",
    period: [2003, 2007],
    deceased: false,
  },
  {
    name: "Renato Santini",
    image: "renato-santini.jpg",
    period: [2007, 2013],
    deceased: true,
  },
  {
    name: "Evandro Ruy da Costa Lima",
    image: "evandro-ruy-da-costa-lima.jpg",
    period: [2013, 2017],
    deceased: false,
  },
  {
    name: "Claudio Augusto de O. Ferreira",
    image: "claudio-augusto-de-o-ferreira.jpg",
    period: [2017, 2021],
    deceased: false,
  },
];
