import React from "react";
import Scaffold from "layouts/Scaffold";
import { PageBanner } from "components/Shared";
import * as C from "components/Pages/Contact/Contact";

export const ContactPage: React.FC = () => {
  return (
    <Scaffold>
      <PageBanner
        image={require("assets/images/generic-banner.jpg")}
        title="Fale conosco"
        subtitle="Envie-nos suas dúvidas e sugestões"
      />
      <C.Main />
    </Scaffold>
  );
};
