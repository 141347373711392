import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { ReactComponent as CaretDown } from "assets/icons/caret-down.svg";
import { Colors, Fonts, ScreenSize } from "styles/contracts";

// handlers

const baseHandlerStyle = css`
  color: currentColor;
  font-family: ${Fonts.NunitoBold};
  font-size: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 0;
  cursor: pointer;
`;

export const LinkHandler = styled(Link)`
  ${baseHandlerStyle}
`;

export const ExternalLinkHandler = styled.a`
  ${baseHandlerStyle}
`;

export const ActionHandler = styled.button`
  ${baseHandlerStyle}
`;

// in case of accordion

export const AccordionContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media ${ScreenSize.SmallTabletAndUp} {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
`;

export const AccordionHandler = styled.button`
  ${baseHandlerStyle};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const CaretDownIcon = styled(CaretDown)`
  display: none;
  path {
    stroke: ${Colors.Light1};
    fill: ${Colors.Light1};
  }
  @media ${ScreenSize.Mobile} {
    display: initial;
  }
`;

interface IOptionsContainer {
  isActive?: boolean;
}

export const OptionsContainer = styled.div.attrs({
  className: "options-container",
})<IOptionsContainer>`
  display: flex;
  flex-direction: column;

  @media ${ScreenSize.Mobile} {
    overflow: hidden;
    transform: all 300ms ease-in-out;
    max-height: ${(props) => (props.isActive ? "1000px" : "0px")};
  }
`;

const baseOptionStyle = css`
  color: currentColor;
  font-family: ${Fonts.NunitoRegular};
  font-size: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 0;
  cursor: pointer;
`;

export const LinkOption = styled(Link)`
  ${baseOptionStyle}
`;

export const ExternalLinkOption = styled.a`
  ${baseOptionStyle}
`;

export const ActionOption = styled.button`
  ${baseOptionStyle}
`;
