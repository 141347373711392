import styled from "styled-components";
import { Colors } from "styles/contracts";
import { ReactComponent as Pin } from "assets/icons/pin.svg";
export { Restrainer } from "styles/components";

export const Main = styled.div`
  background: ${Colors.Light3};
  padding-bottom: 110px;
`;

export const MainContainer = styled.div`
  margin-top: -100px;
  background: ${Colors.Light1};
  border-radius: 8px;
  padding: 34px 60px 60px 60px;
  @media screen and (max-width: 800px) {
    margin-top: -32px;
  }
`;

export const Title = styled.h4`
  font-size: 26px;
  margin: 26px 0;
`;

export const Subtitle = styled.h5`
  font-size: 18px;
  margin-bottom: 10px;
`;

export const Text = styled.p`
  font-size: 16px;
  span {
    font-weight: bold;
  }
  &:not(:last-child) {
    margin-bottom: 24px;
  }
`;

export const GroupMap = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 500px;
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 540px) {
    text-align: center;
    column-gap: 0;
    row-gap: 30px;
    justify-content: center;
    align-items: center;
  }
`;

export const Map = styled.div`
  width: 100%;
  max-width: 800px;
  border: 1px #d2d2d2 solid;
`;

export const PinIcon = styled(Pin)``;
