import React, { useMemo } from "react";

import { useScreenSize } from "hooks";
import DesktopHeader from "./DesktopHeader";
import MobileHeader from "./MobileHeader";
import Footer from "./Footer";
import * as S from "./styles";

const Scaffold: React.FC = ({ children }) => {
  const screenSize = useScreenSize();

  const isDesktop = useMemo(() => screenSize.width > 1023, [screenSize?.width]);

  return (
    <S.Scaffold id="AppScaffold">
      {(isDesktop && <DesktopHeader />) || <MobileHeader />}
      <S.MainSlot>{children}</S.MainSlot>
      <Footer />
    </S.Scaffold>
  );
};

export default Scaffold;
