import styled from "styled-components";
import { Colors } from "styles/contracts";
export { Restrainer } from "styles/components";

export const Main = styled.div`
  background: ${Colors.Light3};
  padding-bottom: 110px;
`;

export const MainContainer = styled.div`
  margin-top: -100px;
  background: ${Colors.Light1};
  border-radius: 8px;
  padding: 34px 60px 60px 60px;
  @media screen and (max-width: 800px) {
    margin-top: -32px;
  }
`;

export const Title = styled.h4`
  font-size: 26px;
  margin: 26px 0;
`;

export const Subtitle = styled.h5`
  font-size: 18px;
  margin: 24px 0;
`;

export const Text = styled.p`
  font-size: 16px;
  span {
    font-weight: bold;
  }
  &:not(:last-child) {
    margin-bottom: 24px;
  }
`;

export const GroupEdicoes = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 95%;
  margin: 0 auto;
  align-items: center;
  justify-content: flex-start;
  padding: 15px;
  column-gap: 55px;
  row-gap: 45px;
  @media screen and (max-width: 540px) {
    text-align: center;
    column-gap: 0;
    row-gap: 30px;
    justify-content: center;
    align-items: center;
  }
`;
export const CardEdition = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  span {
    display: block;
    padding: 5px;
    font-weight: bold;
    text-align: center;
    color: #940b0f;
  }
`;
