import styled from "styled-components";
import { Colors } from "styles/contracts";
export { Restrainer } from "styles/components";

export const Main = styled.div`
  background: ${Colors.Light3};
  padding-bottom: 110px;
`;

export const MainContainer = styled.div`
  margin-top: -100px;
  background: ${Colors.Light1};
  border-radius: 8px;
  padding: 34px 60px 60px 60px;
  @media screen and (max-width: 800px) {
    margin-top: -32px;
  }
`;

export const Header = styled.header`
  text-align: center;
  max-width: 480px;
  margin: 0 auto 48px auto;
`;

export const Title = styled.h4`
  font-size: 26px;
  margin: 26px 0;
`;

export const Subtitle = styled.p`
  font-size: 15px;
  margin: 24px 0;
`;

export const FormContainer = styled.div`
  margin: 0 auto;
  max-width: 480px;
`;
