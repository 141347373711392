import styled from "styled-components";

import { Colors } from "styles/contracts";

export { Restrainer } from "styles/components";

export const Main = styled.div`
  background: ${Colors.Light3};
  padding-top: 64px;
`;

export const MainContainer = styled.div`
  background: ${Colors.Light1};
  border-radius: 8px;
  padding: 48px 60px 96px 60px;
`;

// content

export const Title = styled.h4`
  font-size: 26px;
  margin-bottom: 24px;
`;

export const GroupLinks = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 95%;
  height: auto;
`;

export const ItemDoc = styled.a`
  display: block;
  margin: 7px 0;
  text-decoration: none;
  color: #e52a49;
  text-align: left;
  &:hover {
    text-decoration: underline;
  }
`;
