import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { ReactComponent as PopoverTriangle } from "assets/icons/popover-triangle.svg";
import { ReactComponent as CaretDown } from "assets/icons/caret-down.svg";
import { Colors, Fonts } from "styles/contracts";

// handlers

const baseHandlerStyle = css`
  color: ${Colors.Light1};
  font-family: ${Fonts.NunitoBold};
  font-size: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

export const LinkHandler = styled(Link)`
  ${baseHandlerStyle}
`;

export const ExternalLinkHandler = styled.a`
  ${baseHandlerStyle}
`;

export const ActionHandler = styled.button`
  ${baseHandlerStyle}
`;

// in case of popover
export const PopoverContainer = styled.div``;

export const PopoverHandler = styled.div.attrs({
  className: "popover-handler",
})`
  ${baseHandlerStyle};
  position: relative;
  width: max-content;
  gap: 0 12px;
`;

export const CaretDownIcon = styled(CaretDown).attrs({
  className: "caret-down",
})``;

export const AnimatedContainer = styled.div.attrs({
  className: "animated-container",
})`
  position: absolute;
  display: flex;
  flex-direction: column;
  min-width: 160px;
  top: 32px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;

  animation: slideDownPopover 200ms ease-in-out forwards;

  @keyframes slideDownPopover {
    from {
      top: 24px;
      opacity: 0;
    }
    to {
      top: 32px;
      opacity: 1;
    }
  }
`;

export const TriangleContainer = styled.div`
  position: relative;
  min-width: inherit;
  height: 10px;
`;

export const TriangleIcon = styled(PopoverTriangle).attrs({
  className: "popover-triangle",
})`
  position: absolute;
  polygon {
    fill: ${Colors.Red4};
    stroke: ${Colors.Red4};
  }
`;

export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: ${Colors.Red4};
  min-width: inherit;
  width: max-content;
  padding: 24px 14px 24px 24px;
  border-radius: 4px;

  max-height: calc(100vh - 300px);
  overflow-y: scroll;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  ::-webkit-scrollbar:vertical {
    width: 10px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    border: 2px solid ${Colors.Red4};
    background-color: ${Colors.Dark5}44;
  }
  ::-webkit-scrollbar-track {
    background-color: ${Colors.Red4};
    border-radius: 4px;
  }
`;
