import React from "react";

import * as S from "./styles";

interface Props {
  title: string;
  isOpen?: boolean;
  children: React.ReactNode;
  onClick: () => void;
}

export const Accordion: React.FC<Props> = ({
  title,
  isOpen,
  onClick,
  children,
}) => {
  return (
    <S.Accordion isOpen={isOpen}>
      <S.Header onClick={onClick}>
        <S.Title>{title}</S.Title>
        <S.CaretIcon />
      </S.Header>
      <S.Body>{children}</S.Body>
    </S.Accordion>
  );
};
