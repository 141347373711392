import styled from "styled-components";
import { Colors, ScreenSize } from "styles/contracts";
export { Restrainer, ActivityIndicator } from "styles/components";

export const Main = styled.div`
  background: ${Colors.Light3};
`;

export const Header = styled.header`
  text-align: center;
  padding: 110px 0 72px 0;
`;

export const Title = styled.h1`
  font-size: 48px;
  margin-bottom: 32px;
`;

export const GalleriesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 42px;
  padding-bottom: 72px;

  @media ${ScreenSize.TabletAndDown} {
    grid-template-columns: 1fr 1fr;
  }

  @media ${ScreenSize.Mobile} {
    grid-template-columns: 1fr;
  }
`;

export const PaginatorContainer = styled.div`
  padding: 14px 0 140px 0;
`;
