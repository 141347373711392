import { CommentError } from "@styled-icons/boxicons-regular";
import styled, { css } from "styled-components";

import { Colors, Fonts } from "styles/contracts";

export { Restrainer, FormRow, ActivityIndicator } from "styles/components";

export const SearchAndFilter = styled.div`
  text-align: center;
  padding: 110px 0;
  input {
    background: ${Colors.Light1};
  }
`;

export const Title = styled.h1`
  font-size: 48px;
  margin-bottom: 32px;
`;

export const Categories = styled.div`
  // .swiper-slide {
  //   flex: 1 1 auto;
  // }
  .swiper-container {
    flex: 1 1 auto;
  }
  .next {
    color: red;
  }
`;

export const CategoriesTitle = styled.h3`
  font-size: 18px;
  font-family: ${Fonts.NunitoRegular};
  margin: 32px 0 24px 0;
`;

interface IButton {
  isActive?: boolean;
}

const getActiveStyle = ({ isActive }: IButton) => {
  if (!isActive) {
    return css`
      background: ${Colors.Light1};
      color: ${Colors.Dark3};
    `;
  }

  return css`
    background: ${Colors.Dark3};
    color: ${Colors.Light1};
  `;
};

export const CategoryButton = styled.button<IButton>`
  height: 32px;
  // min-width: 130px;
  font-family: ${Fonts.NunitoSemiBold};
  font-size: 16px;
  border-radius: 16px;
  padding: 0 24px;
  ${(props) => getActiveStyle(props)};
`;
