import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import {
  PaginateArticlesActions as Actions,
  PaginateArticlesState as State,
} from "store/ducks/articles";
import SearchAndFilter, { FilterProps } from "./SearchAndFilter";
import { ArticleCard } from "components/Articles";
import { Paginator } from "components/Shared";
import * as S from "./styles";

export const Main: React.FC = () => {
  const dispatch = useDispatch();
  const [query, setQuery] = useState({
    page: 1,
    limit: 9,
  });

  const {
    data: articles,
    pagination,
    loading,
  } = useSelector<RootStateOrAny, State>((state) => state.paginateArticles);

  const handleFilterChange = useCallback((filter: FilterProps): void => {
    setQuery((state) => ({ ...state, ...filter, page: 1 }));
  }, []);

  const handlePageChange = useCallback((page: number): void => {
    setQuery((state) => ({ ...state, page }));
  }, []);

  const fetchArticles = useCallback((): void => {
    dispatch(Actions.request(query));
  }, [dispatch, query]);

  useEffect(() => {
    fetchArticles();
  }, [fetchArticles]);

  return (
    <S.Main>
      <SearchAndFilter isBusy={loading} onChange={handleFilterChange} />
      <S.Restrainer>
        <S.ArticlesGrid>
          {articles.map((article, index) => (
            <ArticleCard key={index} article={article} zoomOnHover={true} />
          ))}
        </S.ArticlesGrid>
        <S.PaginatorContainer>
          <Paginator pagination={pagination} onPageChange={handlePageChange} />
        </S.PaginatorContainer>
      </S.Restrainer>
    </S.Main>
  );
};
