import React from "react";

import { directors, viceDirectors } from "data/administration";

import * as S from "./styles";

export const ExecutiveBoard: React.FC = () => {
  return (
    <S.Container>
      <S.Subtitle>Principais atribuições</S.Subtitle>
      <S.Text>
        O mandato de cada Diretoria é bienal. O cargo de Presidente por ser
        renovado por mais dois períodos. O presidente é auxiliado por uma
        diretoria indicada por ele e aprovada pelo conselho deliberativo.
        Compete à diretoria exercer os poderes atribuídos a cada pasta conforme
        estabelecido no Estatuto do Clube. Atualmente, compõe a diretoria do
        clube:
      </S.Text>
      <S.MembersTitle>Diretores</S.MembersTitle>
      <S.Members>
        {directors.map((member, index) => (
          <S.Member key={index}>
            <S.ImageContainer>
              <S.Image
                src={`/site/images/directors/${member.image}`}
                alt={member.name}
              />
            </S.ImageContainer>
            <S.Name>{member.name}</S.Name>
            {member?.role && <S.Role>{member.role}</S.Role>}
          </S.Member>
        ))}
      </S.Members>
      <S.MembersTitle>Subdiretores</S.MembersTitle>
      <S.Members>
        {viceDirectors.map((member, index) => (
          <S.Member key={index}>
            <S.ImageContainer>
              <S.Image
                src={`/site/images/vice-directors/${member.image}`}
                alt={member.name}
              />
            </S.ImageContainer>
            <S.Name>{member.name}</S.Name>
            {member?.role && <S.Role>{member.role}</S.Role>}
          </S.Member>
        ))}
      </S.Members>
    </S.Container>
  );
};
