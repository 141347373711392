import React from "react";
import { FormHandles } from "@unform/core";

function handleFormErrors(
  errors: any,
  ref: React.RefObject<FormHandles>
): void {
  if (!ref.current) return;

  for (const { path, message, inner } of errors.inner) {
    if (Array.isArray(inner)) {
      for (const error of inner) {
        handleFormErrors(error, ref);
      }
    }

    if (path !== undefined) {
      ref.current.setFieldError(path, message);
    }
  }
}

function handleApiErrors(
  errors: Record<string, any>[],
  ref: React.RefObject<FormHandles>
): void {
  if (!ref.current) return;
  for (const { field, message } of errors) {
    ref.current.setFieldError(field, message);
  }
}

export const useValidation = () => {
  return {
    handleFormErrors,
    handleApiErrors,
  };
};
