import React from "react";
import ReactMapGL, { Marker } from "react-map-gl";
import * as S from "./styles";

const TOKEN =
  "pk.eyJ1IjoiZm9jb2NvbXVuaWNhY2FvIiwiYSI6ImNqeHA5dXA2MjBmazMzYnA5dnRjeHRnd3kifQ.AmRrcNghNEmQOMaTN05f5A";

const viewport = {
  latitude: -22.527924,
  longitude: -44.109093,
  zoom: 15,
  bearing: 0,
  pitch: 0,
};

export const Main: React.FC = () => {
  return (
    <S.Main>
      <S.Restrainer>
        <S.MainContainer>
          <S.Title>Localização</S.Title>
          <S.Text>
            Rua Hiroshi Matsuda Filho, S/N - Vila Santa Cecília - Volta
            Redonda/RJ <br />
            CEP: 27261-260 (Antiga Rua 90) - Contato:
            atendimento@clubedosfuncionarios.com.br
            <br />
            Telefone: (24) 2102-2750
          </S.Text>
          <S.GroupMap>
            <ReactMapGL
              width={"100%"}
              height={452}
              {...viewport}
              mapStyle="mapbox://styles/fococomunicacao/cjxup1pyp0rs31ctcpixkv3nk"
              mapboxApiAccessToken={TOKEN}
              scrollZoom={true}
            >
              <Marker
                latitude={-22.527924}
                longitude={-44.109093}
                offsetLeft={450}
                offsetTop={110}
              >
                <S.PinIcon />
              </Marker>
            </ReactMapGL>
          </S.GroupMap>
        </S.MainContainer>
      </S.Restrainer>
    </S.Main>
  );
};
