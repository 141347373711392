import React from "react";

import * as S from "./styles";

// TODO: Get content from the site https://www.clubedosfuncionarios.com.br/esportes/2/bale/

export const Main: React.FC = () => {
  return (
    <S.Main>
      <S.Restrainer>
        <S.MainContainer>
          <S.ContentBlock>
            <S.Title>Balé</S.Title>
            <S.Text>
              Balé, Ballet ou Balê, é o nome dado a um estilo de dança e a sua
              performance. O termo deriva do italiano ballare que significa
              bailar. Os princípios básicos do balé são:
            </S.Text>
            <S.Text>
              Postura ereta;
              <br />
              Uso do en dehors (rotação externa dos membros inferiores);
              <br />
              Verticalidade corporal;
              <br />
              Simetria.
            </S.Text>
            <S.Text>
              O ballet tem suas raízes na Itália renascentista através das
              pantomimas (peças de teatro sem falas, utilizando apenas
              expressões faciais e corporais, geralmente improvisada)que eram
              realizados por atores e circenses em grandes salões para membros
              da corte.
            </S.Text>
            <S.Text>
              O casamento da italiana Catarina de Médicis com o Rei Henrique II
              da França em 1533 deu um importante impulso para o desenvolvimento
              do balé. Diversos artistas especializados em grandes e luxuosos
              espetáculos foram trazidos da Itália. Em 1581 Catarina de Médicis
              produziu o Ballet Comique de la Reine em Paris sob a direção do
              músico italiano Baldassarino de Belgiojoso ou Balthazar de
              Beaujoyeulx, nome que adotou na França. O balé tomou a forma na
              qual é conhecido hoje, na França durante o reinado de Luis XIII.
              No ano de 1661, seu filho Luis XIV fundou a Académie de Musique et
              de Danse, com o objetivo de sistematizar, preservar a qualidade e
              de fiscalizar o ensino e a produção do balé. Luis XIV nomeou
              Charles Louis Pierre de Beauchamps para tomar a frente da
              instituição que foi dissolvida em 1780.
            </S.Text>
            <S.Text>
              Os chamados balés de repertório se baseiam em composições musicais
              que contribuíram para torná-lo popular na Europa e depois no resto
              do mundo. Alguns dos balés mais notáveis são: Coppélia, de Léo
              Delibes, O Pássaro de Fogo, de Igor Stravinsky, O Quebra-Nozes e O
              Lago dos Cisnes, ambos de Tchaikovsky.
            </S.Text>
            <S.Text>
              A partir do Romantismo, as mulheres passaram a se destacar e
              contribuir para o aperfeiçoamento da arte. Marie Camargo por
              exemplo, criou o jeté, o pas de basque e o entrechat quatre, além
              de encurtar os vestidos até acima dos tornozelos e calçar sapatos
              sem saltos.
            </S.Text>
          </S.ContentBlock>
          <S.ContentBlock>
            <S.Subtitle>A importância dos atestados médicos</S.Subtitle>
            <S.Text>
              É obrigatório entregar os atestados médicos para a prática das
              atividades esportivas no clube, de acordo com a LEI 2.014/92 de
              15/07/1992.
            </S.Text>
            <S.Text>
              O Atestado médico deve ser específico a cada uma das modalidades,
              evitando quaisquer problemas de saúde aos associados.
            </S.Text>
            <S.Text>
              Para verificar suas condições de saúde e a aptidão para a prática
              da atividade física pretendida, o Departamento de Esportes
              recomenda que o associado procure o médico de sua confiança para
              fazer todos os exames necessários
            </S.Text>
          </S.ContentBlock>
          <S.ColumnsView>
            <S.ContentBlock>
              <S.RedSubtitle>
                • As atividades esportivas para não sócios são permitidas apenas
                para menores de 18 anos.
              </S.RedSubtitle>
              <S.Subtitle>Professora Aline(24) 9.9995-4002</S.Subtitle>
              <S.Text>
                (Segunda e quarta-feira)
                <br />
                14h:30 às 15h:30 - Avançado - (14, 15 e 16 anos)
                <br />
                16h00 às 17h00 - (12 e 13 anos)
                <br />
                17h00 às 18h00 - Intermediário - (13,14 e 15 anos)
                <br />
                18h00 às 18h40 - Baby (03, 04, 05 e 06 anos) - Lotada - Lista de
                espera com a professora
                <br />
                18h40 às 19h20 - Preparatório - (7,8 e 9 anos )<br />
                19h20 às 20h30 - Intermediário - (13,14 e 15 anos)
                <br />
                <br />
                (Terça e quinta-feira)
                <br />
                17h15 às 18h15
                <br />
                18h00 às 19h00 - Iniciação à ponta (10,11 e 12 )<br />
                19h00 às 20h00 - Avançado (Avaliação)
                <br />
                <br />
                (Sexta-feira)
                <br />
                19h00 às 20h30 - Adultos(masc/fem), homens são bolsistas.
                <br />
                <br />
                <strong>
                  Mensalidade: (Devem ser pagas na secretaria do clube dos
                  funcionários:
                </strong>
                <br />
                Sócio: R$ 60,00
                <br />
                Não sócio: R$ 90,00
                <br />
                <br />
                <strong>Matrícula:</strong>
                <br />
                Matrícula não sócio: R$ 50,00
                <br />
                <br />
                Local: Sede social 5º andar.
              </S.Text>
            </S.ContentBlock>
          </S.ColumnsView>
        </S.MainContainer>
      </S.Restrainer>
    </S.Main>
  );
};
