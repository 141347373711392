import { FacebookCircle } from "@styled-icons/boxicons-logos/FacebookCircle";
import { Instagram } from "@styled-icons/boxicons-logos/Instagram";
import styled from "styled-components";

import { ReactComponent as Person } from "assets/icons/person.svg";

export { FormRow, Button } from "styles/components";

export const AccessWidget = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  column-gap: 5px;
  width: 508px;
`;

export const WidgetTitle = styled.h3`
  font-size: 16px;
  margin-right: 15px;
`;

export const GroupAccess = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
`;

export const PersonIcon = styled(Person)`
  fill: currentColor;
  width: 14px;
  height: 14px;
  margin-right: 8px;
`;

export const ButtonFoward = styled.a`
  background: #000;
  padding: 10px 15px;
  color: #fff;
  max-width: 80px;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 45px;
  &:hover {
    background: #222;
  }
`;

export const FaceIcon = styled(FacebookCircle)`
  color: #222;
  margin-right: 5px;
`;
export const InstaIcon = styled(Instagram)`
  color: #222;
  margin-right: 5px;
`;

export const SocialLink = styled.a`
   display:flex;
   align-items:center;
   justify:content:center;
   margin-right:15px;
   text-decoration:none;
   cursor:pointer;
   color:#222;
   img.logo-canal {
     width:40px;
     height:auto;
     margin-bottom:5px;
   }
`;
