import styled from "styled-components";
import { Colors } from "styles/contracts";

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0 8px;
`;

interface IButton {
  active?: boolean;
}

export const Button = styled.button<IButton>`
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ active }) => (active ? Colors.Light1 : Colors.Dark3)};
  background-color: ${({ active }) => (active ? Colors.Dark3 : Colors.Light1)};
  font-size: 14px;
  font-weight: bold;
  border-radius: 6px;
  transition: all 300ms ease-in-out;

  &:hover {
    background-color: ${Colors.Dark3};
    color: ${Colors.Light1};
  }
`;
