import React, { useCallback, useEffect } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";

import { FetchArticleActions as Actions, FetchArticleState as State } from "store/ducks/articles";
import { Formatter } from "utils";

import * as S from "./styles";

interface Props {
  articleId: string | number;
}

export const Main: React.FC<Props> = ({ articleId }) => {
  const dispatch = useDispatch();

  const { data: article } = useSelector<RootStateOrAny, State>(
    (state) => state.fetchArticle
  );

  const fetchArticle = useCallback((): void => {
    dispatch(Actions.request(articleId));
  }, [articleId, dispatch]);

  useEffect(() => {
    fetchArticle();
  }, [fetchArticle]);

  return (
    <S.Main>
      <S.Restrainer>
        {article && (
          <S.MainContainer>
            <S.MainRestrainer>
              <S.ImageContainer>
                <S.Image src={article.image_url} alt={article.title} />
              </S.ImageContainer>
              <S.Title>{article.title}</S.Title>
              {/* <S.Info>
                <S.Category>{article.category.name}</S.Category>
                <S.Dot>&bull;</S.Dot>
                <S.DateTime>
                  {Formatter.date(article.published_at, {
                    format: "dd/MM/yyyy HH:mm",
                  })}
                </S.DateTime>
              </S.Info> */}
              <S.Content
                dangerouslySetInnerHTML={{ __html: article.content }}
              />
            </S.MainRestrainer>
          </S.MainContainer>
        )}
      </S.Restrainer>
    </S.Main>
  );
};
