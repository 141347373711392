import styled from "styled-components";
import { Link } from "react-router-dom";
import { Colors } from "styles/contracts";

import { Instagram } from "@styled-icons/boxicons-logos/Instagram";
import { Facebook } from "@styled-icons/fa-brands/Facebook";

import { ReactComponent as HamburgerMenu } from "assets/icons/hamburger-menu.svg";

export { Restrainer } from "styles/components";

export const MobileTopBar = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  background: #222;
  min-height: 50px;
  padding: 10px 20px;

  img.icon {
    width: auto;
    height: 45px;
    border-radius: 5px;
    margin-right: 10px;
    &.whitebg {
      background: #fff;
    }
    @media screen and (max-width: 460px) {
      width: 38px;
      height: auto;
      background: #fff;
    }
  }
`;

export const InstaLogo = styled(Instagram)`
  color: #fff;
`;
export const FaceLogo = styled(Facebook)`
  color: #fff;
  margin: 0 15px 0 20px;
`;

export const MobileNavigation = styled.header`
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  height: 52px;
  z-index: 9;
  background: ${Colors.Light2};
`;

export const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0 16px;
  height: inherit;
  background: #f2f2f2;
`;

// Homepage link
export const HomepageLink = styled(Link)`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index: 1 !important;
`;

export const GroupIcons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0;
  padding-top: 6px;
  margin-right: 35px;

  img.icons {
    width: auto;
    height: 40px;
    margin-right: 10px;
    &.last {
      margin-right: 0;
    }
  }
`;

export const LogoImage = styled.img`
  width: 100%;
  max-width: 120px;
  height: auto;
`;

// Menu Button
export const MenuButton = styled.button`
  background: transparent;
  width: 44px;
  height: 44px;
`;

export const MenuIcon = styled(HamburgerMenu)`
  width: 18px;
  height: 18px;
`;
