interface Member {
  name: string;
  image: string;
  role?: string;
}

export const bornMembers: Member[] = [
  {
    name: "Evandro Ruy da Costa Lima",
    image: "evandro-ruy-da-costa-lima.jpg",
    role: "Presidente",
  },
  {
    name: "Nilton José Bueno",
    image: "milton-bueno.jpg",
    role: "Vice-presidente",
  },
  {
    name: "Antonio Carlos Marins",
    image: "antonio-carlos-marins.jpg",
  },
  {
    name: "Arino Gonçalves de Oliveira",
    image: "arino-goncalves-de-oliveira.jpg",
  },
  {
    name: "Carlos Roberto Paiva",
    image: "carlos-roberto-paiva.jpg",
  },
  {
    name: "Djalma Gamis de Oliveira",
    image: "djalma-gamis-de-oliveira.jpg",
  },
  {
    name: "Evandro Ruy da Costa Lima",
    image: "evandro-ruy-da-costa-lima.jpg",
  },
  {
    name: "Expedito Gonçalves de Aquino",
    image: "expedito-goncalves-de-aquino.jpg",
  },
  {
    name: "Fernando Parente de Matos",
    image: "fernando-parente-de-matos.jpg",
  },
  // {
  //   name: "Fernando Antônio R de Almeida",
  //   image: "fernando-antonio-de-almeida.jpg",
  // },
  // {
  //   name: "Jair Azevedo Lima",
  //   image: "jair-azevedo-lima.jpg",
  // },
  {
    name: "José Mauro Lemos",
    image: "jose-mauro-lemos.jpg",
  },
  {
    name: "Nilton José Bueno",
    image: "nilton-jose-bueno.jpg",
  },
  // {
  //   name: "Rafael de Paiva Lima",
  //   image: "rafael-de-paiva-lima.jpg",
  //   role: "Representante da CSN",
  // },
  {
    name: "Cláudio Augusto de O. Ferreira",
    image: "claudio-augusto-de-o-ferreira.jpg",
    role: "Licenciado",
  },
];
