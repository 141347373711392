import styled from "styled-components";
import { BaseExternalLinkButton } from "styles/components";
import { Colors, Fonts } from "styles/contracts";
export { Restrainer } from "styles/components";

export const Main = styled.div`
  background: ${Colors.Light3};
  padding-bottom: 110px;
`;

export const MainContainer = styled.div`
  margin-top: -100px;
  background: ${Colors.Light1};
  border-radius: 8px;
  padding: 34px 60px 60px 60px;
  text-align: center;
  @media screen and (max-width: 800px) {
    margin-top: -32px;
  }
`;

export const Title = styled.h4`
  font-size: 26px;
  margin: 26px 0;
`;

export const Text = styled.p`
  font-size: 16px;
  span {
    font-weight: bold;
  }
  &:not(:last-child) {
    margin-bottom: 24px;
  }
`;

export const DownloadContainer = styled.div`
  margin-top: 72px;
`;

export const DownloadButton = styled(BaseExternalLinkButton)`
  font-family: ${Fonts.NunitoSemiBold};
  border-radius: 24px;
  padding: 0 28px;
  background: ${Colors.Red3};
  color: ${Colors.Light1};
  gap: 0 8px;
`;
