import React, { useCallback, useEffect } from "react";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import {
  PaginateArticlesActions as Actions,
  PaginateArticlesState as State,
} from "store/ducks/articles";
import { ArticleCard } from "components/Articles";
import * as S from "./styles";

export const RelatedArticles: React.FC = () => {
  const dispatch = useDispatch();

  const { data: articles } = useSelector<RootStateOrAny, State>(
    (state) => state.paginateArticles
  );

  const fetchArticles = useCallback((): void => {
    dispatch(Actions.request({ category: 4, limit: 3 }));
  }, [dispatch]);

  useEffect(() => {
    fetchArticles();
  }, [fetchArticles]);

  return (
    <S.RelatedArticles>
      <S.Restrainer>
        <S.Title>Notícias Relacionadas</S.Title>
        <S.ArticlesGrid>
          {articles.map((article, index) => (
            <ArticleCard zoomOnHover key={index} article={article} />
          ))}
        </S.ArticlesGrid>
      </S.Restrainer>
    </S.RelatedArticles>
  );
};
