import styled from "styled-components";

interface IRestrainer {
  maxWidth?: "1100px" | "1270px" | "720px";
}

export const Restrainer = styled.section.attrs({
  className: "restrainer",
})<IRestrainer>`
  max-width: ${({ maxWidth }) => maxWidth || "1100px"};
  margin: 0 auto;
  @media (max-width: ${({ maxWidth }) => maxWidth || "1100px"}) {
    padding: 0 16px;
    .restrainer {
      padding: 0 0;
    }
  }
`;
