import React, { useCallback, useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";

import { Modal } from "components/Shared";
import { VideoCard } from "components/Videos";
import { PaginatedVideo } from "contracts/Videos";
import { PaginateVideosActions as Actions, PaginateVideosState as State } from "store/ducks/videos";

import * as S from "./styles";

const getYoutubeembedUrl = (url: string): string => {
  const videoId = url.split("v=")[1];
  const ampersandPosition = videoId.indexOf("&");
  const id =
    ampersandPosition !== -1 ? videoId.slice(0, ampersandPosition) : videoId;
  return `https://www.youtube.com/embed/${id}`;
};

export const Videos: React.FC = () => {
  const dispatch = useDispatch();

  const [selectedVideo, setSelectedVideo] = useState<PaginatedVideo | null>(
    null
  );

  const { data: videos, loading } = useSelector<RootStateOrAny, State>(
    (state) => state.paginateVideos
  );

  const fetchVideos = useCallback((): void => {
    dispatch(Actions.request({ limit: 3 }));
  }, [dispatch]);

  useEffect(() => {
    fetchVideos();
  }, [fetchVideos]);

  return (
    <S.Videos>
      <S.Restrainer>
        <S.Title>Vídeos {loading && <S.ActivityIndicator />}</S.Title>
        <S.VideosGrid>
          {videos.map((video) => (
            <VideoCard
              zoomOnHover
              onClick={(v) => setSelectedVideo(v)}
              video={video}
              key={video.id}
            />
          ))}
        </S.VideosGrid>
        <S.VideosFooter>
          <S.MoreButton
            href="https://www.youtube.com/channel/UCvzqofGd3vmJIVeWt6IfkOA"
            target="_blank"
          >
            <S.YouTubeIcon /> <span>Confira nosso canal</span>
          </S.MoreButton>
        </S.VideosFooter>
      </S.Restrainer>

      <Modal
        onClickOutside={() => setSelectedVideo(null)}
        isOpen={!!selectedVideo}
      >
        <S.ModalContent>
          <S.CloseButton onClick={() => setSelectedVideo(null)}>
            &times;
          </S.CloseButton>
          {selectedVideo && (
            <S.VideoContainer>
              <S.VideoIframe
                src={getYoutubeembedUrl(selectedVideo.url)}
                title={selectedVideo.title}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              />
            </S.VideoContainer>
          )}
        </S.ModalContent>
      </Modal>
    </S.Videos>
  );
};
