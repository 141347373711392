import styled from "styled-components";
import { Colors } from "styles/contracts";
export { Restrainer } from "styles/components";

export const Main = styled.div`
  background: ${Colors.Light3};
  padding-top: 64px;
`;

export const MainContainer = styled.div`
  background: ${Colors.Light1};
  border-radius: 8px;
  padding: 48px 60px 96px 60px;
`;

// content

export const Title = styled.h4`
  font-size: 26px;
  margin-bottom: 24px;
`;

export const Accordions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px 0;
`;
