import React, { useCallback, useEffect, useMemo, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import SwiperCore from "swiper";
import { Swiper, SwiperProps, SwiperSlide } from "swiper/react";

import { ArticleCard } from "components/Articles";
import {
  PaginateArticlesActions as Actions,
  PaginateArticlesState as State,
} from "store/ducks/articles";

import * as S from "./styles";

const defaultSwiperProps: SwiperProps = {
  spaceBetween: 42,
  loop: true,
  autoplay: false,
  breakpoints: {
    920: {
      slidesPerView: 3,
    },
    640: {
      slidesPerView: 2,
    },
  },
};

interface Props {
  zoomOnHover?: boolean;
}

export const ArticlesCarousel: React.FC<Props> = ({ zoomOnHover = true }) => {
  const dispatch = useDispatch();
  const [swiper, setSwiper] = useState<SwiperCore | null>(null);

  const { data: articles, loading } = useSelector<RootStateOrAny, State>(
    (state) => state.paginateArticles
  );

  const fetchArticles = useCallback((): void => {
    dispatch(Actions.request());
  }, [dispatch]);

  const swiperProps = useMemo((): SwiperProps => {
    return {
      ...defaultSwiperProps,
      ...(zoomOnHover && {
        spaceBetween: 42 - 32,
      }),
    };
  }, [zoomOnHover]);

  const PrevButton = useCallback((): JSX.Element => {
    if (!swiper) return <></>;
    return (
      <S.Button whileHover={{ scale: 1.2 }} onClick={() => swiper?.slidePrev()}>
        <S.PrevIcon />
      </S.Button>
    );
  }, [swiper]);

  const NextButton = useCallback((): JSX.Element => {
    if (!swiper) return <></>;
    return (
      <S.Button whileHover={{ scale: 1.2 }} onClick={() => swiper?.slideNext()}>
        <S.NextIcon />
      </S.Button>
    );
  }, [swiper]);

  useEffect(() => {
    fetchArticles();
  }, [fetchArticles]);
  console.log("article content: ", articles);
  return (
    <S.ArticlesCarousel>
      <S.Restrainer>
        <S.Title>Últimas Notícias {loading && <S.ActivityIndicator />}</S.Title>
      </S.Restrainer>
      <S.CarouselRestrainer zoomOnHover={zoomOnHover}>
        <S.CarouselContainer>
          <Swiper initialSlide={3} onSwiper={setSwiper} {...swiperProps}>
            {articles.map((article, index) => (
              <SwiperSlide key={index}>
                <ArticleCard
                  zoomOnHover={zoomOnHover}
                  article={article}
                  key={index}
                />
              </SwiperSlide>
            ))}
          </Swiper>
          <S.CarouselControls>
            <PrevButton />
            <NextButton />
          </S.CarouselControls>
        </S.CarouselContainer>
      </S.CarouselRestrainer>
      <S.Restrainer>
        <S.Separator />
      </S.Restrainer>
    </S.ArticlesCarousel>
  );
};
