import React, { useState, useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { PageBanner } from "components/Shared";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import Scaffold from "layouts/Scaffold";
import * as C from "./RelatedArticles";
import * as S from "./styles";
import { trees } from "../../data/trees/tree";
import {
  PaginateArticlesActions as Actions,
  PaginateArticlesState as State,
} from "store/ducks/articles";

export const TreesPage: React.FC = () => {
  const { slug } = useParams();
  const dispatch = useDispatch();

  const { data: articles } = useSelector<RootStateOrAny, State>(
    (state) => state.paginateArticles
  );

  const fetchArticles = useCallback((): void => {
    dispatch(Actions.request());
  }, [dispatch]);

  useEffect(() => {
    fetchArticles();
  }, [fetchArticles]);

  console.log("valor de articles: ", articles);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [postPerPage, setPostPerPage] = useState<number>(6);

  const totalPosts = trees.length;
  const pageNumbers = [];

  const indexOfLastPost = currentPage * postPerPage;
  const indexOfFirstPost = indexOfLastPost - postPerPage;
  const currentPosts = trees.slice(indexOfFirstPost, indexOfLastPost);

  for (let i = 1; i <= Math.ceil(totalPosts / postPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <Scaffold>
      <PageBanner
        image={require("assets/images/trees.jpg")}
        title="Árvores do Clube"
        subtitle="Conheça a natureza que cerca o associado."
      />
      <S.Container>
        <S.Content>
          <S.GroupCards>
            {currentPosts.map((item, i) => (
              <S.Card key={i}>
                <img
                  src={
                    process.env.PUBLIC_URL +
                    `/images/trees/thumbs/${item.thumb}`
                  }
                  alt={item.name}
                />
                <p className="title">{item.name}</p>
                <p className="text">Nome Vulgar: {item.vulgar_name}</p>
                <p className="text">
                  Nome Científico:<span>{item.science_name}</span>
                </p>
                <a href={"arvores-do-clube/" + item.slug} className="link">
                  saiba mais
                </a>
              </S.Card>
            ))}
          </S.GroupCards>
          <S.PaginationContent>
            {pageNumbers.map((page, i) => (
              <li key={page}>
                <a
                  href="javascript:void(0)"
                  onClick={() => {
                    setCurrentPage(page);
                  }}
                >
                  {page}
                </a>
              </li>
            ))}
          </S.PaginationContent>
        </S.Content>
      </S.Container>
      <C.RelatedArticles />
    </Scaffold>
  );
};
