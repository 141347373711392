import React from "react";

import { benefactors } from "data/administration";

import * as S from "./styles";

export const Benefactors: React.FC = () => {
  return (
    <S.Container>
      <S.Members>
        {benefactors.map((member, index) => (
          <S.Member key={index}>
            <S.ImageContainer>
              <S.Image
                src={`/site/images/benefactors/${member.image}`}
                alt={member.name}
              />
            </S.ImageContainer>
            <S.Name>{member.name}</S.Name>
            {member?.role && <S.Role>{member.role}</S.Role>}
          </S.Member>
        ))}
      </S.Members>
    </S.Container>
  );
};
