import styled from "styled-components";

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  width: 95%;
  max-width: 1100px;
  margin: 60px auto 30px auto;

  padding: 10px;
`;

export const GroupLoading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  max-width: 200px;
  margin: 0 auto;
  padding: 20px;
  img {
    width: 50px;
    height: auto;
  }
`;

export const PageTitle = styled.h1`
  font-size: xx-large;
  color: #222;
  margin: 15px auto;
`;

export const Warning = styled.h2`
  font-size: x-large;
  color: #222;
  margin: 15px auto;
`;
export const GroupResp = styled.a`
  display: block;
  width: 100%;
  padding: 20px;
  border-bottom: 1px dotted #c5c5c5;
  text-decoration: none;
  color: #000;
  cursor: pointer;
  &:hover {
    background: #222;
    color: #fff;
  }
`;
export const RespTitle = styled.h2`
  margin-top: 30px;
  font-size: large;
  color: #222;
  margin: 10px 0 20px 0;
  &.last {
    margin-top: 50px;
  }
`;
