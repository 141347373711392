import styled from "styled-components";

export const Banner = styled.div`
  z-index: -1;
`;

export const BannerContainer = styled.figure`
  max-width: 1550px;
  width: 100%;
  margin: 0px auto;
  display: flex;
  position: relative;
`;

export const BannerImage = styled.img`
  max-width: 100%;
  height: auto;
`;

export const Overlay = styled.div`
  position: absolute;
  inset: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const OverlayContainer = styled.div`
  padding: 24px;
  max-width: 100%;
  text-align: center;
  color: #fff;
  text-shadow: 0px 4px 4px #00000066;
`;

export const Title = styled.h1`
  color: currentColor;
  margin-top: -100px;
  margin-bottom: 1rem;
  font-family: "Nunito-ExtraBold";
  text-transform: uppercase;
  font-size: 92px;
  @media screen and (max-width: 800px) {
    font-size: 32px;
    margin-top: -32px;
  }
`;

export const Subtitle = styled.p`
  font-size: 18px;
  font-family: "Nunito-Regular";
  text-transform: uppercase;
  @media screen and (max-width: 800px) {
    font-size: 16px;
  }
`;
