import React from "react";

import SearchBar from "../SearchBar";
import AccessWidget from "./AccessWidget";
import InfoBar from "./Infobar";
import NavigationBar from "./NavigationBar";
import * as S from "./styles";

const DesktopNavigation: React.FC = () => {
  return (
    <S.DesktopNavigation>
      {/* Info bar */}
      <InfoBar />
      {/* Top Header */}
      <S.Restrainer>
        <S.TopContainer>
          <S.GroupInfo>
            <S.HomepageLink to="/">
              <S.LogoImage src={require("assets/images/logo.png")} alt="Logo" />
            </S.HomepageLink>
            <div>
              <img
                src={require("assets/images/top-100.png")}
                alt="Logo Top 100"
              />
            </div>
            <div>
              <a target="_blank" rel="logo" href="https://www.cbclubes.org.br/">
                <img
                  src={require("assets/images/selo-cbc.png")}
                  alt="Logo CBC"
                />
              </a>
            </div>
          </S.GroupInfo>

          <S.MainArea>
            <AccessWidget />
          </S.MainArea>
        </S.TopContainer>
        <SearchBar />
      </S.Restrainer>
      {/* The red navigation bar */}
      <S.BottonContainer>
        <S.Restrainer>
          <NavigationBar />
        </S.Restrainer>
      </S.BottonContainer>
    </S.DesktopNavigation>
  );
};

export default DesktopNavigation;
