import React from "react";

import * as S from "./styles";

export const Main: React.FC = () => {
  return (
    <S.Main>
      <S.Restrainer>
        <S.MainContainer>
          <S.ContentBlock>
            <S.Title>Basquete</S.Title>
            <S.Text>
              O basquetebol é um desporto em plena evolução no mundo, cada vez
              mais difundido nas competições de alto nível e também como forma
              de recreação. Teve nos Estados Unidos o seu berço e lá também se
              constata a sua maior difusão e, conseqüentemente, o maior
              desenvolvimento técnico e a melhor performance em termos de
              ranking internacional. O basquetebol é um esporte completo,
              constituindo-se numa sucessão de esforços intensos e breves em
              vários ritmos, podendo alinhar corridas, saltos e lançamentos. Por
              estas razões coordena o ritmo e os movimentos humanos.
            </S.Text>
            <S.Text>
              Neste esporte, o trabalho físico dos grupamentos musculares ocorre
              de maneira coordenada e ritmada e, sem dúvida, os membros
              inferiores são os mais exigidos, embora esses movimentos sejam
              executados com muita flexibilidade e contrações rápidas. Os
              músculos abdominais e dorsais são freqüentemente exigidos, através
              de rotações, flexões, etc. Os músculos dos membros superiores
              realizam um trabalho onde os movimentos suaves, precisos e
              ajustados constituem artifícios essenciais nas conclusões dos
              passes, dribles e arremessos. O equilíbrio emocional de uma parte
              e a técnica da outra proporcionam a precisão e segurança que devem
              coexistir com a velocidade e a explosão. No campo moral, o
              basquetebol desperta no praticante o amor próprio, o espírito de
              cooperação, a solidariedade, o respeito ao próximo, a lealdade, a
              disciplina, a força de vontade etc. Em síntese, podemos concluir
              que o basquetebol é um excelente meio de formação física, moral e
              social do indivíduo.
            </S.Text>

            <S.ContentBlock>
              <S.Subtitle>A importância dos atestados médicos</S.Subtitle>
              <S.Text>
                É obrigatório entregar os atestados médicos para a prática das
                atividades esportivas no clube, de acordo com a LEI 2.014/92 de
                15/07/1992.
              </S.Text>
              <S.Text>
                O Atestado médico deve ser específico a cada uma das
                modalidades, evitando quaisquer problemas de saúde aos
                associados.
              </S.Text>
              <S.Text>
                Para verificar suas condições de saúde e a aptidão para a
                prática da atividade física pretendida, o Departamento de
                Esportes recomenda que o associado procure o médico de sua
                confiança para fazer todos os exames necessários.
              </S.Text>

              <S.RedSubtitle>
                • As atividades esportivas para não sócios são permitidas apenas
                para menores de 18 anos.
              </S.RedSubtitle>
            </S.ContentBlock>
          </S.ContentBlock>
          <S.ContentBlock>
            <S.Subtitle>BASQUETE MASCULINO</S.Subtitle>
            <S.Text>
              <span>Professor Dé</span>
              <br />
              <br />
              Equipe:
              <br />
              Segunda e Quarta-feira: 20h30 às 22h00
              <br />
              Sexta-feira: 18h30 às 20h00
              <br />
            </S.Text>
            <S.Text>
              Pré-Equipe:
              <br />
              Terça e Quinta-feira: 15h00 às 16h00
              <br />
              Terça e Quinta-feira: 16h00 às 17h00
              <br />
            </S.Text>
            <S.Text>
              Segunda e Quarta-feira: 18h30 às 19:30h (2011 a 2012) - 7 Vagas
              <br />
              Segunda e Quarta-feira: 19h30 às 20:30h (2010 a 2011) - 1 Vaga
              <br />
              Terça e Quinta-feira: 20h00 às 21:00h (2008 a 2009) - 10 Vagas
              <br />
            </S.Text>
          </S.ContentBlock>
          <S.ContentBlock>
            <S.Subtitle>BASQUETE FEMININO</S.Subtitle>
            <S.Text>
              Terça e Quinta-feira: 19h00 às 20h00 (2005 até 2016) - 9 Vagas
              <br />
            </S.Text>
            <S.Text>
              <span>Valores:</span>
              <br />
              <br />
              <span>Mensalidade para sócios:</span> R$ 69,00 (2x na semana) - R$
              89,00 (3x na semana)
              <br />
              <br />
              <span>Matrícula apenas para sócio:</span> R$ 50,00
              <br />
              <span>Mensalidade para não sócios:</span> R$ 116,50 (2x na semana)
              - R$ 166,00 (3x na semana)
            </S.Text>
          </S.ContentBlock>
          <S.ContentBlock>
            <S.Subtitle>Professor Márcio</S.Subtitle>
            <S.Text>
              Terça e Quinta-feira: 18h00 às 19h00 - Nascidos em 2014/2015/2016
              11 Vagas
              <br />
              Terça e Quinta-feira: 18h00 às 19h00 - Nascidos em 2012/2013 5
              Vagas
              <br />
              Terça e Quinta-feira: 20h00 às 21h00 - Nascidos em 2006/2007/2008
              1 Vaga
              <br />
              <S.RedSubtitle>
                Turmas lotadas inserir nome na lista de espera
              </S.RedSubtitle>
            </S.Text>
            <S.Text>
              <span>Valores para Sócios</span>
              <br />
              <span>Mensalidade para Sócios: </span> R$ 76,00 (2x na semana) -
              R$ 98,00 (3x na semana)
              <br />
              <br />
              <span>Valores para não Sócios:</span>
              <br />
              <span>Matrícula:</span> R$ 50,00
              <br />
              <span>Mensalidade:</span> R$ 128,00 (2x na semana) - R$ 183,00 (3x
              na semana)
              <br />
              Aumento de 10% para 2024.
              <br />
            </S.Text>
          </S.ContentBlock>
          <S.ContentBlock>
            <S.Subtitle>Professor Dagobel (24) 98137-4513</S.Subtitle>
            <S.Text>
              (Segunda, Quarta e Sexta-feira)
              <br />
              14h00 às 16h00 - Sub 15 (Turma Lotada)
              <br />
              16h00 às 17h30 - Sub 13 (Turma Lotada)
              <br />
              17h30 às 18h30 - Sub 12 (Turma Lotada)
              <br />
              <br />
              <span>Mediante Autorização do Professor</span>
              <br />
              <br />
              <S.RedSubtitle>
                Turmas lotadas inserir nome na lista de espera
              </S.RedSubtitle>
            </S.Text>
            <S.Text>
              <span>Valores para Sócios</span>
              <br />
              <span>Mensalidade para Sócios: </span> R$ 69,00 (2x na semana) -
              R$ 98,00 (3x na semana)
              <br />
              <br />
              <span>Valores para não Sócios:</span>
              <br />
              <span>Matrícula:</span> R$ 50,00
              <br />
              <span>Mensalidade:</span> R$ 116,50 (2x na semana) - R$ 183,00 (3x
              na semana) <br />
            </S.Text>
          </S.ContentBlock>
        </S.MainContainer>
      </S.Restrainer>
    </S.Main>
  );
};
