import React from "react";

import { deliberativeConcil, fiscalCommission } from "data/administration";

import * as S from "./styles";

export const DeliberativeConcil: React.FC = () => {
  return (
    <S.Container>
      <S.MembersTitle>Conselho deliberativo</S.MembersTitle>
      <S.Members>
        {deliberativeConcil.map((member, index) => (
          <S.Member key={index}>
            <S.ImageContainer>
              <S.Image
                src={`/site/images/deliberative-concil/${member.image}`}
                alt={member.name}
              />
            </S.ImageContainer>
            <S.Name>{member.name}</S.Name>
            {member.role && <S.Role>{member.role}</S.Role>}
          </S.Member>
        ))}
      </S.Members>
      <S.MembersTitle>Comissão fiscal</S.MembersTitle>
      <S.Members>
        {fiscalCommission.map((member, index) => (
          <S.Member key={index}>
            <S.ImageContainer>
              <S.Image
                src={`/site/images/fiscal-commission/${member.image}`}
                alt={member.name}
              />
            </S.ImageContainer>
            <S.Name>{member.name}</S.Name>
            {member.role && <S.Role>{member.role}</S.Role>}
          </S.Member>
        ))}
      </S.Members>
      <S.Content>
        <S.Title>Constituição</S.Title>

        <S.TextGrid>
          <div>
            <S.Subtitle>Membros natos</S.Subtitle>
            <S.Text>
              Os ex-presidentes do Clube
              <br />
              Os sócios beneméritos
            </S.Text>
          </div>
          <div>
            <S.Subtitle>Membros temporários</S.Subtitle>
            <S.Text>
              32 (Trinta e Dois) sócios eleitos
              <br />
              O Presidente do Clube
              <br />O Presidente do Grande Conselho
            </S.Text>
          </div>
        </S.TextGrid>
        <S.Subtitle>Principais atribuições</S.Subtitle>
        <S.Text>
          Julgar as contas mensais (balancetes) após parecer da Comissão Fiscal
          Homologar a indicação de Diretores e Sub-Diretores Apreciar e aprovar
          o orçamento anual Conceder títulos de sócio beneméritos e honorário
          Propor alteração do estatuto Aprovar proposta da Diretoria para
          eliminação ou suspensão de associado superior a 6 (seis) meses Julgar
          atos e omissões de membros da Diretoria
        </S.Text>
        <S.Subtitle>Comissão fiscal</S.Subtitle>
        <S.Text>
          A Comissão Fiscal, eleita bienalmente pelo Conselho Deliberativo, é
          composta por 6 (Seis) conselheiros, 3 (Três) efetivos e 3 (Três)
          suplentes.
        </S.Text>
        <S.Subtitle>Principais atribuições</S.Subtitle>
        <S.Text>
          Examinar os balancetes mensais e controles orçamentários.
          <br />
          Examinar o balanço anual e controles orçamentários.
        </S.Text>
      </S.Content>
    </S.Container>
  );
};
