import React from "react";

import { exPresidents } from "data/administration";

import * as S from "./styles";

export const ExPresidents: React.FC = () => {
  return (
    <S.Container>
      <S.MembersTitle>Ex presidentes</S.MembersTitle>
      <S.Members>
        {exPresidents.map((member, index) => (
          <S.Member key={index}>
            <S.ImageContainer>
              <S.Image
                src={`/site/images/ex-presidents/${member.image}`}
                alt={member.name}
              />
            </S.ImageContainer>
            <S.Name>{member.name}</S.Name>
            <S.Period>
              {member.deceased && <S.CruxIcon />}
              {member.period[0]}-{member.period[1]}
            </S.Period>
          </S.Member>
        ))}
      </S.Members>
    </S.Container>
  );
};
