import React, { useLayoutEffect } from "react";
import ReactGA from "react-ga";
import { Provider } from "react-redux";
import { BrowserRouter, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { PersistGate } from "redux-persist/integration/react";

import AppRoutes from "routes";
import { persistor, store } from "store";
import GlobalStyle from "styles/global";

import "react-toastify/dist/ReactToastify.css";
import "swiper/css";

toast.configure();

const ScrollToTop: React.FC = ({ children }) => {
  const location = useLocation();

  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);

  return <React.Fragment>{children}</React.Fragment>;
};

const App: React.FC = () => {
  ReactGA.initialize("UA-717432-62");
  ReactGA.pageview(window.location.pathname);
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <BrowserRouter basename="site">
          <GlobalStyle />
          <ScrollToTop>
            <AppRoutes />
          </ScrollToTop>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
};

export default App;
