import React from "react";

import * as S from "./styles";

export const Main: React.FC = () => {
  return (
    <S.Main>
      <S.Restrainer>
        <S.MainContainer>
          <S.ContentBlock>
            <S.Title>Futebol</S.Title>
            <S.Text>
              O futebol é um dos esportes mais populares no mundo. Praticado em
              centenas de países, este esporte desperta tanto interesse em
              função de sua forma de disputa atraente. Embora não se tenha muita
              certeza sobre os primórdios do futebol, historiadores descobriram
              vestígios dos jogos de bola em várias culturas antigas. Estes
              jogos de bola ainda não eram o futebol, pois não havia a definição
              de regras como há hoje, porém demonstram o interesse do homem por
              este tipo de esporte desde os tempos antigos.
            </S.Text>
            <S.Text>
              O futebol tornou-se tão popular graças a seu jeito simples de
              jogar. Basta uma bola, equipes de jogadores e as traves, para que,
              em qualquer espaço, crianças e adultos possam se divertir com o
              futebol. Na rua, na escola, no clube, no campinho do bairro ou até
              mesmo no quintal de casa, desde cedo jovens de vários cantos do
              mundo começam a praticar o futebol.
            </S.Text>

            <S.Subtitle>Quer Marcar um Jogo ?</S.Subtitle>
            <S.Text>
              Você faz parte de um grupo de amigos que se reúne toda semana para
              bater aquela pelada?... Se a resposta for sim, a solução está
              aqui, no Clube dos Funcionários da CSN. Entre em contato conosco e
              marque um jogo com a nossa equipe de veteranos.
            </S.Text>
            <S.Subtitle>Confira as datas agendadas:</S.Subtitle>
            <S.Text>
              Maiores de 40 anos - Sábados - 15:00 horas.
              <br />
              Misto - Quartas-Feiras - 19:00 horas .
            </S.Text>
            <S.ContentBlock>
              <S.Subtitle>A importância dos atestados médicos</S.Subtitle>
              <S.Text>
                É obrigatório entregar os atestados médicos para a prática das
                atividades esportivas no clube, de acordo com a LEI 2.014/92 de
                15/07/1992.
              </S.Text>
              <S.Text>
                O Atestado médico deve ser específico a cada uma das
                modalidades, evitando quaisquer problemas de saúde aos
                associados.
              </S.Text>
              <S.Text>
                Para verificar suas condições de saúde e a aptidão para a
                prática da atividade física pretendida, o Departamento de
                Esportes recomenda que o associado procure o médico de sua
                confiança para fazer todos os exames necessários
              </S.Text>

              <S.RedSubtitle>
                • As atividades esportivas para não sócios são permitidas apenas
                para menores de 18 anos.
              </S.RedSubtitle>
            </S.ContentBlock>
          </S.ContentBlock>

          <S.ContentBlock>
            <S.Subtitle>Escolinha de futebol PSG</S.Subtitle>
            <S.Text>
              <strong>Contato (24) 9.9905-2254</strong>
              <br />
              {/* ----------------------------------- */}
              <S.GroupTime>
                <S.ItemGroup>
                  <p className="title">Categoria</p>
                  <p>
                    Sub 05
                    <br /> Nascidos em 2018/2019
                  </p>
                </S.ItemGroup>
                <S.ItemGroup>
                  <p className="title">Dias</p>
                  <p>3ª e 5ª</p>
                  <p>3ª e 5ª</p>
                </S.ItemGroup>
                <S.ItemGroup>
                  <p className="title">Horário</p>
                  <p>8h30 às 9h30</p>
                  <p>18h às 19h</p>
                </S.ItemGroup>
              </S.GroupTime>
              {/* ----------------------------------- */}
              <S.GroupTime>
                <S.ItemGroup>
                  <p>
                    Sub 07
                    <br /> Nascidos em 2016/2017
                  </p>
                </S.ItemGroup>
                <S.ItemGroup>
                  <p>3ª e 5ª</p>
                  <p>3ª e 5ª</p>
                </S.ItemGroup>
                <S.ItemGroup>
                  <p>8h30 às 10h00</p>
                  <p>18h00 às 19h30</p>
                </S.ItemGroup>
              </S.GroupTime>
              {/* ----------------------------------- */}
              <S.GroupTime>
                <S.ItemGroup>
                  <p>
                    Sub 09 <br /> Nascidos em 2014/2015
                  </p>
                </S.ItemGroup>
                <S.ItemGroup>
                  <p>3ª e 5ª</p>
                  <p>3ª e 5ª</p>
                </S.ItemGroup>
                <S.ItemGroup>
                  <p>8h30 às 10h00</p>
                  <p>18h às 19h30 (Lotado)</p>
                </S.ItemGroup>
              </S.GroupTime>
              {/* ----------------------------------- */}
              <S.GroupTime>
                <S.ItemGroup>
                  <p>
                    Sub 11 <br /> Nascidos em 2012/2013
                  </p>
                </S.ItemGroup>
                <S.ItemGroup>
                  <p>3ª e 5ª</p>
                  <p>2ª e 6ª</p>
                  <p>3ª e 5ª</p>
                </S.ItemGroup>
                <S.ItemGroup>
                  <p>8h30 às 10h00</p>
                  <p>18h às 19h30 (Lotada)</p>
                  <p>16h30 às 18h00</p>
                </S.ItemGroup>
              </S.GroupTime>
              {/* ----------------------------------- */}
              <S.GroupTime>
                <S.ItemGroup>
                  <p>
                    Sub 13 <br /> Nascidos em 2010/2011
                  </p>
                </S.ItemGroup>
                <S.ItemGroup>
                  <p>2ª e 6ª</p>
                  <p>3ª e 5ª</p>
                </S.ItemGroup>
                <S.ItemGroup>
                  <p>18h00 às 19h30 (Lotada)</p>
                  <p>16h30 às 18h00</p>
                </S.ItemGroup>
              </S.GroupTime>
              {/* ----------------------------------- */}
              <S.GroupTime>
                <S.ItemGroup>
                  <p>
                    Sub 15 <br /> Nascidos em 2008/2009
                  </p>
                </S.ItemGroup>
                <S.ItemGroup>
                  <p>2ª e 6ª</p>
                  <p>3ª e 5ª</p>
                </S.ItemGroup>
                <S.ItemGroup>
                  <p>19h30 às 21h00</p>
                  <p>16h30 às 18h00</p>
                </S.ItemGroup>
              </S.GroupTime>
              {/* ----------------------------------- */}
              <S.GroupTime>
                <S.ItemGroup>
                  <p>Sub 17 - Nascidos em 2006/2007</p>
                </S.ItemGroup>
                <S.ItemGroup>
                  <p>2ª e 6ª</p>
                </S.ItemGroup>
                <S.ItemGroup>
                  <p>19h30 às 21h:00</p>
                </S.ItemGroup>
              </S.GroupTime>

              {/* ----------------------------------- */}
              {/* <S.GroupTime>
                <S.ItemGroup>
                  <p>
                    Turma Feminina <br /> Nascidas entre 2003/2011
                  </p>
                </S.ItemGroup>
                <S.ItemGroup>
                  <p>2ª e 4ª</p>
                </S.ItemGroup>
                <S.ItemGroup>
                  <p>16h30 às 18h</p>
                </S.ItemGroup>
              </S.GroupTime> */}
              {/* ----------------------------------- */}
            </S.Text>
          </S.ContentBlock>

          <S.ContentBlock>
            <S.Subtitle>Mensalidades:</S.Subtitle>
            <S.Text>
              <span>Valores para Sócios</span>
              <br />
              Matrícula: R$ 70,00
              <br />
              Mensalidade: R$ 162,00
              <br />
              <br />
              <span>Valores para Não Sócios</span>
              <br />
              Matrícula: R$ 100,00
              <br />
              Mensalidade Não Sócio: R$ 216,00
            </S.Text>
          </S.ContentBlock>
        </S.MainContainer>
      </S.Restrainer>
    </S.Main>
  );
};
