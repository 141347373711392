import React from "react";
import * as S from "./styles";

export const Main: React.FC = () => {
  return (
    <S.Main>
      <S.Restrainer>
        <S.MainContainer>
          <S.Title>Sinuca</S.Title>
          <S.Text>
            Sinuca é um jogo de mesa, taco e bolas praticado no Brasil, e
            constitui uma variante do snooker, um jogo de mesa inventado em 1875
            na Grã-Bretanha. Neste jogo dois adversários tentam colocar num dos
            seis buracos da mesa as bolas coloridas (não brancas) na seqüência
            definida pelas regras. Pode ser jogado individualmente ou em dupla.
            Numa mesa de 2,84 m X 1,42 m (medida brasileira), são colocadas oito
            bolas, com pontuação de 1 (vermelha) a 7 (preta) mais a bola branca.
          </S.Text>
          <S.Subtitle>
            As regras em vigor no Brasil são ligeiramente diferentes das do
            snooker praticado no resto do mundo:
          </S.Subtitle>
          <S.Text>
            A bola branca é utilizada para impulsionar as outras. Denomina-se
            bola da vez a bola colorida de menor pontuação presente na mesa. Ela
            é livre, isto é, o jogador não perde pontos caso erre quando tenta
            encaçapar (matar) essa bola. Quando encaçapada, ela não retorna à
            mesa e dá direito ao jogador de jogar livremente qualquer outra
            bola. Esta, se encaçapada, retorna à mesa e o jogador deve a seguir
            jogar a nova bola da vez. Com exceção da tacada inicial (saída), é
            permitido jogar uma outra bola no lugar da bola da vez, porém com
            castigo, isto é, com perda de 7 pontos em caso de erro. A mesa
            original, inglesa, possui mais 50 cm, tanto na largura, quanto no
            comprimento, com 15 bolas vermelhas (bola 1) ao invés de apenas uma,
            como no Brasil, e regras diferentes da nossa adaptação. Hoje, há uma
            tendência, principalmente em campeonatos oficiais, de se jogar na
            "regra inglesa" (ver descrição da mesma em snooker), visando
            internacionalizar os atletas brasileiros.
          </S.Text>

          <S.RedSubtitle>
            • As atividades esportivas para não sócios são permitidas apenas
            para menores de 18 anos.
          </S.RedSubtitle>

          <S.ContentBlock>
            <S.Subtitle>A importância dos atestados médicos</S.Subtitle>
            <S.Text>
              É obrigatório entregar os atestados médicos para a prática das
              atividades esportivas no clube, de acordo com a LEI 2.014/92 de
              15/07/1992.
            </S.Text>
            <S.Text>
              O Atestado médico deve ser específico a cada uma das modalidades,
              evitando quaisquer problemas de saúde aos associados.
            </S.Text>
            <S.Text>
              Para verificar suas condições de saúde e a aptidão para a prática
              da atividade física pretendida, o Departamento de Esportes
              recomenda que o associado procure o médico de sua confiança para
              fazer todos os exames necessários
            </S.Text>
          </S.ContentBlock>
        </S.MainContainer>
      </S.Restrainer>
    </S.Main>
  );
};
