import styled from "styled-components";
import { Whatsapp } from "@styled-icons/boxicons-logos/Whatsapp";
import { Colors, Fonts, ScreenSize } from "styles/contracts";
export { Restrainer } from "styles/components";

export const Zap = styled(Whatsapp)`
  color: ${Colors.Light1};
`;

export const InfoBar = styled.div`
  background: ${Colors.Dark3};
  color: ${Colors.Light1};
`;

export const GroupContact = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  align-items: flex-start;
  justify-content: center;
  column-gap: 10px;
  margin-bottom: 10px;
`;

export const GroupWhats = styled.a`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
  color: ${Colors.Light1};
`;

export const InfoGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 42px;
  padding: 60px 0 46px 0;
  @media ${ScreenSize.SmallTabletAndDown} {
    grid-template-columns: 1fr;
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h3`
  color: currentColor;
  margin-bottom: 12px;
  font-family: ${Fonts.NunitoBold};
  font-size: 20px;
`;

export const Text = styled.p`
  line-height: 1.8rem;
  font-size: 15px;
  &:not(:last-child) {
    margin-bottom: 12px;
  }
`;

export const Separator = styled.hr`
  height: 1px;
  border: none;
  background: ${Colors.Light6}66;
`;
