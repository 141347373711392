import { Link } from "react-router-dom";
import styled from "styled-components";

import { Colors } from "styles/contracts";

export { Restrainer } from "styles/components";

export const DesktopNavigation = styled.header`
  background: ${Colors.Light1};
`;

export const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 52px 0 36px 0;
`;
export const GroupInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 35px;
`;

export const BottonContainer = styled.div`
  height: 35px;
`;

// Homepage link
export const HomepageLink = styled(Link)``;
export const LogoImage = styled.img``;

// Main header area

export const MainArea = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0 40px;
`;
