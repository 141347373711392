import styled from "styled-components";
import { motion } from "framer-motion";
import { ReactComponent as CaretLeft } from "assets/icons/caret-left.svg";
import { ReactComponent as CaretRight } from "assets/icons/caret-right.svg";
import { Colors } from "styles/contracts";

export const BannersCarousel = styled.div`
  max-width: 1550px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  @media screen and (max-width: 1024px) {
    margin: 75px auto 0 auto;
  }
`;

export const BannersOverlay = styled.div`
  position: absolute;
  inset: 0 0 0 0;
  z-index: 1;
  pointer-events: none;
`;

export const CarouselControls = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 1270px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  @media screen and (max-width: 1270px) {
    padding: 0 16px;
  }
`;

export const BannerImage = styled.img`
  max-width: 100%;
  height: auto;
`;

export const Button = styled(motion.button)`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${Colors.Light1};
  width: 44px;
  height: 44px;
  border-radius: 22px;
  pointer-events: all;
`;

export const PrevIcon = styled(CaretLeft)``;
export const NextIcon = styled(CaretRight)``;

export const BannerLink = styled.a`
  width: 100%;
  height: auto;
  cursor: pointer;
`;
