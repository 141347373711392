import styled from "styled-components";

import { ReactComponent as Download } from "assets/icons/download.svg";
import { BaseExternalLinkButton } from "styles/components";
import { Colors, ScreenSize } from "styles/contracts";

export { Restrainer, Button, ActivityIndicator } from "styles/components";

export const Main = styled.div`
  background: ${Colors.Dark3};
  color: ${Colors.Light1};
`;

export const Header = styled.header`
  text-align: center;
  padding: 110px 0 72px 0;
`;

export const Title = styled.h1`
  font-size: 48px;
  margin-bottom: 32px;
  color: currentColor;
`;

export const ImagesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 42px;
  padding-bottom: 72px;

  @media ${ScreenSize.TabletAndDown} {
    grid-template-columns: 1fr 1fr;
  }

  @media ${ScreenSize.Mobile} {
    grid-template-columns: 1fr;
  }
`;

// modal

export const ModalContent = styled.div`
  position: relative;
  max-width: 1400px;
  width: fit-content;
  height: auto;
  padding: 24px;
  border-radius: 8px;
  background: ${Colors.Dark3};
  animation: ModalPopIn 300ms ease-in-out;
  box-shadow: 0px 32px 32px -16px #000000aa;
`;

export const CloseButton = styled.button`
  background: ${Colors.Red3};
  color: ${Colors.Light1};
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  font-size: 16px;
  top: -12px;
  right: -12px;
  :hover {
    animation: ZoomIn 200ms linear;
    animation-fill-mode: forwards;
  }
`;

export const ImageContainer = styled.figure`
  position: relative;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
`;
export const NavLinks = styled.a`
  display: flex;
  position: absolute;
  width: 40px;
  height: 40px;
  font-size: xx-large;
  align-items: center;
  justify-content: center;
  background: red;
  color: #fff;
  border-radius: 50%;
  cursor: pointer;
  &.next {
    right: -20px;
  }
  &.prev {
    left: -20px;
  }
  @media ${ScreenSize.Mobile} {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    &.next {
      right: -30px;
    }
    &.prev {
      left: -30px;
    }
  }
`;

export const AreaLoad = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  color: #000;
  img {
    width: 70%;
  }
`;

export const Image = styled.img`
  max-width: 100%;
  max-height: 72vh;
  height: auto;
`;

export const DownloadButton = styled(BaseExternalLinkButton)`
  display: inline-flex;
  flex-direction: row;
  gap: 12px;
  color: ${Colors.Light1};
  background: ${Colors.Red3};
  svg {
    path {
      fill: currentColor;
    }
  }
`;

export const DownloadIcon = styled(Download)``;
