import React from "react";
import Scaffold from "layouts/Scaffold";
import { PageBanner } from "components/Shared";
import * as C from "components/Pages/Club/SocialOffice";

export const SocialOfficePage: React.FC = () => {
  return (
    <Scaffold>
      <PageBanner
        image={require("assets/images/club-social-office-banner.jpg")}
      />
      <C.Main />
    </Scaffold>
  );
};
