import styled from "styled-components";
import { Colors, ScreenSize } from "styles/contracts";
export { Restrainer } from "styles/components";

export const Main = styled.div`
  background: ${Colors.Light3};
`;

export const MainContainer = styled.div`
  margin-top: -100px;
  background: ${Colors.Light1};
  border-radius: 8px;
  padding: 34px 60px 60px 60px;
  @media screen and (max-width: 800px) {
    margin-top: -32px;
  }
`;

export const ColumnsView = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 42px;
  @media ${ScreenSize.TabletAndDown} {
    grid-template-columns: 1fr;
    gap: 0px;
  }
`;

export const ContentBlock = styled.div``;

export const Title = styled.h4`
  font-size: 26px;
  margin: 26px 0;
`;

export const Subtitle = styled.h5`
  font-size: 18px;
  margin: 24px 0;
`;

export const RedSubtitle = styled.h5`
  color: ${Colors.Red3};
  font-size: 16px;
  margin: 24px 0;
`;

export const Text = styled.p`
  font-size: 16px;
  span {
    font-weight: bold;
  }
  &:not(:last-child) {
    margin-bottom: 24px;
  }
`;
