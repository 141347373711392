import React from "react";

import * as C from "components/Pages/Administration/Minutes";
import Scaffold from "layouts/Scaffold";

export const MinutePage: React.FC = () => {
  return (
    <Scaffold>
      <C.Main />
    </Scaffold>
  );
};
