import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { Colors, Fonts, ButtonVariant } from "styles/contracts";

export const BaseButtonStyles = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: 300ms ease;
  padding: 0 1.6rem;
  height: 48px;
  border-radius: 4px;
  font-family: ${Fonts.NunitoExtraBold};
  font-size: 16px;
  :hover {
    animation: ZoomIn 200ms linear;
    animation-fill-mode: forwards;
  }
`;

export const BaseButton = styled.button`
  ${BaseButtonStyles};
`;

export const BaseLinkButton = styled(Link)`
  ${BaseButtonStyles}
`;

export const BaseExternalLinkButton = styled.a`
  ${BaseButtonStyles};
`;

interface IButton {
  variant?: ButtonVariant;
}

const gertButtonVariant = (variant?: ButtonVariant) => {
  switch (variant) {
    case "red":
      return css`
        background: ${Colors.Red3};
        color: ${Colors.Light1};
      `;
    case "light":
      return css`
        background: ${Colors.Light3};
        color: ${Colors.Dark3};
      `;
    case "void":
      return css`
        background: transparent;
        color: currentColor;
      `;
    case "dark":
    default:
      return css`
        background: ${Colors.Dark3};
        color: ${Colors.Light1};
      `;
  }
};

export const Button = styled(BaseButton)<IButton>`
  ${({ variant }) => gertButtonVariant(variant)};
`;
