import axios from "axios";

const fixedIp = "192.168.0.149";

const environment = {
  local: {
    api: "http://localhost:3333",
  },
  fixedIp: {
    api: `http://${fixedIp}:3333`,
  },
  production: {
    api: "https://clube-dos-funcionarios-api.herokuapp.com",
  },
};

export const baseURL =
  process.env.NODE_ENV === "development"
    ? environment.production
    : environment.production;

export const api = axios.create({
  baseURL: baseURL.api,
  headers: {},
});

export const applyQueryString = (
  url: string,
  payload: Record<string, any>
): string => {
  const validObject: Record<string, any> = {};

  for (const [param, value] of Object.entries(payload)) {
    if (value) Object.assign(validObject, { [param]: value });
  }

  if (Object.keys(validObject).length === 0) {
    return url;
  }

  const searchParams = new URLSearchParams(validObject).toString();

  return `${url}?${searchParams}`;
};

export const formDataBuilder = (payload: Record<string, any>): FormData => {
  const formData = new FormData();

  for (const [param, value] of Object.entries(payload)) {
    if (value instanceof File) {
      formData.append(param, value, value.name);
    } else if (value) {
      formData.append(param, value);
    }
  }

  return formData;
};
