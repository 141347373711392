import React from "react";

import * as C from "components/Pages/Sports/FunctionalTraining";
import { PageBanner } from "components/Shared";
import Scaffold from "layouts/Scaffold";

export const FunctionalTrainingPage: React.FC = () => {
  return (
    <Scaffold>
      <PageBanner
        image={require("assets/images/functional-training-banner.jpg")}
        title="Treinamento Funcional"
        subtitle="Confira informações e horários"
      />
      <C.Main />
      <C.RelatedArticles />
    </Scaffold>
  );
};
