import styled from "styled-components";
import { Colors, ColorScheme } from "styles/contracts";
import { FieldContainer } from "styles/components";
export { FieldLabel, FieldError } from "styles/components";

export const Container = styled(FieldContainer)`
  textarea {
    border-radius: 6px;
    background: ${Colors.Light2};
    border: 1px solid ${Colors.Light5};
    color: ${ColorScheme.Text};
    height: 160px;
    padding: 16px;
    font-size: inherit;
    resize: none;
  }
`;
