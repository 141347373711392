import React, { useState } from "react";
import * as Section from "./Sections";
import * as S from "./styles";

export const Main: React.FC = () => {
  const [activeAccordion, setActiveAccordion] = useState<string>(
    "MONTHLY_BALANCES_2024"
  );

  return (
    <S.Main>
      <S.Restrainer>
        <S.MainContainer>
          <S.Title>Balancetes mensais</S.Title>
          <Section.MonthlyBalances
            activeAccordion={activeAccordion}
            onActivate={setActiveAccordion}
          />
          <S.Title>Balanço patrimonial anual</S.Title>
          <Section.AnnualStatements
            activeAccordion={activeAccordion}
            onActivate={setActiveAccordion}
          />
          <S.Title>Parecer da comissão fiscal</S.Title>
          <Section.FiscalCommissions
            activeAccordion={activeAccordion}
            onActivate={setActiveAccordion}
          />
          <S.Title>Aprovação de contas</S.Title>
          <Section.AccountApprovals
            activeAccordion={activeAccordion}
            onActivate={setActiveAccordion}
          />
          <S.Title>Relatório financeiro</S.Title>
          <Section.FinancialReports
            activeAccordion={activeAccordion}
            onActivate={setActiveAccordion}
          />

          <S.Title>Orçamentos</S.Title>
          <Section.BudgetsDocuments
            activeAccordion={activeAccordion}
            onActivate={setActiveAccordion}
          />

          <S.Title>Editais Convênio CBC</S.Title>
          <Section.CovenantDocuments />
        </S.MainContainer>
      </S.Restrainer>
    </S.Main>
  );
};
