import React from "react";

import * as S from "./styles";

export const Main: React.FC = () => {
  return (
    <S.Main>
      <S.Restrainer>
        <S.MainContainer>
          <S.ContentBlock>
            <S.Title>Futsal</S.Title>
            <S.Text>
              A versão mais aceita, para o surgimento do Futebol de Salão no
              Brasil, é a de que ele começou a ser praticado nos idos de 1940
              por jovens freqüentadores da Associação Cristã de Moços, em São
              Paulo. Enfrentando dificuldades para encontrar campos de Futebol
              para divertimento nas suas horas de lazer, improvisaram "peladas"
              nas quadras de basquete e hóquei, aproveitando as traves usadas na
              prática desse último esporte. De início as "equipes" variavam de
              número, tendo cinco, seis e até sete jogadores, sendo pouco a
              pouco fixado o limite de cinco. As bolas eram de crina vegetal ou
              serragem, sofrendo sucessivas modificações, inclusive com uso de
              cortiça granulada. Como as bolas de ar utilizadas depois saltavam
              muito e saíam freqüentemente das quadras, posteriormente tiveram
              seu tamanho diminuído e o peso aumentado. Daí o "Futebol de Salão"
              ser chamado também de "esporte de bola pesada".
            </S.Text>
            <S.ContentBlock>
              <S.Subtitle>A importância dos atestados médicos</S.Subtitle>
              <S.Text>
                É obrigatório entregar os atestados médicos para a prática das
                atividades esportivas no clube, de acordo com a LEI 2.014/92 de
                15/07/1992.
              </S.Text>
              <S.Text>
                O Atestado médico deve ser específico a cada uma das
                modalidades, evitando quaisquer problemas de saúde aos
                associados.
              </S.Text>
              <S.Text>
                Para verificar suas condições de saúde e a aptidão para a
                prática da atividade física pretendida, o Departamento de
                Esportes recomenda que o associado procure o médico de sua
                confiança para fazer todos os exames necessários
              </S.Text>

              <S.RedSubtitle>
                • As atividades esportivas para não sócios são permitidas apenas
                para menores de 18 anos.
              </S.RedSubtitle>
            </S.ContentBlock>
          </S.ContentBlock>

          <S.ContentBlock>
            <S.Subtitle>
              Escolinha de futsal PSG Oficial - (24) 9 9905-2254
            </S.Subtitle>

            <S.Text>
              {/* ----------------------------------- */}
              <S.GroupTime>
                <S.ItemGroup>
                  <p className="title">Categoria</p>
                  <p>
                    Sub 05 <br /> Nascidos em 2018/2019
                  </p>
                </S.ItemGroup>
                <S.ItemGroup>
                  <p className="title">Dias</p>
                  <p>2ª e 4ª</p>
                  <p>3ª e 5ª</p>
                </S.ItemGroup>
                <S.ItemGroup>
                  <p className="title">Horário</p>
                  <p>09h30 às 10h30</p>
                  <p>18h00 às 19h00</p>
                </S.ItemGroup>
              </S.GroupTime>
              {/* ----------------------------------- */}
              <S.GroupTime>
                <S.ItemGroup>
                  <p>
                    Sub 07 <br /> Nascidos em 2016/2017
                  </p>
                </S.ItemGroup>
                <S.ItemGroup>
                  <p>2ª e 4ª</p>
                  <p>2ª e 4ª</p>
                  <p>Sábados</p>
                </S.ItemGroup>
                <S.ItemGroup>
                  <p>09h30 às 10h30</p>
                  <p>18h00 às 19h00</p>
                  <p>08h30 às 10h30</p>
                </S.ItemGroup>
              </S.GroupTime>
              {/* ----------------------------------- */}
              {/* <S.GroupTime>
                <S.ItemGroup>
                  <p>
                    Sub 09 <br /> Nascidos em 2013/2014
                  </p>
                </S.ItemGroup>
                <S.ItemGroup>
                  <p>2ª e 4ª</p>
                  <p>2ª e 4ª</p>
                  <p>Sábados</p>
                </S.ItemGroup>
                <S.ItemGroup>
                  <p>08h30 às 09h30 (Formando Turma)</p>
                  <p>19h às 20h</p>
                  <p>8h às 12h</p>
                </S.ItemGroup>
              </S.GroupTime> */}
              {/* ----------------------------------- */}
              <S.GroupTime>
                <S.ItemGroup>
                  <p>
                    Sub 11 <br /> Nascidos em 2012/2013
                  </p>
                </S.ItemGroup>
                <S.ItemGroup>
                  <p>2ª e 4ª</p>
                  <p>3ª e 5ª</p>
                  <p>Sábado</p>
                </S.ItemGroup>
                <S.ItemGroup>
                  <p>08h30 às 10h30</p>
                  <p>19h00 às 20h00</p>
                  <p>8h30 às 10h30</p>
                </S.ItemGroup>
              </S.GroupTime>
              {/* ----------------------------------- */}
              <S.GroupTime>
                <S.ItemGroup>
                  <p>
                    Sub 13 <br /> Nascidos em 2010/2011
                  </p>
                </S.ItemGroup>
                <S.ItemGroup>
                  <p>2ª e 4ª</p>
                </S.ItemGroup>
                <S.ItemGroup>
                  <p>08h30 às 09h30</p>
                </S.ItemGroup>
              </S.GroupTime>
              {/* ----------------------------------- */}
              <S.GroupTime>
                <S.ItemGroup>
                  <p>
                    Sub 15 <br /> Nascidos em 2008/2009
                  </p>
                </S.ItemGroup>
                <S.ItemGroup>
                  <p>3ª e 5ª</p>
                </S.ItemGroup>
                <S.ItemGroup>
                  <p>17h00 às 18h00</p>
                </S.ItemGroup>
              </S.GroupTime>
              {/* ----------------------------------- */}
              <S.GroupTime>
                <S.ItemGroup>
                  <p>
                    Turma Feminina <br /> Nascidas entre 2010/2015
                  </p>
                </S.ItemGroup>
                <S.ItemGroup>
                  <p>3ª e 5ª</p>
                </S.ItemGroup>
                <S.ItemGroup>
                  <p>20h00 às 21h00</p>
                </S.ItemGroup>
              </S.GroupTime>
              {/* ----------------------------------- */}
            </S.Text>
          </S.ContentBlock>

          <S.ContentBlock>
            <S.Subtitle>Mensalidades</S.Subtitle>

            <S.Text>
              <span>Valores para Sócios</span>
              <br />
              <span>Matrícula:</span> R$ 70,00
              <br />
              <span>Mensalidade:</span> R$ 126,00
            </S.Text>

            <S.Text>
              <span>Valores para não Sócios</span>
              <br />
              <span>Matrícula:</span> R$ 100,00
              <br />
              <span>Mensalidade:</span> R$ 173,00
            </S.Text>

            <S.RedSubtitle>
              OBS. Taxa de matrícula da direito a um chaveiro,mochila,bolsa e
              contrato**
            </S.RedSubtitle>
          </S.ContentBlock>
        </S.MainContainer>
      </S.Restrainer>
    </S.Main>
  );
};
