import React from "react";
import * as S from "./styles";

export const Main: React.FC = () => {
  return (
    <S.Main>
      <S.Restrainer>
        <S.MainContainer>
          <S.ContentBlock>
            <S.Title>Jiu-Jitsu</S.Title>
            <S.Text>
              A opinião geral, aceita pela maioria dos historiadores, é de que
              as técnicas sistematizadas das artes marciais vieram da Índia,
              junto com o budismo (Dharma). A teoria é que o templo de Shaolin
              foi construído no centro da China, precisamente onde o Dharma
              introduziu o budismo. Conta-se que monges budistas do norte da
              Índia muito contribuíram para o desenvolvimento inicial do
              jiu-jítsu. Em suas longas viagens pelo interior da Índia, os
              monges eram constantemente assaltados por bandidos. Os valores
              religiosos e morais budistas não permitiam o uso de armas, por
              isso os monges foram forçados a desenvolver um sistema de
              autodefesa usando as mãos nuas. Uma coisa é certa sobre tais
              histórias: os japoneses foram os responsáveis pelo refinamento de
              uma arte de luta corporal na forma de um sistema de combate corpo
              a corpo muito sofisticado chamado jiu-jítsu, que foi desenvolvido
              no Japão durante o período feudal.
            </S.Text>

            <S.ContentBlock>
              <S.Subtitle>A importância dos atestados médicos</S.Subtitle>
              <S.Text>
                É obrigatório entregar os atestados médicos para a prática das
                atividades esportivas no clube, de acordo com a LEI 2.014/92 de
                15/07/1992.
              </S.Text>
              <S.Text>
                O Atestado médico deve ser específico a cada uma das
                modalidades, evitando quaisquer problemas de saúde aos
                associados.
              </S.Text>
              <S.Text>
                Para verificar suas condições de saúde e a aptidão para a
                prática da atividade física pretendida, o Departamento de
                Esportes recomenda que o associado procure o médico de sua
                confiança para fazer todos os exames necessários
              </S.Text>
            </S.ContentBlock>

            <S.Text>
              <br />
              <strong>Horário das Aulas</strong>
              <br />
              Professor Charles Pedro
              <br />
              (Suspenso temporariamente)
              <br />
              <br />
              19:30 às 21:00 - A partir de 08 anos
            </S.Text>

            <S.Text>
              <strong>Mensalidade:</strong>
              <br />
              Mensalidade Sócio: R$ 65,00 (R$ 30,00 Matrícula)
              <br />
              Mensalidade não Sócio: R$ 85,00 (R$ 50,00 Matrícula)
              <br />
            </S.Text>
            <S.Text>
              • Não sócios apenas menores de 18 anos
              <br />
              <br />
              Local: Palco do Ginásio
            </S.Text>
          </S.ContentBlock>
        </S.MainContainer>
      </S.Restrainer>
    </S.Main>
  );
};
