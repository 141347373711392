interface Member {
  name: string;
  image: string;
  role?: string;
}

export const deliberativeConcil: Member[] = [
  // {
  //   name: "Alyson Santana",
  //   image: "alyson-santana.jpg",
  //   role: "Presidente",
  // },
  {
    name: "Carlos Fernando Dornellas Jr.",
    image: "carlos-fernando-dornellas-jr.jpg",
    role: "Presidente",
  },
  {
    name: "Aires Henrique Cajazeira Vidal",
    image: "aires-henrique-cajazeira-vidal.jpg",
  },
  {
    name: "Alexandre Luiz dos S. Botelho",
    image: "alexandre-luiz-botelho.jpg",
  },
  {
    name: "Ana Letícia de Oliveira Costa",
    image: "ana-leticia-de-oliveira-costa.jpg",
  },
  // {
  //   name: "Benevenuto Silva dos Santos",
  //   image: "benevenuto-silva-santos.jpg",
  // },
  // {
  //   name: "Carlos Henrique Perrut Mello",
  //   image: "carlos-henrique-perrut-mello.jpg",
  // },
  // {
  //   name: "Claudio Fernando de Azevedo",
  //   image: "claudio-fernando-de-azevedo.jpg",
  // },
  // {
  //   name: "Caio Stocco",
  //   image: "caio-stocco.jpg",
  // },
  {
    name: "Camila Ribeiro",
    image: "camila-ribeiro.jpg",
  },
  {
    name: "Carlos Augusto Ferreira",
    image: "carlos-augusto-ferreira.jpg",
  },
  // {
  //   name: "Criseide Domingues",
  //   image: "criseide-domingues.jpg",
  // },
  {
    name: "Cesar de Oliveira Vieira",
    image: "cesar-de-oliveira-vieira.jpg",
  },
  {
    name: "Davi do Amaral Vasconcelos",
    image: "davi-do-amaral-vasconcelos.jpg",
  },
  {
    name: "Edalmo de Mendonça",
    image: "edalmo-de-mendonca.jpg",
  },
  {
    name: "Érico Ramos Pinto",
    image: "erico-ramos.jpg",
  },
  {
    name: "Expedito Gonçalves Aquino",
    image: "expedito-goncalves-de-aquino.jpg",
    role: "licenciado",
  },
  {
    name: "Fabrício dos Reis de Almeida",
    image: "fabricio-reis-de-almeida.jpg",
  },
  {
    name: "Felipe Mendonça de Oliveira",
    image: "felipe-mendonca.jpg",
  },
  // {
  //   name: "Fernando Cajazeira de Rezende",
  //   image: "fernando-cajazeira-de-rezende.jpg",
  // },
  {
    name: "Geraldo Margela Bastos Brandão",
    image: "geraldo-margela-bastos-brandao.jpg",
  },
  // {
  //   name: "Geraldo Pires Domingues",
  //   image: "geraldo-pires-domingues.jpg",
  // },
  {
    name: "Jorge Alves Otaviano",
    image: "jorge-alves-otaviano.jpg",
  },
  {
    name: "José Alexandre de Carvalho Tommaso",
    image: "jose-alexandre.jpg",
  },
  // {
  //   name: "José Ivan do Amaral",
  //   image: "jose-ivan-do-amaral.jpg",
  // },
  // {
  //   name: "Leandro Ferreira Galito",
  //   image: "leandro-ferreira-galito.jpg",
  // },
  {
    name: "Luiz Marcelo de Oliveira",
    image: "luiz-marcelo-de-oliveira.jpg",
  },
  // {
  //   name: "Marcelo de Almeida Tartari",
  //   image: "marcelo-de-almeida-tartari.jpg",
  // },
  // {
  //   name: "Marcio Tolomelli Canedo",
  //   image: "marcio-tolomelli-canedo.jpg",
  // },
  // {
  //   name: "Marcus Vinicius C. de Oliveira",
  //   image: "marcus-vinicius-c-de-oliveira.jpg",
  // },
  // {
  //   name: "Marlos Salgado Pereira",
  //   image: "marlos-salgado-pereira.jpg",
  // },
  {
    name: "Maurício Domingues da Silva",
    image: "mauricio-domingues-da-silva.jpg",
  },
  {
    name: "Max Lima de Aquino",
    image: "max-aquino.jpg",
  },
  {
    name: "Natália Oliveira Cadinelli de Farias",
    image: "natalia-cadinelli.jpg",
  },
  {
    name: "Nilton José Bueno",
    image: "nilton-jose-bueno.jpg",
  },
  // {
  //   name: "Ricardo Feliciano Romeiro",
  //   image: "ricardo-feliciano-romeiro.jpg",
  // },
  {
    name: "Orlando de Oliveira",
    image: "orlando-de-oliveira.jpg",
  },
  {
    name: "Roberto Barros do Carmo",
    image: "roberto-barros-do-carmo.jpg",
  },
  {
    name: "Roberto Lourenço José",
    image: "roberto-lourenco.jpg",
  },
  {
    name: "Romulo Rezende Bruno de Oliveira",
    image: "romulo-rezende-bruno-de-oliveira.jpg",
  },
  {
    name: "Sarah Gorito Rezende Santos",
    image: "sarah-resende.jpg",
  },
  {
    name: "Sandro Rogerio Viana",
    image: "sandro-rogerio-viana.jpg",
  },
  {
    name: "Sergio Ramos Haubrick",
    image: "sergio-ramos-haubrick.jpg",
  },
  {
    name: "Sergio Luiz Penna Salgueiro",
    image: "sergio-luiz-penna-salgueiro.jpg",
  },
  // {
  //   name: "Silvio Eduardo de Melo e Cruz",
  //   image: "silvio-eduardo-de-melo-e-cruz.jpg",
  // },
  {
    name: "Valmir Martins da Silva",
    image: "valmir-martins-da-silva.jpg",
  },
  {
    name: "Vinicius Alves Couzi",
    image: "vinicius-alves-couzi.jpg",
  },
  {
    name: "Wilson José Gonçalves Ribeiro",
    image: "wilson-jose-goncalves-ribeiro.jpg",
  },

  // {
  //   name: "Criseide de Oliveira Domingues",
  //   image: "criseide-de-oliveira-domingues.jpg",
  // },
];
