import React from "react";

import { covenantDocuments } from "data/transparency";

import * as S from "./styles";

const getDownloadUrl = (filename: string): string => {
  const baseUrl = "/site/cbc-documentos";
  return `${baseUrl}/${filename}`;
};

export const CovenantDocuments: React.FC = () => {
  return (
    <S.Container>
      <S.Documents>
        {covenantDocuments.map((document, index) => (
          <S.Document
            key={index}
            target="_blank"
            href={getDownloadUrl(document.filename)}
          >
            {document.name}
          </S.Document>
        ))}
      </S.Documents>
    </S.Container>
  );
};
