import styled from "styled-components";
import { Fonts, ICard } from "styles/contracts";
import { getCardZoomOnHoverStyle } from "styles/functions";

export const VideoCard = styled.button<ICard>`
  display: flex;
  flex-direction: column;
  height: 100%;
  color: currentColor;
  text-align: left;
  ${(props) => getCardZoomOnHoverStyle(props)};
`;

export const ImageContainer = styled.figure`
  display: flex;
  overflow: hidden;
  border-radius: 4px;
  margin-bottom: 24px;
`;

export const CardImage = styled.img`
  max-width: 100%;
  height: auto;
`;

export const CardTitle = styled.h4`
  font-family: ${Fonts.NunitoBold};
  font-size: 18px;
  margin-bottom: 24px;
  color: currentColor;
`;
