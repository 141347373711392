import React from "react";

import * as S from "./styles";

export const Main: React.FC = () => {
  return (
    <S.Main>
      <S.Restrainer>
        <S.MainContainer>
          <S.ContentBlock>
            <S.Title>Natação</S.Title>
            <S.Text>
              Considerado um esporte completo, a natação desenvolve a
              coordenação motora, a força, a resistência, a velocidade e o
              condicionamento físico geral. Dividida em níveis de aprendizagem
              I, II, III, Pré-competitivo e Competitivo, trabalha os inúmeros
              fundamentos da modalidade como adaptação, respiração, flutuação,
              etc., assim como os diversos estilos como nado crawl, costas,
              peito, borboleta e adaptados.
            </S.Text>
            <S.Subtitle>Evolução dos nados</S.Subtitle>
            <S.Text>
              O nado de peito, a borboleta e o golfinho têm um vínculo histórico
              comum. O nado de peito somente foi regulamentado como tal após o
              estilo crawl tê-lo substituído nas provas de nado livre.
              Posteriormente foi introduzido o estilo borboleta que, finalmente,
              evoluiu para o golfinho. Com o surgimento do crawl, o antigo
              estilo (peito) perdeu sua posição, por ser mais lento. Entretanto,
              havia interesse em manter o estilo clássico e por isso, foram
              regulamentadas as provas exclusivas para aquele estilo.
            </S.Text>
            <S.Text>
              Quando surgiu a braçada da borboleta, novamente decaiu o uso do
              estilo clássico e, isto, da mesma forma que ocorrera
              anteriormente, fez com que a FINA, por meio de regulamentação
              específica, separasse os dois estilos. Aperfeiçoou-se o estilo de
              batidas de pernas e ao invés de tesoura surgiu o movimento
              ondulante do golfinho, razão da denominação do novo estilo. O nado
              de costas, inicialmente, tinha por finalidade proporcionar meios
              de fácil flutuação para descansar o nadador. Somente nos jogos
              olímpicos de Paris, em 1900, é que surgiu este estilo como forma
              de competição. Inicialmente os braços eram levados simultaneamente
              para dentro da água e as pernas movimentam-se de forma semelhante
              à tesoura a frente. Daí evoluiu para uma borboleta invertida e,
              com o surgimento do estilo novo de frente, seus empréstimos
              técnicos chegaram ao nado de costas, que passou a ser usar os
              mesmos movimentos de pernas, alternados para baixo e para cima,
              com os braços também alternados, de trás para frente, em tração de
              dentro da água e em recuperação fora dela.
            </S.Text>
          </S.ContentBlock>

          <S.ContentBlock>
            <S.RedSubtitle>
              • As atividades esportivas para não sócios são permitidas apenas
              para menores de 18 anos.
            </S.RedSubtitle>
            <S.Subtitle>Horário das Aulas</S.Subtitle>

            <S.Text>
              <span>Professor Rodolfo (24) 98155-8866 - Equipe Júnior</span>
              <br />
              Segunda à sexta-feira de 14h00 às 17h00
              <br />
              Sábado de 07h00 às 09h30
              <br />
              <br />
              (Segunda, quarta e Sexta-feira)
              <br />
              07h00 às 08:00 - Adulto- Nível I (Limite 15 Alunos) Turma Lotada
              <br />
              08h00 às 08:50 - 9 a 12 anos - Nível II (Limite 15 Alunos) Somente
              com Autorização do Professor
              <br />
              09h00 às 09:50 - 6 a 8 anos - Nível I (Limite 15 Alunos) Turma
              Lotada
              <br />
              <br />
              <span>Mensalidades</span>
              <br />
              Matrícula não sócio: R$ 50,00 <br />
              Sócio: R$ 98,00 (3x por semana) <br />
              <br />
              Exame dermatológico: R$ 10,00 <br />
            </S.Text>

            <S.Text>
              <span>Professor Rômulo - Equipe Infantil</span>
              <br />
              Segunda à sexta-feira de 14h00 às 16h00
              <br />
              Sábado de 08h00 às 10h30
            </S.Text>

            <S.Text>
              <span>
                Professores Dandara, Denise e Rômulo - Equipe Petiz (11 à 12
                anos)
              </span>
              <br />
              Segunda à sexta-feira de 16h00 às 18h00
            </S.Text>

            <S.Text>
              <span>
                Professora Dandara - Infantil e Sênior - Assistente Técnica na
                Equipe do Rodolfo e Rômulo
              </span>
              <br />
              Segunda, quarta e sexta-feira de 14h00 às 16h00
            </S.Text>

            <S.Text>
              <span>EQUIPE FLEX:</span>
              <br />
              Valores para treinar 5x na semana: <br />
              R$ 107,00 (Sócio) <br />
              R$ 183,00 (Não Sócio) <br />
            </S.Text>

            <S.Text>
              <span>Rômulo - 11 à 13 anos - Equipe Flex</span>
              <br />
              Segunda e Quarta-feira - 18h00 às 19h00
              <br />
            </S.Text>

            <S.Text>
              <span>Rômulo - 11 à 13 anos - Equipe Flex</span>
              <br />
              Terça e Quinta-feira - 18h00 às 19h00
              <br />
            </S.Text>

            <S.Text>
              <span>Valores Sócio</span>
              <br />
              Mensalidade: R$ 97,00
              <br />
              2x na Semana: R$ 77,00
              <br />
            </S.Text>
            <S.Text>
              <span>Valores Não Sócio</span>
              <br />
              Matrícula: R$ 50,00
              <br />
              Mensalidade: R$ 116,00
              <br />
              2x na Semana: R$ 133,00
              <br />
              <br />
              <S.RedSubtitle>Exame dermatológico: R$ 10,00 </S.RedSubtitle>
            </S.Text>
            <S.Text>
              <span>Escolinha de Natação</span>
              <br />
              Professora: Denise Dornelas
              <br />
              <br />
              (Segunda e Quarta-feira)
              <br />
              18h00 às 19h00 - 06 a 08 anos (Limite 12 alunos) - Turma Lotada
              <br />
              <br />
              (Segunda e Sexta-feira)
              <br />
              09h00 às 09h40 - 3 e 4 anos - (Limite 12 alunos) - Turma Lotada
              <br />
              10h00 às 10h40 - 3 e 4 anos Iniciante - (Limite 12 alunos) - Turma
              Lotada
              <br />
              <br />
              (Terça e Quinta-feira)
              <br />
              08h00 às 08h50 - 6 a 11 anos - (Limite 12 alunos) - Turma Lotada
              <br />
              09h00 às 09h50 - 5 a 7 anos - (Limite 12 alunos) - Turma Lotada
              <br />
              10h00 às 10h40 - 3 a 4 anos - (Limite 12 alunos) - Turma Lotada
              <br />
            </S.Text>
            <S.Text>
              <span>03 a 04 anos</span>(40 minutos)
              <br />
              <span>Mensalidades Sócios</span>
              <br />
              Sócio R$ 96,00 (2x na semana)
              <br />
              Não Sócio
              <br />
              R$ 50,00 Taxa de Matrícula
              <br />
              Mensalidade para não sócio R$ 159,00 (2x na semana)
            </S.Text>

            <S.Text>
              <span>05 a 07 anos</span>(45 minutos)
              <br />
              <span>Mensalidades Sócios</span>
              <br />
              Sócio R$ 85,00 (2x na semana) R$ 98,00 (3x na semana)
              <br />
              Não Sócio
              <br />
              R$ 50,00 Taxa de Matrícula
              <br />
              Mensalidade para não sócio R$ 146,00 (2x na semana) R$ 183,00 (3x
              na semana)
            </S.Text>

            <S.RedSubtitle>
              Aula experimental de natação é de somente 15 minutos
            </S.RedSubtitle>
            <S.RedSubtitle>
              para melhor desenvolvimnto da turma; favor obedecer a faixa etária
              definida
            </S.RedSubtitle>
            <br />
            <S.Title>Professora Fernanda</S.Title>
            <S.Text>
              (Terça e Quinta-feira)
              <br />
              07h00 às 07h50 - 13 anos em diante - Nível I (Limite 10 Alunos)
              <br />
              08h00 às 08h50 - 10 a 14 anos - Nível II (Limite 20 Alunos) -
              Turma Lotada
              <br />
              <span>** Somente com autorização da Fernanda</span>
              <br />
              <br />
              09h00 às 09h50 - 8 a 12 anos - Nível I (Limite 15 Alunos) - Turma
              Lotada
              <br />
              10h00 às 10h50 - 8 a 10 anos - Nível I (Limite 15 Alunos) - Turma
              Lotada
              <br />
              <br />
              16h00 às 16h50 - 8 a 12 anos - Nível I (Limite 15 Alunos) - Turma
              Lotada
              <br />
              17h00 às 17h50 - 12 a 14 anos - Nível II (Limite 20 Alunos) -
              Turma Lotada
              <br />
              18h00 às 18h50 - 12 a 14 anos - Nível I (Limite 15 Alunos) - Turma
              Lotada
              <br />
            </S.Text>
            <S.Text>
              <span>Matrícula Não Sócio: </span> R$ 50,00
              <br />
              <span>Mensalidades: </span> Sócio: R$ 77,00 / Não Sócio R$ 133,00
              <br />
              <span>Exame dermatológico: </span> R$ 10,00
              <br />
            </S.Text>
          </S.ContentBlock>
          <S.ContentBlock>
            <S.Title>Professora Dandara - Escolinha</S.Title>
            <S.Text>
              (Segunda e Quarta-feira)
              <br />
              18h00 às 19h00 - 6 a 8 anos - (Limite 12 Alunos) - Turma Lotada
              <br />
              <br />
              <span>Mensalidades:</span>
              <br />
              Sócio (2x na semana) - R$ 77,00
              <br />
              Sócio (3x na semana) - R$ 89,00
              <br />
              <br />
              Matrícula Não Sócio: R$ 50,00
              <br />
              Não Sócio (2x na semana) - R$ 133,00
              <br />
              Não Sócio (3x na semana) - R$ 166,00
              <br />
              <br />
              <span>Aula experimental de natação é somente de 15 minutos</span>
              <br />
              Exame Dermatológico: R$ 10,00
              <br />
            </S.Text>
            <S.Subtitle>Professora Cintia</S.Subtitle>
            <S.Text>
              (Segunda e Sexta-feira)
              <br />
              08h00 às 08h50 - 5 e 6 anos (Limite 12 alunos) - Turma Lotada
              <br />
              <br />
              (Terça e Quinta-feira)
              <br />
              08h00 às 08h50 - 6 a 8 anos (Limite 15 alunos) - Turma Lotada
              <br />
              09h00 às 09h45 - 3 a 4 anos (Limite 6 alunos) - Turma Lotada
              <br />
              <br />
              <span>Mensalidades</span>
              <br />
              Sócio :R$ 77,00 / Não Sócio: R$ 133,00 (2x na semana)
              <br />
              Não Sócio: R$ 50,00 (Taxa de Matrícula)
              <br />
              <br />
              <span>*** 3 a 4 anos: R$ 144,00 (2x na semana) </span>
              <br />
              <br />
              <S.RedSubtitle>
                Aula Experimental de natação é somente 15 minutos
              </S.RedSubtitle>
              <br />
              <S.RedSubtitle>
                Para melhor desenvolvimnto da turma; favor obedecer a faixa
                etária
              </S.RedSubtitle>
              <br />
            </S.Text>
          </S.ContentBlock>
        </S.MainContainer>
      </S.Restrainer>
    </S.Main>
  );
};
