import React from "react";

import * as C from "components/Pages/Sports/Taekwondo";
import { PageBanner } from "components/Shared";
import Scaffold from "layouts/Scaffold";

export const TaekwondoPage: React.FC = () => {
  return (
    <Scaffold>
      <PageBanner
        image={require("assets/images/taekwondo-banner.jpg")}
        title="Taekwondo"
        subtitle="Confira informações e horários"
      />
      <C.Main />
    </Scaffold>
  );
};
