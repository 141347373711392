import React from "react";

import { navigationMenuEntries } from "data/navigation";
import * as S from "./styles";

import MenuEntry from "./MenuEntry";

const NavigationMenu: React.FC = () => {
  return (
    <S.NavigationBar>
      <S.Menu>
        {navigationMenuEntries.map((entry, index) => (
          <MenuEntry entry={entry} key={index} />
        ))}
      </S.Menu>
    </S.NavigationBar>
  );
};

export default NavigationMenu;
