import { ReactNode } from "react";
import { Link } from "react-router-dom";

interface FAQ {
  question: string;
  answer: string;
}

export const faq: FAQ[] = [
  // {
  //   question: `O Clube já fez uso de recursos de algum projeto de incentivos fiscais?`,
  //   answer: `Ainda não.`,
  // },
  {
    question: `De onde vem as receitas do Clube?`,
    answer: `As receitas do Clube são oriundas de: mensalidades dos associados, aluguel de patrimônio, eventos, patrocínios de empresas privadas e recursos oriundos de convênio com o CBC.`,
  },
  {
    question: `Qual o horário de funcionamento do clube?`,
    answer: `Segunda a sábado: 6h30 às 23h. Domingos e feriados 6h30 às 21h<br/>Domingos e feriados 6h30 às 21h`,
  },
  {
    question: `Qual o horário de funcionamento do parque aquático?`,
    answer: `Segunda a sábado: 7h às 20h. Domingos e feriados 6h30 às 21h<br/>Domingos e feriados 7h às 19h30`,
  },
  {
    question: `Qual o horário de funcionamento da secretaria?`,
    answer: `De segunda a sexta das 7h às 19h50<br/> sábados, domingos e feriados, das 7h10 às 18h`,
  },
  {
    question: `Quais os dias do exame dermatológico?`,
    answer: `Os exames acontecem em dias e horários predefinidos. A escala é divulgada previamente neste site, na aba<br/> informativos, e também nas redes sociais do clube e também nos avisos internos do clube.`,
  },
  {
    question: `Meu exame dermatológico venceu, consigo freqüentar a piscina?`,
    answer: `Sim. Ao efetuar o pagamento da taxa do exame dermatológico o associado terá 30 (trinta dias) para realizar o exame.<br/> Caso não realize o exame dentro deste período, o associado ficará impedido de fazer uso das piscinas.`,
  },
  {
    question: `Perdi a minha carteira, o que fazer?`,
    answer: `Comunicar diretamente a secretaria do clube e solicitar a emissão de nova carteira. A comunicação é necessária para que possamos providenciar o bloqueio no sistema.`,
  },
  {
    question: `Onde encontro o horário das escolinhas do Clube?`,
    answer: `Neste site, no menu esportes, e também na secretaria do Clube.`,
  },
  {
    question: `Como faço para me tornar associado do Clube?`,
    answer: `Será necessário procurar a secretaria do Clube, apresentar os documentos do titular e dos dependentes (se houver), no ato da entrega da(s) propostas(s). Não aceitamos solicitações com pendências de documentos. É imprescindível a entrega dos documentos conforme marcado na relação que foi entregue no ato do atendimento, assim como a assinatura de um sócio proponente.`,
  },
  {
    question: `Podemos alugar os salões do Clube, para eventos?`,
    answer: `Sim. O clube tem vários pontos agradáveis para festas e comemorações que podem ser locados a associados ou não. As taxas para associados têm preço diferenciado. As mesmas são feitas com o setor social do clube, de forma antecipada e agendamento prévio. No ato do agendamento será dado conhecimento ao interessado das normas de locação. Para informações sobre datas disponíveis e valores o contato pode ser feito pelo telefone (24) 2102-2750. para efetivar a locação é necessário comparecer à administração do Clube na PET de segunda a sexta para acertos e pagamentos.`,
  },
  {
    question: `Como faço para levar um amigo (a), para conhecer o Clube?`,
    answer: `Para conhecer o clube não será necessário efetuar o pagamento de nenhuma taxa, é necessário que o visitante esteja acompanhado de um sócio e apresente um documento de identificação com foto. Residentes de Volta Redonda e cidades circunvizinhas não podem fazer uso de nenhuma dependência, somente conhecer o clube. Os residentes nas demais cidades devem efetuar o pagamento de uma diária no valor de 90,00 (noventa reais), apresentar o documento de identificação com foto, apresentar comprovante de residência em nome do visitante e então será liberado para fazer uso de todas as dependências.`,
  },
  {
    question: `Para fazer uma crítica, sugestão ou tirar uma dúvida, tenho que ir até o Clube?`,
    answer: `De forma nenhuma. Você poderá utilizar qualquer um dos canais de comunicação do clube que seu comentário será bem recebido e encaminhado à área a que ele se referenciar. Na secretaria também temos formulário próprio para utilização de nossos associados que querem colaborar conosco no dia-a-dia do clube, ajudando-nos com suas idéias, críticas e sugestões a engrandecer nosso clube.`,
  },
  {
    question: `O exame dermatológico é pago?`,
    answer: `Sim. É cobrada uma taxa de R$ 10,00.`,
  },
  {
    question: `A segunda via da carteira é paga?`,
    answer: `Sim, ela é paga. O valor atualmente é de R$10,00.`,
  },
  {
    question: `Preciso de atestado médico de aptidão física, para entrar para uma escolinha do Clube?`,
    answer: `Será necessário preencher um formulário de Responsabilidade de Aptidão Física. A apresentação do atestado médico, dar-se-á mediante análise de suas respostas neste formulário.`,
  },
  {
    question: `Quanto tempo demora a confecção da carteira do Clube?`,
    answer: `A carteirinha é feita e entregue na hora na secretaria do Clube.`,
  },
  {
    question: `Crianças a partir de qual idade, já pagam mensalidade?`,
    answer: `As crianças pagam mensalidades a partir de 05 anos de idade. A inclusão na dependência do titular deve ser feita logo que nasce, mediante pleito do titular e apresentação de cópia da certidão de nascimento.`,
  },
  {
    question: `Posso fazer uma carteira para o meu filho (a), a partir de qual idade?`,
    answer: `A partir de 5 anos é obrigatória a apresentação da carteira social. Para menores de cinco anos fica à critério do titular.`,
  },
  {
    question: `Posso pedir um afastamento do Clube, se eu estiver morando/trabalhando ou estudando em outra cidade fora de Volta Redonda e cidades vizinhas?`,
    answer: `Está prevista em nosso estatuto a licença ausentes de Volta redonda e cidades circunvizinhas, mediante requerimento e a critério da Diretoria. Para pleitea - la, o titular deverá comparecer à secretaria do Clube, preencher sua solicitação e entregar cópia dos comprovantes solicitados.`,
  },
  {
    question: `Uma pessoa que é NÃO-ASSOCIADO, mas faz escolinha no Clube, pode frequentar o Clube fora do horário da aula?`,
    answer: `Não. A carteira das diversas escolas de esportes é diferenciada da de associado. No verso da carteira contém um código de barras onde constam o horário e os dias da semana do curso em que houve matrícula, a liberação de entrada na catraca da portaria só ocorre 60 minutos antes do horário. ATENÇÃO: Os acompanhantes de alunos devem permanecer no local destinado às atividades, não tendo acesso liberado às demais dependências do Clube.`,
  },
  {
    question: `Esqueci alguns objetos de uso pessoal na piscina, o que devo fazer?`,
    answer: `De imediato poderá recorrer ao responsável pela limpeza ou ao porteiro do parque aquático a fim de se certificar se deixaram o objeto com ele. Caso contrário, todos os objetos achados ou perdidos são recolhidos e cadastrados pelo setor de esportes do clube. A guarda e a entrega dos referidos itens é realizada no setor de achados e perdidos, localizado no Setor administrativo, ao lado do ginásio em horário comercial. É importante lembrar que o Clube não se responsabiliza por objetos dos associados e/ou frequentadores.`,
  },
  {
    question: `Se eu entrar para uma escolinha no dia 10, preciso pagar o mês todo?`,
    answer: `Sim. O vencimento das mensalidades é todo dia 10.`,
  },
  {
    question: `Todas as escolinhas, têm taxa de matrícula?`,
    answer: `Sim.Todas tem taxa de matricula.`,
  },
  // {
  //   question: `Gostaria de saber os horários dos eventos do Clube?`,
  //   answer: `O interessado poderá consultar este site na aba EVENTOS, acompanhar nossas redes sociais,nos passar um e-mail, ou consultar a secretaria.`,
  // },
  {
    question: `Sou sócio, para entrar no clube, preciso levar sempre a minha carteira?`,
    answer: `Sim, é imprescindível e faz parte das obrigações dos associados. A carteira social identifica o portador desde o momento em que se apresentar na portaria do clube.`,
  },
  {
    question: `Posso pagar a minha mensalidade no Clube?`,
    answer: `Sim, podendo também fazer a opção para débito em conta, desconto na folha da prefeitura ou então via cartão de crédito (onde as mensalidades irão junto com sua fatura do cartão de crédito).`,
  },
  {
    question: `Quais as formas de pagamento aceitas no Clube?`,
    answer: `Os pagamentos poderão ser feitos em espécie, em cartão de débito ou crédito.`,
  },
  {
    question: `Que dia tenho que pagar o Clube?`,
    answer: `O vencimento da mensalidade do Clube é sempre no dia 10 de cada mês. Após esta data, serão cobrados juros e mora. Juros de 2% e mora de .0,1% ao dia.`,
  },
  {
    question: `É necessária alguma senha para acessar o site do clube?`,
    answer: `Não. Todo e qualquer associado pode se beneficiar das informações disponibilizadas no Site do clube. O uso da senha é apenas para o portal do sócio.`,
  },
  {
    question: `Perdi o meu boleto, preciso ir até o Clube para gerar uma segunda via?`,
    answer: `Não. Você poderá obter a segunda via através do Portal do Sócio no site do clube em minha conta ou até mesmo ligar e solicitar a segunda via por e-mail.`,
  },
  {
    question: `Que vantagens terei ao me cadastrar em MINHA CONTA?`,
    answer: `Você poderá acessar seu saldo do cartão de consumo da família e outros benefícios que o clube está implantando, inclusive segunda via do boleto bancário.`,
  },
  // {
  //   question: `O que o Clube oferece nos finais de semana, para as crianças?`,
  //   answer: `Temos aos domingos, o Alô Garotada, com brinquedos diversos. Nas datas festivas, o Clube sempre promove um Alô Garotada especial, a diversão é garantida!`,
  // },
  {
    question: `O Clube tem algum tipo de controle de créditos?`,
    answer: `Sim. Hoje, o associado já consegue inserir créditos na sua carteira e usufruir desse benefício em todo o clube. Através de nosso site no PORTAL DO ASSOCIADO , o mesmo também poderá acompanhar o controle de créditos de seu cartão de consumo e dos dependentes. Basta se dirigir a qualquer caixa do clube, terminal de auto atendimento ou secretaria e efetuar a recarga.`,
  },
  {
    question: `Onde compro ingressos para os eventos do Clube?`,
    answer: `Temos eventos do clube e outros terceirizados. Os do clube sempre serão vendidos em nossa secretaria e os de terceirizados variam conforme a produção do evento. Fiquem atentos neste site e nas nossas redes sociais para saber os locais de venda de cada evento`,
  },
  // {
  //   question: `Como posso saber se as piscinas do Clube estão sendo limpas?`,
  //   answer: `Neste site, em informativos, disponibilizamos o programa de aspiração das piscinas, que é realizado semanalmente com todo cuidado e controle necessário.`,
  // },
  // {
  //   question: `Onde posso descobrir os telefones do Clube?`,
  //   answer: `
  //      Em nossa página <a href="https://clubedosfuncionarios.com.br/site/contato/fale-conosco">Fale Conosco</a>
  //       deste site.
  //   `,
  // },
];
