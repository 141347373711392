import React, { useCallback, useEffect } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import {
  PaginateGalleriesActions as Actions,
  PaginateGalleriesState as State,
} from "store/ducks/galleries";
import { GalleryCard } from "components/Galleries";

import * as S from "./styles";

export const Galleries: React.FC = () => {
  const dispatch = useDispatch();

  const { data: galleries, loading } = useSelector<RootStateOrAny, State>(
    (state) => state.paginateGalleries
  );

  const fetchGalleries = useCallback((): void => {
    dispatch(Actions.request({ limit: 2 }));
  }, [dispatch]);

  useEffect(() => {
    fetchGalleries();
  }, [fetchGalleries]);

  return (
    <S.Galleries>
      <S.Restrainer>
        <S.Title>Galeria {loading && <S.ActivityIndicator />}</S.Title>
        <S.GalleriesGrid>
          {galleries.map((gallery) => (
            <GalleryCard zoomOnHover key={gallery.id} gallery={gallery} />
          ))}
        </S.GalleriesGrid>
        <S.GalleriesFooter>
          <S.MoreButton to="/galerias">Ver mais fotos</S.MoreButton>
        </S.GalleriesFooter>
      </S.Restrainer>
    </S.Galleries>
  );
};
