import React from "react";

import * as C from "components/Pages/Sports/Snooker";
import { PageBanner } from "components/Shared";
import Scaffold from "layouts/Scaffold";

export const SnookerPage: React.FC = () => {
  return (
    <Scaffold>
      <PageBanner
        image={require("assets/images/snooker-banner.jpg")}
        title="Sinuca"
        subtitle="Confira informações e horários"
      />
      <C.Main />
      <C.RelatedArticles />
    </Scaffold>
  );
};
