import React from "react";
import Scaffold from "layouts/Scaffold";
import { PageBanner } from "components/Shared";
import * as C from "components/Pages/Contact/Ombudsman";

export const OmbudsmanPage: React.FC = () => {
  return (
    <Scaffold>
      <PageBanner
        image={require("assets/images/generic-banner.jpg")}
        title="Ouvidoria"
        subtitle="Como podemos ajudar?"
      />
      <C.Main />
    </Scaffold>
  );
};
