import React from "react";

import * as S from "./styles";

const InfoBar: React.FC = () => {
  return (
    <S.InfoBar>
      <S.Container>
        <div>
          <span>Horários do Clube: </span> Segunda à sábado: 6h30 às 23h |
          Feriados e domingos: 6h30 às 21h
        </div>
        <S.AreaTour
          href="https://www.clubedosfuncionarios.com.br/tour/"
          target="_blank"
        >
          <S.IconTour /> Tour 360º
        </S.AreaTour>
        <div>
          <span>Fale conosco: </span> (24) 2102-2750
        </div>
      </S.Container>
    </S.InfoBar>
  );
};

export default InfoBar;
