import React from "react";

import * as S from "./styles";

export const Main: React.FC = () => {
  return (
    <S.Main>
      <S.Restrainer>
        <S.MainContainer>
          <S.ContentBlock>
            <S.Title>Muay Thai</S.Title>
            <S.Text>
              Muay Thai é uma luta tailandesa que vem ganhando as academias
              brasileiras. Com mais de dois mil anos de existência, a história
              do Muay Thai tem suas origens vinculadas à uma época em que
              dominar as técnicas marciais, garantia poder e sobrevivência.
            </S.Text>
            <S.Text>
              A arte marcial também é conhecida por outros nomes: Boxe
              tailandês, Thai Boxing e Luta das Oito Armas são sinônimos que
              carregam uma herança e legado dignos das lendas do Ocidente.
              <br />
              <br />
            </S.Text>

            <S.ContentBlock>
              <S.Subtitle>A importância dos atestados médicos</S.Subtitle>
              <S.Text>
                É obrigatório entregar os atestados médicos para a prática das
                atividades esportivas no clube, de acordo com a LEI 2.014/92 de
                15/07/1992.
              </S.Text>
              <S.Text>
                O Atestado médico deve ser específico a cada uma das
                modalidades, evitando quaisquer problemas de saúde aos
                associados.
              </S.Text>
              <S.Text>
                Para verificar suas condições de saúde e a aptidão para a
                prática da atividade física pretendida, o Departamento de
                Esportes recomenda que o associado procure o médico de sua
                confiança para fazer todos os exames necessários
              </S.Text>

              <S.RedSubtitle>
                • As atividades esportivas para não sócios são permitidas apenas
                para menores de 18 anos.
              </S.RedSubtitle>
            </S.ContentBlock>

            <S.Subtitle>Horário das Aulas</S.Subtitle>
            <S.Text>
              Professor Fernando Secron: (24) 99287-7401
              <br />
              <br />
              <span>(Terça e quinta-feira)</span>
              <br />
              18h45 às 19h30 - Infantil (06 à 17 anos)
              <br />
              19h30 às 20h30 - Adulto
              <br />
            </S.Text>

            <S.Subtitle>Mensalidade:</S.Subtitle>
            <S.Text>
              Mensalidade sócio: R$ 130,00 ( 2x na semana)
              <br />
              <br />
              Matrícula não sócio: R$ 50,00
              <br />
              Mensalidade não Sócio: R$ 180,00 (2x na semana)
              <br />
            </S.Text>
            <S.Text>
              • Preencher termo de responsabilidade para a prática de atividade
              física.
              <br />
              <br />
              Local: Ginásio
            </S.Text>
          </S.ContentBlock>
        </S.MainContainer>
      </S.Restrainer>
    </S.Main>
  );
};
