import { useEffect, useState } from "react";

interface ScreenSize {
  width: number;
  height: number;
}

const getScreenSize = (): ScreenSize => {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
  };
};

export const useScreenSize = () => {
  const [screenSize, setScreenSize] = useState<ScreenSize>(getScreenSize());

  useEffect(() => {
    const handleResize = () => {
      setScreenSize(getScreenSize());
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  return screenSize;
};
