import React from "react";
import * as S from "./styles";

export const Main: React.FC = () => {
  return (
    <S.Main>
      <S.Restrainer>
        <S.MainContainer>
          <S.Title>Conheça a história do Clube dos Funcionários</S.Title>
          <S.Text>
            Época de carnaval, em fevereiro de 1942, e um grupo de funcionários
            da Companhia Siderúrgica Nacional vai a um baile em Barra Mansa. Os
            foliões, entretanto, são impedidos de entrar na festa porque
            torceram contra o time da cidade. Surge aí, a ideia de criar um
            clube onde pudessem se divertir. No dia seguinte ao baile, 19 de
            fevereiro, o grupo de amigos se reuniu, montou uma diretoria
            provisória e já redigiu a ata de criação do Clube dos Funcionários
            da Companhia Siderúrgica Nacional, que contou com 116 membros
            fundadores. Sua instalação inicial era um barracão na área da usina
            onde eram feitos bailes e reuniões.
          </S.Text>
          <S.Text>
            Desde sua criação, o Clube passou por transformações e melhorias ao
            longo dos anos. Um marco foi a construção do Cine 9 de Abril em
            1959, sendo até hoje a maior sala cinema em funcionamento no país
            com 1505 lugares. O espaço tem tamanha importância que foi tombado
            pelo Patrimônio Histórico em 1985. A ideia da Diretoria da época era
            de fazer um cinema de luxo na cidade, tanto que por muitos anos, só
            era permitido frequentar o local de terno e gravata.
          </S.Text>
          <S.Text>
            Apesar de ser amplamente conhecido pelas atividades culturais e de
            lazer, o CFCSN desempenhou um papel de destaque na educação local.
            Logo após sua criação, o Clube fundou um Curso de Aperfeiçoamento em
            que professores se reuniam para dar aulas de português, inglês,
            matemática, geografia, história e taquigrafia. Além disso, teve a
            iniciativa de criar um ginásio voltado ao ensino médio que viria a
            se tornar o Colégio Macedo Soares em 1944. O CFCSN também teve
            participação ativa e pioneira em trazer o sinal de duas emissoras de
            TV para a cidade em 1952.
          </S.Text>
          <S.Text>
            O branco e o grená foram escolhidos como cores oficiais em 1962,
            mesmo ano em que o CFCSN ganhou a Sede Social(Edifício Guilherme
            Guinle) ao lado do cinema. A Praça de Esportes Tabajaras, mais
            conhecida como PET, começou a ser construída em 1965 na Floresta da
            Cicuta. O espaço se tornaria um dos principais complexos de esporte
            e lazer do estado. Outra obra de grande relevância para a cidade e
            seu entorno foi o Parque Aquático, com 4 piscinas(entre elas uma
            olímpica e um tanque de saltos oficiais) começou a ser construído em
            1972 e foi fundado em 1975, sendo à época um dos mais completos do
            Brasil. Em 1998, foi inaugurado o Ginásio da PET, com capacidade
            para 5.200 pessoas, que conta com uma quadra poliesportiva,
            vestiários, palco, camarins e além do objetivo principal da prática
            esportiva, também abriga muitos eventos sociais, como shows, peças
            de teatro, casamentos, formaturas e etc.
          </S.Text>
          <S.Text>
            A agremiação que tem um passado de glórias esportivas, cedeu vários
            atletas para seleções brasileiras de diferentes modalidades e tem
            como maior destaque entre os atletas nela iniciados, o nadador
            Thiago Pereira, recordista de medalhas em jogos Pan americanos e
            medalhista olímpico.
          </S.Text>
          <S.Text>
            Além disso, as instalações da PET, foram as únicas do interior do
            estado a receber atletas olímpicos e paralímpicos para os jogos Rio
            2016.
          </S.Text>
          <S.Text>
            Atualmente, além de ser o maior formador individual da atletas do
            sul fluminense, o Clube também é referência em lazer, entretenimento
            e cultura. Suas escolinhas contam com mais de 3.000 alunos, e entre
            eventos tradicionais, peças teatrais, festas, concursos culturais,
            palestras, eventos beneficentes, entre outros, podemos afirmar
            seguramente que, em média, mais de 20.000 pessoas passam mensalmente
            pelas suas sedes.
          </S.Text>
          <S.Text>
            É notável a importância do CFCSN para a região e a ideia é continuar
            buscando realizações do interesse de todos. Para a Diretoria, fica a
            certeza de que ao longo destes anos, o Clube conseguiu se erguer e
            se consolidar como uma das mais importantes e respeitadas
            instituições do município. Os associados podem e devem se orgulhar
            de toda esta história e seguir em frente colaborando e ajudando na
            construção de muitos anos mais de glórias e conquistas. O CFCSN
            agradece a todos aqueles que ajudaram a escrever esta história, em
            especial aos presidentes que tanto se dedicaram para transformar o
            Clube dos Funcionários no que ele é hoje.
          </S.Text>
          <S.Text>
            Demérito Boueri, Octávio da Costa Monteiro, Itagiba Nogueira de Sá,
            Paulo César Gomes Martins, José Marco Ferreira de Souza, Newton
            Coimbra Bittencourt Cotrin, Benevenuto dos Santos Netto, Ervin
            Michelstadter, Arino Gonçalves de Oliveira, Fernando Antônio
            Rodrigues de Almeida, José Mauro Lemos, Airton Lécio do Prado
            Castro, Djalma Gamis de Oliveira, Renato Santini, Evandro Ruy e
            Cláudio Augusto.
          </S.Text>
        </S.MainContainer>
      </S.Restrainer>
    </S.Main>
  );
};
