import React, { useCallback, useRef, useState } from "react";
import { NavigationMenuEntry } from "contracts/Common";
import * as S from "./styles";

interface Props {
  entry: NavigationMenuEntry;
}

const MenuEntry: React.FC<Props> = ({ entry }) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const accordionContainer = useRef<HTMLDivElement>(null);

  const OptionComponent = useCallback(
    ({ entry }: { entry: NavigationMenuEntry }): JSX.Element => {
      if (entry?.path) {
        return <S.LinkOption to={entry.path}>{entry.label}</S.LinkOption>;
      }

      if (entry?.externalPath) {
        return (
          <S.ExternalLinkOption href={entry.externalPath} target="_blank">
            {entry.label}
          </S.ExternalLinkOption>
        );
      }

      if (entry?.action) {
        return (
          <S.ActionOption onClick={entry.action}>{entry.label}</S.ActionOption>
        );
      }

      return <></>;
    },
    []
  );

  const HandlerComponent = useCallback(
    ({ entry }: { entry: NavigationMenuEntry }): JSX.Element => {
      if (entry?.path) {
        return <S.LinkHandler to={entry.path}>{entry.label}</S.LinkHandler>;
      }

      if (entry?.externalPath) {
        return (
          <S.ExternalLinkHandler href={entry.externalPath} target="_blank">
            {entry.label}
          </S.ExternalLinkHandler>
        );
      }

      if (entry?.action) {
        return (
          <S.ActionHandler onClick={entry.action}>
            {entry.label}
          </S.ActionHandler>
        );
      }

      if (entry?.children) {
        return (
          <S.AccordionContainer ref={accordionContainer}>
            <S.AccordionHandler onClick={() => setIsActive((s) => !s)}>
              <span>{entry.label}</span> <S.CaretDownIcon />
            </S.AccordionHandler>
            <S.OptionsContainer isActive={isActive}>
              {entry.children.map((entry, index) => (
                <OptionComponent key={index} entry={entry} />
              ))}
            </S.OptionsContainer>
          </S.AccordionContainer>
        );
      }

      return <></>;
    },
    [OptionComponent, isActive]
  );

  return <HandlerComponent entry={entry} />;
};

export default MenuEntry;
