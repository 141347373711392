import React, { useCallback } from "react";

import { Accordion } from "components/Shared";
import { AnnualStatement, annualStatements } from "data/transparency";

import * as S from "./styles";

const getDownloadUrl = (filename: string): string => {
  const baseUrl = "/site/cbc-documentos";
  return `${baseUrl}/${filename}`;
};

const getIdentifier = (annualStatement: AnnualStatement): string => {
  return `ANNUAL_STATEMENTS_${annualStatement.year}`;
};

interface Props {
  activeAccordion: string;
  onActivate: (activeAccordion: string) => void;
}

export const AnnualStatements: React.FC<Props> = ({
  activeAccordion,
  onActivate,
}) => {
  const DocumentList = useCallback(
    ({ statement }: { statement: AnnualStatement }): JSX.Element => {
      return (
        <S.Documents>
          {statement.documents.map((document, index) => (
            <S.Document
              key={index}
              target="_blank"
              href={getDownloadUrl(document.filename)}
            >
              {document.name}
            </S.Document>
          ))}
        </S.Documents>
      );
    },
    []
  );

  const handleAccordion = useCallback(
    (annualStatement: AnnualStatement): void => {
      onActivate(getIdentifier(annualStatement));
    },
    [onActivate]
  );

  return (
    <S.Container>
      <S.Accordions>
        {annualStatements.map((annualStatement, index) => (
          <Accordion
            key={index}
            title={`Balanço patrimonial e DRE ${annualStatement.year}`}
            isOpen={activeAccordion === getIdentifier(annualStatement)}
            onClick={() => handleAccordion(annualStatement)}
          >
            <DocumentList statement={annualStatement} />
          </Accordion>
        ))}
      </S.Accordions>
    </S.Container>
  );
};
