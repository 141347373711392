import React from "react";
import Scaffold from "layouts/Scaffold";
import * as C from "components/Pages/CBCCovenant";

export const CBCCovenantPage: React.FC = () => {
  return (
    <Scaffold>
      <C.Main />
    </Scaffold>
  );
};
