import styled from "styled-components";
import { ReactComponent as Crux } from "assets/icons/crux.svg";
import { ScreenSize } from "styles/contracts";

export const Container = styled.div`
  width: 100%;
  height: auto;
`;

// members

export const MembersTitle = styled.h4`
  font-size: 16px;
  margin-bottom: 32px;
  text-align: center;
`;

export const Members = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  column-gap: 20px;
  row-gap: 20px;
  width: 100%;
  height: auto;
  margin: 0 auto;

  @media ${ScreenSize.TabletAndDown} {
    justify-content: center;
  }
  @media ${ScreenSize.SmallTabletAndDown} {
    justify-content: center;
  }
`;

export const Member = styled.div`
  display: flex;
  width: 100%;
  max-width: 200px;
  height: auto;
  flex-direction: column;
  align-items: center;
`;

export const ImageContainer = styled.figure`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 140px;
  overflow: hidden;
  border-radius: 50%;
`;

export const Image = styled.img`
  width: inherit;
  height: auto;
`;

export const Name = styled.p`
  font-size: 14px;
  margin-top: 16px;
  text-align: center;
`;

export const Period = styled.p`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  margin-top: 8px;
  text-align: center;
  gap: 0 8px;
`;

export const CruxIcon = styled(Crux)`
  height: 10px;
  path {
    fill: currentColor;
  }
`;
