import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

import NavigationMenu from "./NavigationMenu";
import * as S from "./styles";

export type SidebarRef = {
  toogle: () => void;
} | null;

type Props = {};

const Sidebar: React.ForwardRefExoticComponent<
  Props & React.RefAttributes<SidebarRef>
> = forwardRef<SidebarRef, Props>((_, ref) => {
  const sidebarRef = useRef<HTMLElement>(null);
  const [isActive, setIsActive] = useState<boolean>(false);

  const handleToggle = useCallback((): void => {
    setIsActive((state) => !state);
  }, []);

  const handleClickOutside = useCallback((event): void => {
    if (!sidebarRef?.current?.contains(event.target)) {
      setIsActive(false);
    }
  }, []);

  useImperativeHandle(
    ref,
    () => ({
      toogle: handleToggle,
    }),
    [handleToggle]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  useEffect(() => {
    if (isActive) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  }, [isActive]);

  return (
    <S.Sidebar
      ref={sidebarRef}
      role="navigation"
      className={isActive ? "active" : ""}
    >
      <S.SidebarHeader>
        <div></div>
        <S.CloseButton onClick={handleToggle}>&times;</S.CloseButton>
      </S.SidebarHeader>
      <S.SidebarBody>
        <NavigationMenu />
      </S.SidebarBody>
      <S.SidebarFooter>
        <S.CalendarContainer>
          <S.CalendarIcon />
          <div>
            <p>Segunda à sexta: 8h às 18:30</p>
            <p> Sábados, domingos e feriados: 8h às 15h</p>
          </div>
        </S.CalendarContainer>
        <S.PhoneContainer>
          <S.PhoneIcon /> (24) 2102-2750
        </S.PhoneContainer>
      </S.SidebarFooter>
    </S.Sidebar>
  );
});

export default Sidebar;
