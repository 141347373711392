interface Member {
  name: string;
  image: string;
  role?: string;
}

export const fiscalCommission: Member[] = [
  {
    name: "Alexandre Luiz dos S. Botelho",
    image: "alexandre-luiz-botelho.jpg",
    role: "Suplente",
  },
  {
    name: "Cesar de Oliveira Vieira",
    image: "cesar-de-oliveira-vieira.jpg",
    role: "Efetivo",
  },
  {
    name: "Felipe Mendonça de Oliveira",
    image: "felipe-mendonca.jpg",
    role: "Suplente",
  },
  {
    name: "Max Lima de Aquino",
    image: "max-aquino.jpg",
    role: "Suplente",
  },
  {
    name: "Sergio Ramos Haubrick",
    image: "sergio-ramos-haubrick.jpg",
    role: "Suplente",
  },
  // {
  //   name: "Fernando Cajazeira de Rezende",
  //   image: "fernando-cajazeira-de-rezende.jpg",
  //   role: "Suplente",
  // },
  // {
  //   name: "Leandro Ferreira Galito",
  //   image: "leandro-ferreira-galito.jpg",
  //   role: "Efetivo",
  // },
  // {
  //   name: "Mauricio Domingues da Silva",
  //   image: "mauricio-domingues-da-silva.jpg",
  //   role: "Suplente",
  // },
  {
    name: "Orlando de Oliveira",
    image: "orlando-de-oliveira.jpg",
    role: "Efetivo",
  },
  {
    name: "Valmir Martins da Silva",
    image: "valmir-martins-da-silva.jpg",
    role: "Efetivo",
  },

  // {
  //   name: "Sergio Luiz Penna Salgueiro",
  //   image: "sergio-luiz-penna-salgueiro.jpg",
  //   role: "Efetivo",
  // },
  // {
  //   name: "Theo Iostte de Oliveira",
  //   image: "theo-iostte-de-oliveira.jpg",
  //   role: "Efetivo",
  // },
];
