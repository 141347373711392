import { combineReducers } from "redux";

// articles
import { paginateArticleCategories } from "./articleCategories";
import { fetchArticle, paginateArticles } from "./articles";
// galleries
import { fetchGallery, paginateGalleries } from "./galleries";
import { fetchGeneralSearch } from "./generalSearch";
// homepage banners
import { paginateHomepageBanners } from "./homepageBanners";
// messages
import { sendContactMessage, sendOmbudsmanMessage } from "./messages";
// pagination cache
import { paginationParamsCache } from "./paginationCache";
// videos
import { fetchVideo, paginateVideos } from "./videos";

export default combineReducers({
  // articles
  paginateArticleCategories,
  fetchArticle,
  paginateArticles,
  // homepage banners
  paginateHomepageBanners,
  // galleries
  fetchGallery,
  paginateGalleries,
  // messages
  sendContactMessage,
  sendOmbudsmanMessage,
  // pagination cache
  paginationParamsCache,
  // videos
  fetchVideo,
  paginateVideos,
  //general search
  fetchGeneralSearch,
});
