import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { ReactComponent as CaretDown } from "assets/icons/caret-down.svg";
import { ReactComponent as PopoverTriangle } from "assets/icons/popover-triangle.svg";
import { Colors, Fonts } from "styles/contracts";

const baseHandlerStyle = css`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 14px;
  font-family: ${Fonts.NunitoSemiBold};
  color: ${Colors.Light1}cc;
  padding: 8px 0;
  cursor: pointer;
  transition: all 300ms ease-in-out;
  &:hover {
    color: ${Colors.Light1};
  }
`;

export const LinkHandler = styled(Link)`
  ${baseHandlerStyle}
`;

export const ExternalLinkHandler = styled.a`
  ${baseHandlerStyle}
`;

export const ActionHandler = styled.button`
  ${baseHandlerStyle}
`;

// in case of popover

export const PopoverContainer = styled.div``;

export const PopoverHandler = styled.div`
  ${baseHandlerStyle};
  gap: 0 12px;
`;

export const CaretRightIcon = styled(CaretDown)`
  transform: rotate(-90deg);
  path {
    fill: ${Colors.Light2};
    stroke: ${Colors.Light2};
  }
`;

export const AnimatedContainer = styled.div.attrs({
  className: "animated-container",
})`
  position: absolute;
  display: flex;
  flex-direction: row;
  left: 100%;
  z-index: 2;
  transform: translateY(calc(50% - 38px));

  animation: slideRightPopover 200ms ease-in-out forwards;

  @keyframes slideRightPopover {
    from {
      left: 90%;
      opacity: 0;
    }
    to {
      left: 100%;
      opacity: 1;
    }
  }
`;

export const TriangleContainer = styled.div`
  position: relative;
  min-width: inherit;
  height: fit-content;
  width: fit-content;
  margin-top: 29px;
`;

export const TriangleIcon = styled(PopoverTriangle)`
  transform: rotate(-90deg);
  margin-right: -5px;
  polygon {
    fill: ${Colors.Red4};
    stroke: ${Colors.Red4};
  }
`;

export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: ${Colors.Red4};
  min-width: inherit;
  width: max-content;
  padding: 24px 14px 24px 24px;
  border-radius: 4px;

  /* max-height: 30vh; */
  overflow-y: scroll;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  ::-webkit-scrollbar:vertical {
    width: 10px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    border: 2px solid ${Colors.Red4};
    background-color: ${Colors.Dark5}44;
  }
  ::-webkit-scrollbar-track {
    background-color: ${Colors.Red4};
    border-radius: 4px;
  }
`;
