interface iMagazine {
  id: number;
  file: string;
  title: string;
  url: string;
  thumb: string;
}

const Magazine: iMagazine[] = [
  {
    id: 21,
    file: "prestando_contas_2024.pdf",
    title: "Prestando Contas 2024",
    url: "informaClube/21-Prestando-contas-2024/",
    thumb: "prestando_contas_2024.jpg",
  },
  {
    id: 20,
    file: "informa-clube.pdf",
    title: "Outubro a Dezembro 2023",
    url: "informaClube/20-Outubro-2023/",
    thumb: "outubro-2023.jpg",
  },
  {
    id: 19,
    file: "informa-clube.pdf",
    title: "Agosto/Setembro 2023",
    url: "informaClube/19-Agosto-2023/",
    thumb: "agosto-2023.jpg",
  },
  {
    id: 18,
    file: "informa-clube.pdf",
    title: "Junho/Julho 2023",
    url: "informaClube/18-Junho-2023/",
    thumb: "junho-2023.jpg",
  },
  {
    id: 17,
    file: "informa-clube.pdf",
    title: "Abril/Maio 2023",
    url: "informaClube/17-maio-2023/",
    thumb: "maio-2023.jpg",
  },
  {
    id: 16,
    file: "informa-clube.pdf",
    title: "Março 2023",
    url: "informaClube/16-marco-2023/",
    thumb: "marco-2023.jpg",
  },
  {
    id: 15,
    file: "informa-clube.pdf",
    title: "Fevereiro 2023",
    url: "informaClube/15-fevereiro-2023/",
    thumb: "fevereiro-2023.jpg",
  },
  {
    id: 14,
    file: "informa-clube.pdf",
    title: "Janeiro 2023",
    url: "informaClube/14-janeiro-2023/",
    thumb: "janeiro-2023.jpg",
  },
  {
    id: 13,
    file: "informa-clube.pdf",
    title: "Dezembro 2022",
    url: "informaClube/13-dezembro-2022/",
    thumb: "dezembro-2022.jpg",
  },
  {
    id: 12,
    file: "informa-clube.pdf",
    title: "Novembro 2022",
    url: "informaClube/12-novembro-2022/",
    thumb: "novembro-2022.jpg",
  },
  {
    id: 11,
    file: "informa-clube.pdf",
    title: "Outubro 2022",
    url: "informaClube/11-outubro-2022/",
    thumb: "outubro-2022.jpg",
  },
  {
    id: 10,
    file: "informa-clube.pdf",
    title: "Setembro 2022",
    url: "informaClube/10-setembro-2022/",
    thumb: "setembro-2022.jpg",
  },
  {
    id: 9,
    file: "informa-clube.pdf",
    title: "Agosto 2022",
    url: "informaClube/09-agosto-2022/",
    thumb: "agosto-2022.jpg",
  },
  {
    id: 8,
    file: "informa-clube.pdf",
    title: "Julho 2022",
    url: "informaClube/08-julho-2022/",
    thumb: "julho-2022.jpg",
  },
  {
    id: 7,
    file: "informa-clube.pdf",
    title: "Junho 2022",
    url: "informaClube/07-junho-2022/",
    thumb: "junho-2022.jpg",
  },
  {
    id: 6,
    file: "informa-clube.pdf",
    title: "Abril/Maio 2022",
    url: "informaClube/06-abril-maio/",
    thumb: "abril-maio.jpg",
  },
  {
    id: 5,
    file: "informa-clube.pdf",
    title: "Março 2022",
    url: "informaClube/05-marco-2022/",
    thumb: "marco-2022.jpg",
  },
  {
    id: 4,
    file: "informa-clube.pdf",
    title: "Fevereiro 2022",
    url: "informaClube/04-fevereiro-2022/",
    thumb: "fev-2022.jpg",
  },
  {
    id: 3,
    file: "informa-clube.pdf",
    title: "Janeiro 2022",
    url: "informaClube/03-janeiro-2022/",
    thumb: "jan-2022.jpg",
  },
  {
    id: 2,
    file: "informa-clube.pdf",
    title: "Dezembro 2021",
    url: "informaClube/02-dezembro-2021/",
    thumb: "dez-2021.jpg",
  },
  {
    id: 1,
    file: "informa-clube.pdf",
    title: "Novembro 2021",
    url: "informaClube/01-novembro-2021/",
    thumb: "nov-2021.jpg",
  },
];
export default Magazine;
