import React, { useCallback } from "react";

import { Accordion } from "components/Shared";
import { FiscalCommision, fiscalCommissions } from "data/transparency";

import * as S from "./styles";

const getDownloadUrl = (filename: string): string => {
  const baseUrl = "/site/cbc-documentos";
  return `${baseUrl}/${filename}`;
};

const getIdentifier = (fiscalCommission: FiscalCommision): string => {
  return `FISCAL_COMMISSIONS_${fiscalCommission.year}`;
};

interface Props {
  activeAccordion: string;
  onActivate: (activeAccordion: string) => void;
}

export const FiscalCommissions: React.FC<Props> = ({
  activeAccordion,
  onActivate,
}) => {
  const DocumentList = useCallback(
    ({ commision }: { commision: FiscalCommision }): JSX.Element => {
      return (
        <S.Documents>
          {commision.documents.map((document, index) => (
            <S.Document
              key={index}
              target="_blank"
              href={getDownloadUrl(document.filename)}
            >
              {document.name}
            </S.Document>
          ))}
        </S.Documents>
      );
    },
    []
  );

  const handleAccordion = useCallback(
    (commision: FiscalCommision): void => {
      onActivate(getIdentifier(commision));
    },
    [onActivate]
  );

  return (
    <S.Container>
      <S.Accordions>
        {fiscalCommissions.map((commision, index) => (
          <Accordion
            key={index}
            title={`Parecer da comissão fiscal ${commision.year}`}
            isOpen={activeAccordion === getIdentifier(commision)}
            onClick={() => handleAccordion(commision)}
          >
            <DocumentList commision={commision} />
          </Accordion>
        ))}
      </S.Accordions>
    </S.Container>
  );
};
