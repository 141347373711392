import React from "react";

import * as S from "./styles";

const InfoBar: React.FC = () => {
  return (
    <S.InfoBar>
      <S.Restrainer>
        <S.InfoGrid>
          <S.Info>
            <S.Title>Horários e local</S.Title>
            <S.Text>
              <span>Horário de atendimento na Secretaria:</span>
              <br />
              Segunda a Sexta: 7h às 19h50h
              <br />
              Sábados, Domingos e Feriados: 7h10 às 18h
              <br />
              Rua Hiroshi Matsuda Filho, S/N - Vila Santa Cecília
              <br />
              Volta Redonda/RJ - CEP: 27261-260 (Antiga Rua 90)
              <br />
              <span>Horário de funcionamento do Clube:</span>
              <br />
              Segunda a sábado: 6h30 às 23h <br />
              Domingos e Feriados: 6h30 às 21h
              <br />
              <span>Horário de funcionamento do Pq. Aquático:</span>
              <br />
              Segunda a sábado: 7h às 20h
              <br />
              Domingos e Feriados: 7h às 19h30
              <br />
            </S.Text>
          </S.Info>
          <S.Info>
            <S.Title>Contato</S.Title>
            <S.Text>
              Telefones:
              <br />
              <S.GroupContact>
                (24) 2102-2750{" "}
                <S.GroupWhats target="_blank" href="https://wa.link/1djys2">
                  <S.Zap size={22} /> 24 99251-0959
                </S.GroupWhats>
              </S.GroupContact>
              Atendimento:
              <br />
              atendimento@clubedosfuncionarios.com.br
            </S.Text>
            <S.Text>
              Anúncios e Publicidade:
              <br />
              mkt@clubedosfuncionarios.com.br
            </S.Text>
          </S.Info>
        </S.InfoGrid>
        <S.Separator />
      </S.Restrainer>
    </S.InfoBar>
  );
};

export default InfoBar;
