import styled from "styled-components";
import { BaseExternalLinkButton } from "styles/components";
import { Colors, Fonts, ScreenSize } from "styles/contracts";
import { ReactComponent as YouTube } from "assets/icons/youtube.svg";
export { Restrainer, ActivityIndicator } from "styles/components";

export const Videos = styled.div`
  padding: 18px 0 74px 0;
  background: ${Colors.Dark3};
  color: ${Colors.Light1};
`;

export const Title = styled.h3`
  font-size: 30px;
  margin: 32px 0;
  color: currentColor;
`;

export const VideosGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 42px;

  @media ${ScreenSize.TabletAndDown} {
    grid-template-columns: 1fr 1fr;
  }

  @media ${ScreenSize.Mobile} {
    grid-template-columns: 1fr;
  }
`;

export const VideosFooter = styled.div`
  padding: 32px 0 24px 0;
  display: flex;
  flex-direction: row;
  @media ${ScreenSize.Mobile} {
    justify-content: center;
  }
`;

export const MoreButton = styled(BaseExternalLinkButton)`
  font-family: ${Fonts.NunitoSemiBold};
  border-radius: 24px;
  padding: 0 28px;
  background: ${Colors.Red3};
  color: ${Colors.Light1};
  gap: 0 8px;
`;

export const YouTubeIcon = styled(YouTube)``;

// modal

export const ModalContent = styled.div`
  position: relative;
  max-width: 860px;
  width: 100%;
  height: auto;
  padding: 24px;
  border-radius: 8px;
  background: ${Colors.Dark3};
  animation: ModalPopIn 300ms ease-in-out;
  box-shadow: 0px 32px 32px -16px #000000aa;
`;

export const CloseButton = styled.button`
  background: ${Colors.Red3};
  color: ${Colors.Light1};
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  font-size: 16px;
  top: -12px;
  right: -12px;
  :hover {
    animation: ZoomIn 200ms linear;
    animation-fill-mode: forwards;
  }
`;

export const VideoContainer = styled.div`
  overflow: hidden;
  position: relative;
  width: 100%;
  &::after {
    padding-top: 56.25%;
    display: block;
    content: "";
  }
`;

export const VideoIframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
