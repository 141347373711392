import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { PageBanner } from "../../components/Shared";
import Scaffold from "../../layouts/Scaffold/";

import * as S from "./styles";
import { trees } from "../../data/trees/tree";

export const TreeDetails: React.FC = () => {
  const urlParam = useParams();
  const slug = urlParam.slug;

  const data = trees.filter((info) => info.slug === slug);

  function control(id: number, action: string): void {
    let lp = trees.length - 1;
    let last = trees[lp].id;

    if (action === "next") {
      if (id < last) {
        let next = id;
        let nslug = trees[next].slug;
        window.location.replace(`${nslug}`);
      } else {
        window.location.replace(`abacateiro`);
      }
    }
    if (action === "prev") {
      if (id > 1) {
        let prev = id - 2;
        let slug = trees[prev].slug;
        window.location.replace(`${slug}`);
      } else {
        let search = trees.filter((item) => item.id === last);
        let nslug = search[0].slug;
        window.location.replace(`${nslug}`);
      }
    }
  }

  return (
    <>
      <Scaffold>
        <PageBanner
          image={process.env.PUBLIC_URL + `/images/trees/${data[0].top_banner}`}
          title={data[0].name}
        />
        <S.Container>
          <S.ContentLeft>
            <S.DetailHeader>
              <S.Title>
                Conheça mais sobre {data[0].artigo} {data[0].name}
              </S.Title>
              <S.DetailBack>
                <p>Voltar para Galeria</p>
                <a href="/site/arvores-do-clube">&times;</a>
              </S.DetailBack>
            </S.DetailHeader>

            <S.DetailContent>
              <p> Nome vulgar: {data[0].vulgar_name}</p>
              <p className="padd">
                {" "}
                Nome científico:<span>{data[0].science_name}</span>
              </p>
              <p className="padd">{data[0].about}</p>
              <p>
                Saiba mais:{" "}
                <a href={data[0].link} target="_blank">
                  {data[0].link}
                </a>
              </p>
              <S.DetailsNav>
                <S.GroupControls>
                  <a
                    className="arrowL"
                    href="javascript:void(0)"
                    onClick={() => control(data[0].id, "prev")}
                  >
                    <S.ChevLeft size={15} />
                  </a>
                  Anterior
                </S.GroupControls>
                <S.GroupControls>
                  Próximo
                  <a
                    className="arrowR"
                    href="javascript:void(0)"
                    onClick={() => control(data[0].id, "next")}
                  >
                    <S.ChevRight size={15} />
                  </a>
                </S.GroupControls>
              </S.DetailsNav>
            </S.DetailContent>
          </S.ContentLeft>
        </S.Container>
      </Scaffold>
    </>
  );
};
