import styled from "styled-components";
import { Colors, ColorScheme, Fonts } from "styles/contracts";

export const FormRow = styled.div.attrs({ className: "form-row" })`
  display: flex;
  flex-direction: row;
  gap: 16px 16px;
  &:not(:last-child) {
    margin-bottom: 16px;
  }
  .form-row {
    display: flex;
    flex-direction: rows;
    width: 100%;
  }
`;

export const FieldContainer = styled.div.attrs({
  className: "field-container",
})`
  font-family: ${Fonts.NunitoRegular};
  font-size: 15px;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
`;

export const FieldLabel = styled.label.attrs({ className: "field-label" })`
  display: block;
  font-size: 15px;
  color: ${ColorScheme.Text};
  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const FieldError = styled.span.attrs({ className: "field-error" })`
  display: inline-block;
  color: ${Colors.Red3};
  font-size: 0.86rem;
  padding: 8px 0;
`;

export const FormActions = styled.div.attrs({ className: "form-actions" })`
  display: flex;
  flex-direction: row;
  button:not(:last-of-type) {
    margin-right: 16px;
  }
`;
