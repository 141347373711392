import React, { useCallback, useRef, useEffect } from "react";

import * as S from "./styles";

type CloseButtonProps = {
  onClick: () => void;
};

type ModalProps = {
  isOpen?: boolean;
  onClickOutside?: () => void;
  children: React.ReactNode;
};

export const ModalCloseButton: React.FC<CloseButtonProps> = ({ onClick }) => (
  <S.CloseButton type="button" onClick={onClick}>
    &times;
  </S.CloseButton>
);

export const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClickOutside,
  children,
}) => {
  const overlayRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = useCallback(
    (event: MouseEvent): void => {
      if (!overlayRef?.current?.contains(event.target as Node)) {
        onClickOutside && onClickOutside();
      }
    },
    [onClickOutside]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.body.classList.remove("no-scroll");
    };
  }, [handleClickOutside]);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  }, [isOpen]);

  return (
    <S.ModalOverlay ref={overlayRef} isOpen={isOpen}>
      <S.ContentContainer>{children}</S.ContentContainer>
    </S.ModalOverlay>
  );
};
