import React from "react";
import * as S from "./styles";

type Props = {
  image: string;
  title?: string;
  subtitle?: string;
};

export const PageBanner: React.FC<Props> = ({ image, title, subtitle }) => {
  return (
    <S.Banner>
      <S.BannerContainer>
        <S.BannerImage src={image} />
        <S.Overlay>
          <S.OverlayContainer>
            {title && <S.Title>{title}</S.Title>}
            {subtitle && <S.Subtitle>{subtitle}</S.Subtitle>}
          </S.OverlayContainer>
        </S.Overlay>
      </S.BannerContainer>
    </S.Banner>
  );
};
