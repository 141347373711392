import React from "react";
import * as S from "./styles";

export const OwnerPartnersAssembly: React.FC = () => {
  return (
    <S.Container>
      <S.Text>
        É constituída pelos sócios proprietários e o presidente da Assembléia é
        escolhido pelos presentes. É de 5000 a quantidade de títulos, porém cada
        proprietário só tem direito a 1 voto, mesmo que possua mais de um
        título.
      </S.Text>
    </S.Container>
  );
};
