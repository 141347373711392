import React from "react";
import Scaffold from "layouts/Scaffold";
import { PageBanner } from "components/Shared";
import * as C from "components/Pages/Club/Location";

export const LocationPage: React.FC = () => {
  return (
    <Scaffold>
      <PageBanner
        image={require("assets/images/generic-banner.jpg")}
        title="Localização"
        subtitle="Onde estamos"
      />
      <C.Main />
    </Scaffold>
  );
};
