import React from "react";

import * as S from "./styles";

export const Main: React.FC = () => {
  return (
    <S.Main>
      <S.Restrainer>
        <S.MainContainer>
          <S.ContentBlock>
            <S.Title>Judô</S.Title>
            <S.Text>
              O estilo Takenouchi-ryu fundado em 1532 é considerado a origem do
              estilo Ju-Jutsu japonês. O judô é derivado do Ju-Jutsu, uma arte
              que serve tanto para atacar quanto para defender usando nada mais
              que o seu próprio corpo.
            </S.Text>
            <S.Text>
              Visando fortalecer o caráter filosófico da prática do judô e fazer
              com que os praticantes do judô crescessem como pessoas, o mestre
              Jigoro Kano idealizou um código moral baseado em oito princípios
              básicos:
              <br />
              <br />
              - Cortesia, para ser educado no trato com os outros;
              <br />
              - Coragem, para enfrentar as dificuldades com bravura;
              <br />
              - Honestidade, para ser verdadeiro em seus pensamentos e ações;
              <br />
              - Honra, para fazer o que é certo e se manter de acordo com seus
              princípios;
              <br />
              - Modéstia, para não agir e pensar de maneira egoísta;
              <br />
              - Respeito, para conviver harmoniosamente com os outros;
              <br />
              - Autocontrole, para estar no comando das suas emoções;
              <br />
              - Amizade, para ser um bom companheiro e amigo.
              <br />
            </S.Text>

            <S.ContentBlock>
              <S.Subtitle>A importância dos atestados médicos</S.Subtitle>
              <S.Text>
                É obrigatório entregar os atestados médicos para a prática das
                atividades esportivas no clube, de acordo com a LEI 2.014/92 de
                15/07/1992.
              </S.Text>
              <S.Text>
                O Atestado médico deve ser específico a cada uma das
                modalidades, evitando quaisquer problemas de saúde aos
                associados.
              </S.Text>
              <S.Text>
                Para verificar suas condições de saúde e a aptidão para a
                prática da atividade física pretendida, o Departamento de
                Esportes recomenda que o associado procure o médico de sua
                confiança para fazer todos os exames necessários
              </S.Text>

              <S.RedSubtitle>
                • As atividades esportivas para não sócios são permitidas apenas
                para menores de 18 anos.
              </S.RedSubtitle>
            </S.ContentBlock>

            <S.Subtitle>Professora Mônica - (24) 9.9978-0250</S.Subtitle>
            <S.Text>
              (Terça e quinta-feira)
              <br />
              17h45 às 18h45 - A partir de 04 anos
            </S.Text>

            <S.Subtitle>Mensalidade:</S.Subtitle>
            <S.Text>
              Mensalidade sócio: R$ 60,00
              <br />
              Matrícula não sócio: R$ 50,00
              <br />
              Mensalidade não Sócio: R$ 80,00
              <br />
            </S.Text>
            <S.Text>
              • Preencher termo de responsabilidade para a prática de atividade
              física.
              <br />
              <br />
              Local: Palco do Ginásio
            </S.Text>
          </S.ContentBlock>
        </S.MainContainer>
      </S.Restrainer>
    </S.Main>
  );
};
