import styled from "styled-components";
export { Restrainer } from "styles/components";

export const PromotionalBanner = styled.div`
  padding: 48px 0;
`;

export const BannerImage = styled.img`
  max-width: 100%;
  height: auto;
`;
