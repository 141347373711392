import styled from "styled-components";
import { ChevronLeft } from "@styled-icons/bootstrap/ChevronLeft";
import { ChevronRight } from "@styled-icons/bootstrap/ChevronRight";

export const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  background: #eee;
  min-height: 800px;
`;
export const Content = styled.div`
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  top: -80px;
  width: 90%;
  justify-content: center;
  padding: 50px 80px;

  max-width: 1300px;
  height: auto;
  min-height: 500px;
  background: #fff;
  border-radius: 5px;
`;
export const ContentLeft = styled.div`
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  top: -80px;
  width: 90%;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px;
  max-width: 1300px;
  height: auto;
  min-height: 500px;
  background: #fff;
  border-radius: 5px;
  @media only screen and (max-width: 500px) {
    top: -55px;
  }
`;

export const GroupCards = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 990px;
  justify-content: flex-start;
  column-gap: 50px;
  row-gap: 50px;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px;
  width: 95%;
  max-width: 290px;
  height: 330px;
  img {
    margin-bottom: 10px;
  }
  p.title {
    font-family: "Nunito-Bold";
    font-size: 18px;
  }
  p.text {
    font-family: "Nunito-Regular";
    font-size: 14px;
    span {
      font-style: italic;
    }
  }
  a.link {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Nunito-SemiBold";
    font-size: 14px;
    background: #222;
    color: #fff;
    padding: 5px 12px !important;
    border-radius: 20px;
    margin: 15px 0;
    width: 100%;
    height: auto;
    max-width: 100px;
    transition: 0.5s;
    &:hover {
      background: #fff;
      color: #222;
      border: 1px solid #222;
      transition: 0.5s;
    }
  }
`;

export const PaginationContent = styled.ul`
  display: flex;
  flex-wrap: wrap;
  width: 95%;
  max-width: 990px;
  height: auto;
  column-gap: 8px;
  row-gap: 8px;
  margin: 15px 0;
  li {
    padding: 5px 10px;
    border: 1px solid #eee;
    border-radius: 5px;
  }
`;

export const DetailHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  @media only screen and (max-width: 500px) {
    margin-bottom: 50px;
  }
`;
export const Title = styled.h1`
  font-family: "Nunito-ExtraBold";
  font-size: 26px;
  color: #222;
  span {
    text-transform: capitalize;
  }
  @media only screen and (max-width: 850px) {
    font-size: x-large;
  }
  @media only screen and (max-width: 750px) {
    font-size: large;
  }
  @media only screen and (max-width: 500px) {
    width: 100%;
    max-width: 180px;
    font-size: large;
  }
`;
export const DetailBack = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  p {
    font-family: "Nunito-Regular";
    font-size: 14px;
    color: #222;
  }
  a {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 2px solid #222;
    font-size: 20px;
    margin-left: 10px;
    color: #222;
  }
`;

export const DetailContent = styled.div`
  display: flex;
  margin: 0;
  flex-direction: column;
  width: 100%;
  margin-top: -140px;
  p {
    font-family: "Nunito-Regular";
    font-size: 16px;
    color: #222;
    &.padd {
      margin-bottom: 20px;
    }
    span {
      font-style: italic;
    }
    a {
      color: #222;
      text-decoration: none;
      transition: 0.5s;
    }
    a:hover {
      color: blue;
    }
  }
`;

export const DetailsNav = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 20px;
  margin-top: 50px;
`;

export const GroupControls = styled.div`
  display: flex;
  align-items: center;
  a {
    transition: 0.5s;
  }
  a:hover {
    background: #eee;
  }
  a.arrowL {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    border: 2px solid #222;
    border-radius: 50%;
    margin-right: 10px;
  }
  a.arrowR {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    border: 2px solid #222;
    border-radius: 50%;
    margin-left: 10px;
  }
`;

export const ChevLeft = styled(ChevronLeft)`
  color: #222;
`;
export const ChevRight = styled(ChevronRight)`
  color: #222;
`;
