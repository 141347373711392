import styled from "styled-components";
import { Colors, Fonts } from "styles/contracts";

export const ModalContent = styled.div.attrs({
  className: "modal-content",
})`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  justify-self: stretch;
  max-height: calc(100vh - 4rem);
  border-radius: 6px;
  box-shadow: #00000044 0 32px 24px -16px;
  overflow: hidden;
  width: 100%;
  @media screen and (min-width: 800px) {
    max-width: 460px;
  }
`;

export const ModalHeader = styled.div.attrs({
  className: "modal-header",
})`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid ${Colors.Light2};
  background-color: ${Colors.Light1};
  min-height: 60px;
  font-size: 18px;
  padding: 16px 24px;
  font-family: ${Fonts.NunitoExtraBold};
  color: ${Colors.Dark3};
`;

export const ModalBody = styled.div.attrs({
  className: "modal-body",
})`
  padding: 24px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;
