import React from "react";
import ContactForm from "./ContactForm";
import * as S from "./styles";

export const Main: React.FC = () => {
  return (
    <S.Main>
      <S.Restrainer>
        <S.MainContainer>
          <S.Header>
            <S.Title>Fale conosco</S.Title>
            <S.Subtitle>Preencha todos os campos abaixo</S.Subtitle>
          </S.Header>
          <S.FormContainer>
            <ContactForm />
          </S.FormContainer>
        </S.MainContainer>
      </S.Restrainer>
    </S.Main>
  );
};
