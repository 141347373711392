import styled from "styled-components";
import { Colors, ScreenSize } from "styles/contracts";

export const Container = styled.div`
  width: 100%;
  height: auto;
  row-gap: 20px;
`;

// members

export const MembersTitle = styled.h4`
  font-size: 16px;
  margin: 35px 0;
  text-align: center;
`;

export const Members = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  column-gap: 20px;
  row-gap: 20px;
  width: 100%;
  height: auto;
  margin: 0 auto;

  @media ${ScreenSize.TabletAndDown} {
    justify-content: center;
  }
  @media ${ScreenSize.SmallTabletAndDown} {
    justify-content: center;
  }
`;

export const Member = styled.div`
  display: flex;
  width: 100%;
  max-width: 200px;
  height: auto;
  flex-direction: column;
  align-items: center;
`;

export const ImageContainer = styled.figure`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 140px;
  overflow: hidden;
  border-radius: 50%;
`;

export const Image = styled.img`
  width: inherit;
  height: auto;
`;

export const Name = styled.p`
  font-size: 14px;
  margin-top: 16px;
  text-align: center;
`;

export const Role = styled.p`
  font-size: 14px;
  margin-top: 8px;
  text-align: center;
`;

// text

export const Content = styled.div`
  margin-top: 40px;
  padding-top: 64px;
  border-top: 1px solid ${Colors.Light4};
`;

export const Title = styled.h4`
  font-size: 16px;
  margin-bottom: 24px;
`;

export const Subtitle = styled.h5`
  margin-bottom: 16px;
`;

export const TextGrid = styled.div`
  display: grid;
  gap: 0 42px;
  grid-template-columns: repeat(2, 1fr);
  @media ${ScreenSize.TabletAndDown} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const Text = styled.p`
  margin-bottom: 26px;
`;
