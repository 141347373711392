export enum Fonts {
  NunitoRegular = "Nunito-Regular",
  NunitoSemiBold = "Nunito-SemiBold",
  NunitoBold = "Nunito-Bold",
  NunitoExtraBold = "Nunito-ExtraBold",
}

export enum Colors {
  Red3 = "#E52A49",
  Red4 = "#A52137",
  Green3 = "#00A859",
  Green4 = "#007847",
  Dark3 = "#10101D",
  Dark4 = "#0D0D0D",
  Dark5 = "#000000",
  Light1 = "#FFFFFF",
  Light2 = "#F2F2F2",
  Light3 = "#F1F1F1",
  Light4 = "#D6D6D6",
  Light5 = "#CCCCCC",
  Light6 = "#A7A7A7",
}

export enum ColorScheme {
  Text = Colors.Dark5,
  Header = Colors.Dark4,
}

export type ButtonVariant = "red" | "dark" | "light" | "void";

export enum ScreenSize {
  Mobile = "screen and (max-width: 639px)",
  SmallTablet = "screen and (min-width: 640px) and (max-width: 767px)",
  SmallTabletAndUp = "screen and (min-width: 640px)",
  SmallTabletAndDown = "screen and (max-width: 767px)",
  Tablet = "screen and (min-width: 768px) and (max-width: 1023px)",
  TabletAndUp = "screen and (min-width: 768px)",
  TabletAndDown = "screen and (max-width: 1023px)",
  Desktop = "screen and (min-width: 1024px)",
}

export interface ICard {
  zoomOnHover?: boolean;
}
