import React from "react";
import Scaffold from "layouts/Scaffold";
import { PageBanner } from "components/Shared";
import * as C from "components/Pages/Club/Mvv";

export const MissionPage: React.FC = () => {
  return (
    <Scaffold>
      <PageBanner
        image={require("assets/images/piscina.jpg")}
        title="MVV"
        subtitle="Missão, Visão e Valores"
      />
      <C.Main />
    </Scaffold>
  );
};
