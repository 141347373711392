import styled from "styled-components";
import { ICard } from "styles/contracts";
import { getCardZoomOnHoverStyle } from "styles/functions";

export const ImageCard = styled.button<ICard>`
  display: flex;
  flex-direction: column;
  height: 100%;
  color: currentColor;
  text-align: left;
  ${(props) => getCardZoomOnHoverStyle(props)};
`;

export const ImageContainer = styled.figure`
  display: flex;
  overflow: hidden;
  border-radius: 4px;
`;

export const CardImage = styled.img`
  max-width: 100%;
  height: auto;
`;
