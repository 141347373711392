import styled from "styled-components";

import { Colors, ScreenSize } from "styles/contracts";

export { Restrainer } from "styles/components";

export const Main = styled.div`
  background: ${Colors.Light3};
`;

export const MainContainer = styled.div`
  margin-top: -100px;
  background: ${Colors.Light1};
  border-radius: 8px;
  padding: 34px 60px 60px 60px;
  @media screen and (max-width: 800px) {
    margin-top: -32px;
  }
`;

export const ColumnsView = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 42px;
  @media ${ScreenSize.TabletAndDown} {
    grid-template-columns: 1fr;
    gap: 0px;
  }
`;

export const ContentBlock = styled.div`
  width: 100%;
`;

export const Title = styled.h4`
  font-size: 26px;
  margin: 26px 0;
`;

export const Subtitle = styled.h5`
  font-size: 18px;
  margin: 24px 0;
`;

export const RedSubtitle = styled.h5`
  color: ${Colors.Red3};
  font-size: 16px;
  margin: 24px 0;
`;

export const Text = styled.p`
  font-size: 16px;
  span {
    font-weight: bold;
  }
  &:not(:last-child) {
    margin-bottom: 24px;
  }
`;

export const GroupTime = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px dotted #000;
`;
export const ItemGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px;
  width: 100%;
  flex: 1;
  p.title {
    width: 100%;
    height: auto;
    font-weight: bold;
    margin-bottom: 10px;
    background: none;
  }
  p {
    width: 100%;
    height: auto;
  }
`;
