import { call, put } from "@redux-saga/core/effects";
import { api, notify, apiErrorHandler } from "services";
import {
  FetchArticleActions as Actions,
  FetchArticleRequestAction as RequestAction,
} from "store/ducks/articles";

export function* fetchArticleRequest(action: any) {
  const { id, onSuccess, onFailure }: RequestAction = action;
  try {
    const { data } = yield call(api.get, `articles/${id}`);
    onSuccess && onSuccess();
    yield put(Actions.success(data));
  } catch (error) {
    const { errorMessage } = apiErrorHandler(error);
    onFailure && onFailure();
    notify("error", errorMessage);
    yield put(Actions.failure(errorMessage));
  }
}
