import styled from "styled-components";
import { Colors } from "styles/contracts";
export { Restrainer } from "styles/components";

export const Main = styled.div`
  background: ${Colors.Light3};
  padding: 64px 0 96px 0;
`;

export const MainContainer = styled.div`
  background: ${Colors.Light1};
  border-radius: 8px;
  padding: 48px 60px 96px 60px;
`;

export const Title = styled.h4`
  font-size: 26px;
  margin-bottom: 24px;
  &:not(:first-of-type) {
    margin-top: 48px;
  }
`;

export const FAQList = styled.div`
  gap: 24px 0;
  display: flex;
  flex-direction: column;
`;

export const FAQItem = styled.div``;

export const Question = styled.h5`
  margin-bottom: 16px;
  font-size: 18px;
`;

export const Answer = styled.p`
  a {
    color: currentColor;
    text-decoration: underline;
  }
  span.break {
    display: block;
  }
`;
