export const trees = [
  {
    id: 1,
    name: "Abacateiro",
    vulgar_name: "Abacateiro",
    science_name: "Persea americana",
    thumb: "abacateiro.jpg",
    about:
      "O abacateiro, cujo fruto é o abacate, também designado como abacado, loiro-abacate e louro-abacate é uma espécie da família Lauraceae, de nome científico Persea americana. É uma árvore de grande porte, de crescimento rápido, ultrapassando os 30 metros de altura, nativa da América Central e México.",
    top_banner: "abacateiro.jpg",
    link: "https://pt.wikipedia.org/wiki/Persea_americana",
    slug: "abacateiro",
    artigo: "o",
  },
  {
    id: 2,
    name: "Abiu",
    vulgar_name: "Abiu",
    science_name: "Pouteria caimito",
    thumb: "abiu.jpg",
    about:
      "O abieiro, abiu, abiurana, abiurana-acariquara, abiorama, abio ou guapeva ou cabo-de-machado (região Centro-Oeste). Pouteria caimito é uma árvore frutífera da família Sapotaceae, nativa da Amazônia Central e da Mata Atlântica costeira do Brasil. A árvore é perenifólia, lactescente, com altura de 6 a 24 m.   ",
    top_banner: "abiu.jpg",
    link: "https://pt.wikipedia.org/wiki/Abieiro",
    slug: "abiu",
    artigo: "a",
  },
  {
    id: 3,
    name: "Ameixa-amarela",
    vulgar_name: "Ameixa-amarela",
    science_name: "Eriobotrya japonica",
    thumb: "ameixa-amarela.jpg",
    about:
      "A Eriobotrya japonica, comumente conhecida como nespereira ou ameixa-amarela em certas regiões do Brasil, é uma espécie de árvore, da subfamília Maloideae, da família das Rosáceas, pertencente ao tipo fisionómico dos microfanerófitos. Para se distinguir da sua congênere europeia, também lhe costumam chamar nespereira-do-japão, pese embora seja originária do sudeste da China.",
    top_banner: "ameixa-amarela.jpg",
    link: "https://pt.wikipedia.org/wiki/Persea_americana",
    slug: "ameixa-amarela",
    artigo: "a",
  },
  {
    id: 4,
    name: "Cabeludinha",
    vulgar_name: "Cabeludinha",
    science_name: "Plinia glomerata",
    thumb: "cabeludinha.jpg",
    about:
      "Plinia glomerata, popularmente conhecida como cabeludinha, é uma planta nativa do Rio de Janeiro, São Paulo e sul de Minas Gerais. Os frutos são ricos em Vitamina C (cerca de dez vezes mais do que na laranja) e possuem ação anti-inflamatória além de servirem de nutracêutico.",
    top_banner: "cabeludinha.jpg",
    link: "https://pt.wikipedia.org/wiki/Plinia_glomerata",
    slug: "cabeludinha",
    artigo: "a",
  },
  {
    id: 5,
    name: "Caju",
    vulgar_name: "Caju",
    science_name: "Anacardium occidentale",
    thumb: "caju.jpg",
    about:
      "O caju é muitas vezes tido como o fruto do cajueiro (Anacardium occidentale) quando, na verdade, trata-se de um pseudofruto. O que entendemos popularmente como &quote;caju&quote; se constitui de duas partes: o fruto propriamente dito, que é a castanha; e seu pedúnculo floral, o pseudofruto, um corpo piriforme, amarelo, rosado ou vermelho.",
    top_banner: "caju.jpg",
    link: "https://pt.wikipedia.org/wiki/Caju",
    slug: "caju",
    artigo: "o",
  },
  {
    id: 6,
    name: "Coité",
    vulgar_name: "Coité",
    science_name: "Cocos nucifera",
    thumb: "coite.jpg",
    about:
      "O abacateiro, cujo fruto é o abacate, também designado como abacado, loiro-abacate e louro-abacate é uma espécie da família Lauraceae, de nome científico Persea americana. É uma árvore de grande porte, de crescimento rápido, ultrapassando os 30 metros de altura, nativa da América Central e México.",
    top_banner: "coite.jpg",
    link: "https://pt.wikipedia.org/wiki/Crescentia_cujete",
    slug: "coite",
    artigo: "o",
  },
  {
    id: 7,
    name: "Coqueiro",
    vulgar_name: "Coqueiro",
    science_name: "Cocos nucifera ",
    thumb: "coqueiro.jpg",
    about:
      "O coqueiro (Cocos nucifera), é um membro da família Arecaceae (família das palmeiras). É a única espécie classificada no gênero Cocos. É uma planta que pode crescer até 30 metros de altura, com folhas pinadas de 4–6 m de comprimento, com pinas de 60–90 cm. As folhas caem completamente, deixando o tronco liso.",
    top_banner: "coqueiro.jpg",
    link: "https://pt.wikipedia.org/wiki/Coqueiro",
    slug: "coqueiro",
    artigo: "o",
  },
  {
    id: 8,
    name: "Graviola",
    vulgar_name: "Graviola",
    science_name: "Annona muricata",
    thumb: "graviola.jpg",
    about:
      "A graviola (Annona muricata) é uma planta originária das Antilhas, onde se encontra em estado silvestre. Prefere climas úmidos e baixa altitude. Em Minas Gerais é também conhecida como pinha. No Maranhão é chamada jacama e fruta-pão. A graviola é amplamente promovida como tratamento alternativo contra o câncer, mas não há evidências médicas de que seja eficaz no tratamento de câncer ou de qualquer outra doença.",
    top_banner: "graviola.jpg",
    link: "https://pt.wikipedia.org/wiki/Graviola",
    slug: "graviola",
    artigo: "a",
  },
  {
    id: 9,
    name: "Ingá-cabeludo",
    vulgar_name: "Ingá-cabeludo",
    science_name: "Inga blanchetiana",
    thumb: "inga-cabeludo.jpg",
    about:
      "Inga blanchetiana ou ingá-cabeludo é uma espécie vegetal da família Fabaceae. Árvore encontrada nas florestas úmidas do litoral do Estado da Bahia, principalmente nas proximidades da cidade de Entre Rios, no Brasil. A expansão agrícola e especulação imobiliária são as principais causas de ameaças a Inga blanchetiana.",
    top_banner: "inga-cabeludo.jpg",
    link: "https://pt.wikipedia.org/wiki/Inga_blanchetiana",
    slug: "inga-cabeludo",
    artigo: "o",
  },
  {
    id: 10,
    name: "Ingá-cipó",
    vulgar_name: "Ingá-cipó",
    science_name: "Inga edulis",
    thumb: "inga-cipo.jpg",
    about:
      "Ingá-cipó (Inga edulis) é uma espécie de ingá originária da Amazônia. Sua árvore pode alcançar 15m de altura. As folhas possuem cerca de 6 a 8 folíolos unidos a uma haste folhosa com pilosidade e de coloração ferrugíneo-tomentosa. As flores são aglomeradas com coloração branco-esverdeada e florescem praticamente o ano todo.",
    top_banner: "inga-cipo.jpg",
    link: "https://pt.wikipedia.org/wiki/Inga_blanchetiana",
    slug: "inga-cipo",
    artigo: "o",
  },
  {
    id: 11,
    name: "Ipê Amarelo",
    vulgar_name: "Ipê Amarelo",
    science_name: "Handroanthus serratifolius",
    thumb: "ipe-amarelo.jpg",
    about:
      "Ipê-amarelo-flor-de-algodão (Handroanthus serratifolius) é uma espécie de árvore do gênero Handroanthus. No Brasil também é conhecida como somente ipê-amarelo, ou então: ipê-amarelo-da-mata, ipê-ovo-de-macuco, ipê-pardo, ipê-tabaco, pau-d’arco-amarelo, piúva-amarela, tamurá-tuíra. É uma árvore com porte que varia de médio a grande e pode atingir de 15 a 30 metros de altura.",
    top_banner: "ipe-amarelo.jpg",
    link: "https://pt.wikipedia.org/wiki/Ipê-amarelo-flor-de-algodão",
    slug: "ipe-amarelo",
    artigo: "o",
  },
  {
    id: 12,
    name: "Jaboticaba",
    vulgar_name: "Jaboticaba",
    science_name: "Plinia cauliflora",
    thumb: "jaboticaba.jpg",
    about:
      "A jabuticaba ou jaboticaba é o fruto da jaboticabeira ou jabuticabeira, uma árvore frutífera brasileira da família das mirtáceas, nativa da Mata Atlântica. Com a recente mudança na nomenclatura botânica, há divergências sobre a classificação da espécie: Myrciaria cauliflora (Mart.) O.Berg 1854, Plinia trunciflora (O.Berg) Kausel 1956 ou Plinia cauliflora (Mart.) Kausel 1956.",
    top_banner: "jaboticaba.jpg",
    link: "https://pt.wikipedia.org/wiki/Jabuticaba",
    slug: "jaboticaba",
    artigo: "a",
  },
  {
    id: 13,
    name: "Jambo-amarelo",
    vulgar_name: "Jambo-amarelo",
    science_name: "Syzygium jambos",
    thumb: "jambo-amarelo.jpg",
    about:
      "O jambo é o fruto de uma espécie de Jambeiro, Syzygium jambos. Pertence ao gênero Syzygium e à família Myrtaceae, que inclui também a goiaba, a pitanga, o jamelão, a jabuticaba e o eucalipto. É bem conhecido como jambo-amarelo ou jambo-amarelado. São frutos piriformes (em forma de pera), com casca lisa e cerosa, rosada, esbranquiçada, amarela ou laranja-amarelada, polpa consistente na cor branca, e uma ou mais sementes de formato esférico no seu interior",
    top_banner: "jambo-amarelo.jpg",
    link: "https://pt.wikipedia.org/wiki/Jabuticaba",
    slug: "jambo-amarelo",
    artigo: "o",
  },
  {
    id: 14,
    name: "Jambo-rosa",
    vulgar_name: "Jambo-rosa",
    science_name: "Syzygium malaccense L.O",
    thumb: "jambo-rosa.jpg",
    about:
      "Syzygium malaccense L.O (antigamente, Eugenia malaccensis), popularmente conhecido como jambeiro, jambo-rosa, jambo-vermelho, jambo-roxo, jambroche e eugenia é uma espécie da família Myrtaceae, a qual também inclui espécies como a goiabeira, a pitangueira, o jamelão, a jabuticaba e o eucalipto. O seu fruto, chamado jambo, é uma drupa piriforme (em forma de pera), com casca com cor variando de vermelho a roxo, polpa consistente e branca, adocicada e levemente ácida, e uma semente de formato esférico, ou duas hemisféricas, no seu interior.",
    top_banner: "jambo-rosa.jpg",
    link: "https://pt.wikipedia.org/wiki/Jambo-vermelho",
    slug: "jambo-rosa",
    artigo: "o",
  },
  {
    id: 15,
    name: "Jatobá",
    vulgar_name: "Jatobá",
    science_name: "Hymenaea courbaril",
    thumb: "jatoba.jpg",
    about:
      "O jatobá-verdadeiro, jatobazeiro ou apenas jatobá, (nome científico: Hymenaea courbaril) é uma árvore da família das fabáceas. É a espécie arbórea dominante na floresta estacional semidecidual submontana. A espécie pode alcançar 40 metros de altura e 2 metros de diâmetro.",
    top_banner: "jatoba.jpg",
    link: "https://pt.wikipedia.org/wiki/Jatob%C3%A1",
    slug: "jatoba",
    artigo: "o",
  },
  {
    id: 16,
    name: "Jenipapo",
    vulgar_name: "Jenipapo",
    science_name: "Genipa americana",
    thumb: "jenipapo.jpg",
    about:
      "Jenipapo é o fruto do jenipapeiro (Genipa americana), uma árvore que chega a vinte metros de altura e é da família Rubiaceae, a mesma do café. É encontrada em toda a América tropical. Em guarani, jenipapo significa &quote;fruta que serve para pintar&quote;. Isso porque, do sumo do fruto verde, se extrai uma tinta com a qual se pode pintar a pele, paredes, cerâmica etc.",
    top_banner: "jenipapo.jpg",
    link: "https://pt.wikipedia.org/wiki/Jatob%C3%A1",
    slug: "jenipapo",
    artigo: "o",
  },
  {
    id: 17,
    name: "Macadâmia",
    vulgar_name: "Macadâmia",
    science_name: "Macadamia integrifolia",
    thumb: "macadamia.jpg",
    about:
      "A macadâmia é um fruto doce extraído de uma árvore originária da Austrália, pertencente a uma das espécies do género Macadamia são exploradas comercialmente: a Macadamia integrifolia, originária de Queensland, onde cresce em florestas muito úmidas, e a Macadamia tetraphylla, originária da Nova Gales do Sul. O nome da planta foi dado pelo botânico Ferdinand von Mueller, o seu descobridor, em honra a um colega seu, o naturalista e político australiano de origem escocesa John Macadam.",
    top_banner: "macadamia.jpg",
    link: "https://pt.wikipedia.org/wiki/Macad%C3%A2mia",
    slug: "macadamia",
    artigo: "a",
  },
  {
    id: 18,
    name: "Olho de Boi",
    vulgar_name: "Olho de Boi",
    science_name: "Leucanthemum sylvaticum",
    thumb: "olho-de-boi.jpg",
    about:
      "Leucanthemum sylvaticum é uma espécie de planta com flor pertencente à família Asteraceae. Os seus nomes comuns são bem-me-quer, bem-me-quer-dos-floristas, margarida-branca, margarida-maior, margarita-maior, olho-de-boi, olho-de-boi-dos-ervanários. Trata-se de uma espécie presente no território português, nomeadamente em Portugal Continental.",
    top_banner: "olho-de-boi.jpg",
    link: "https://pt.wikipedia.org/wiki/Leucanthemum_sylvaticum",
    slug: "olho-de-boi",
    artigo: "o",
  },
  {
    id: 19,
    name: "Pau Brasil",
    vulgar_name: "Pau Brasil",
    science_name: "Paubrasilia",
    thumb: "pau-brasil.jpg",
    about:
      "O pau-brasil ou pau-de-pernambuco (atual Paubrasilia echinata Lam. — Gagnon, H.C.Lima & G.P.Lewis; antiga Caesalpinia echinata Lam.), também chamado arabutã, ibirapiranga, ibirapitá, ibirapitanga, orabutã, pau-de-tinta, pau-pernambuco e pau-rosado, é uma árvore leguminosa nativa da Mata Atlântica, no Brasil.",
    top_banner: "pau-brasil.jpg",
    link: "https://pt.wikipedia.org/wiki/Leucanthemum_sylvaticum",
    slug: "pau-brasil",
    artigo: "o",
  },
  {
    id: 20,
    name: "Pitanga",
    vulgar_name: "Pitanga",
    science_name: "Eugenia uniflora L",
    thumb: "pitanga.jpg",
    about:
      "A pitanga é o fruto da pitangueira (Eugenia uniflora L.), dicotiledônea da família das mirtáceas. Tem a forma de bolinhas globosas e carnosas, de cor vermelha (a mais comum), laranja, amarela ou preta. Na mesma árvore, o fruto poderá ter desde as cores verde, amarelo e alaranjado até a cor vermelho-intenso, de acordo com o grau de maturação",
    top_banner: "pitanga.jpg",
    link: "https://pt.wikipedia.org/wiki/Pitanga",
    slug: "pitanga",
    artigo: "a",
  },
];
