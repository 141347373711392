import styled from "styled-components";
import { Colors } from "styles/contracts";
export { Restrainer } from "styles/components";

export const Main = styled.div`
  background: ${Colors.Light3};
  padding-top: 64px;
`;

export const MainContainer = styled.div`
  background: ${Colors.Light1};
  border-radius: 8px;
  padding: 48px 60px 96px 60px;
`;

export const MainRestrainer = styled.div`
  max-width: 780px;
  width: 100%;
  margin: 0 auto;
`;

// image

export const ImageContainer = styled.figure`
  max-width: 680px;
  width: 100%;
  margin: 0 auto 56px auto;
`;

export const Image = styled.img`
  max-width: 100%;
  height: auto;
`;

// category and date

export const Info = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0 4px;
  margin: 26px 0;
`;

export const Category = styled.span`
  color: ${Colors.Red3};
`;

export const Dot = styled.span`
  color: ${Colors.Dark3};
  font-size: 1.4rem;
`;

export const DateTime = styled.span``;

// content

export const Title = styled.h4`
  font-size: 26px;
`;

export const Content = styled.article`
  font-size: 16px;
  p {
    &:not(:last-child) {
      margin-bottom: 26px;
    }
  }
`;
