import React from "react";
import Scaffold from "layouts/Scaffold";
import { PageBanner } from "components/Shared";
import * as C from "components/Pages/Club/Cine9DeAbril";

export const Cine9DeAbrilPage: React.FC = () => {
  return (
    <Scaffold>
      <PageBanner image={require("assets/images/cine-9-de-abril-banner.jpg")} />
      <C.Main />
    </Scaffold>
  );
};
