import React, { useCallback } from "react";

import { Accordion } from "components/Shared";
import { AnnualBalance, annualBalances } from "data/transparency";

import * as S from "./styles";

const getDownloadUrl = (year: number, period: string): string => {
  const baseUrl = "/site/cbc-documentos";
  const filename = `balancete_${period.replace(
    "ç",
    "c"
  )}_${year}.pdf`.toUpperCase();
  return `${baseUrl}/${filename}`;
};

const getIdentifier = (annualBalance: AnnualBalance): string => {
  return `MONTHLY_BALANCES_${annualBalance.year}`;
};

interface Props {
  activeAccordion: string;
  onActivate: (activeAccordion: string) => void;
}

export const MonthlyBalances: React.FC<Props> = ({
  activeAccordion,
  onActivate,
}) => {
  const DocumentList = useCallback(
    ({ balance }: { balance: AnnualBalance }): JSX.Element => {
      return (
        <S.Documents>
          {balance.periods.map((period, index) => (
            <S.Document
              key={index}
              target="_blank"
              href={getDownloadUrl(balance.year, period)}
            >
              Balancete de {period}
            </S.Document>
          ))}
        </S.Documents>
      );
    },
    []
  );

  const handleAccordion = useCallback(
    (annualBalance: AnnualBalance): void => {
      onActivate(getIdentifier(annualBalance));
    },
    [onActivate]
  );
  return (
    <S.Container>
      <S.Accordions>
        {annualBalances.map((annualBalance, index) => (
          <Accordion
            key={index}
            title={`Balancetes mensais de ${annualBalance.year}`}
            isOpen={activeAccordion === getIdentifier(annualBalance)}
            onClick={() => handleAccordion(annualBalance)}
          >
            <DocumentList balance={annualBalance} />
          </Accordion>
        ))}
      </S.Accordions>
    </S.Container>
  );
};
