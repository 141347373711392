import React, { useState } from "react";

import { Accordion } from "components/Shared";

import * as C from "./AccordionContents";
import * as S from "./styles";

interface Item {
  title: string;
  content: React.ReactNode;
}

const items: Item[] = [
  {
    title: "Assembleia Geral",
    content: <C.GeneralAssembly />,
  },
  {
    title: "Assembleia dos Sócios Proprietários",
    content: <C.OwnerPartnersAssembly />,
  },
  {
    title: "Grande Conselho",
    content: <C.GreatConcil />,
  },
  {
    title: "Conselho Deliberativo",
    content: <C.DeliberativeConcil />,
  },
  {
    title: "Diretoria",
    content: <C.ExecutiveBoard />,
  },
  {
    title: "Ex presidentes",
    content: <C.ExPresidents />,
  },
  {
    title: "Beneméritos",
    content: <C.Benefactors />,
  },
  {
    title: "Organogramas",
    content: <C.Charts />,
  },
];

export const Main: React.FC = () => {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  return (
    <S.Main>
      <S.Restrainer>
        <S.MainContainer>
          <S.Title>Os poderes do clube</S.Title>
          <S.Accordions>
            {items.map((item, index) => (
              <Accordion
                key={index}
                isOpen={selectedIndex === index}
                onClick={() => setSelectedIndex(index)}
                title={item.title}
              >
                {item.content}
              </Accordion>
            ))}
          </S.Accordions>
        </S.MainContainer>
      </S.Restrainer>
    </S.Main>
  );
};
