import React, { useCallback, useEffect, useRef, useState } from "react";
import { NavigationMenuEntry } from "contracts/Common";
import * as S from "./styles";

interface Props {
  entry: NavigationMenuEntry;
  depth: number;
}

const SubmenuEntry: React.FC<Props> = ({ entry, depth }) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const popoverContainerRef = useRef<HTMLDivElement>(null);

  const HandlerComponent = useCallback(
    ({ entry }: { entry: NavigationMenuEntry }): JSX.Element => {
      if (entry?.path) {
        return <S.LinkHandler to={entry.path}>{entry.label}</S.LinkHandler>;
      }

      if (entry?.externalPath) {
        return (
          <S.ExternalLinkHandler href={entry.externalPath} target="_blank">
            {entry.label}
          </S.ExternalLinkHandler>
        );
      }

      if (entry?.action) {
        return (
          <S.ActionHandler onClick={entry.action}>
            {entry.label}
          </S.ActionHandler>
        );
      }

      if (entry?.children) {
        return (
          <S.PopoverContainer ref={popoverContainerRef}>
            <S.PopoverHandler onClick={() => setIsActive(true)}>
              <span>{entry.label}</span> <S.CaretRightIcon />
              {isActive && (
                <S.AnimatedContainer>
                  <S.TriangleContainer>
                    <S.TriangleIcon />
                  </S.TriangleContainer>
                  <S.OptionsContainer>
                    {entry.children.map((entry, index) => (
                      <SubmenuEntry
                        key={index}
                        entry={entry}
                        depth={depth + 1}
                      />
                    ))}
                  </S.OptionsContainer>
                </S.AnimatedContainer>
              )}
            </S.PopoverHandler>
          </S.PopoverContainer>
        );
      }

      return <></>;
    },
    [depth, isActive]
  );

  const handleClickOutside = useCallback((event) => {
    if (!popoverContainerRef?.current?.contains(event.target)) {
      setIsActive(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  return <HandlerComponent entry={entry} />;
};

export default SubmenuEntry;
