interface Member {
  name: string;
  image: string;
  role?: string;
}

export const greatConcil: Member[] = [
  // {
  //   name: "Alyson Santana e Silva",
  //   image: "alyson-santana.jpg",
  //   role: "Presidente",
  // },
  {
    name: "Amir Felix Teixeira",
    image: "no-photo.jpg",
  },
  {
    name: "Gustavo Tramontim de Matos",
    image: "gustavo-tramontin-de-mattos.jpg",
    role: "Presidente Executivo",
  },

  {
    name: "Ana Paula Gonçalvez",
    image: "ana-paula-goncalvez-silva.jpg",
    role: "Representante CSN",
  },

  // {
  //   name: "Alcir Candido Moraes Brandão",
  //   image: "alcir-candido-moraes-brandao.jpg",
  // },
  {
    name: "Alvaro Dutra Poncho",
    image: "alvaro-dutra-poncho.jpg",
  },
  {
    name: "Antônio Carlos Faria Miranda",
    image: "antonio-carlos-faria-miranda.jpg",
  },
  {
    name: "Antonio Elias de Moura",
    image: "antonio-elias-de-moura.jpg",
  },
  {
    name: "Delio de Paiva Amorim",
    image: "delio-de-paiva-amorim.jpg",
  },
  {
    name: "Evandro Ruy da Costa Lima",
    image: "evandro-ruy-da-costa-lima.jpg",
  },
  {
    name: "Expedito Gonçalves de Aquino",
    image: "expedito-goncalves-de-aquino.jpg",
    role: "Licenciado",
  },
  {
    name: "Fause Feres José",
    image: "fause-feres-jose.jpg",
  },
  {
    name: "Geraldo Bruno Bastos",
    image: "geraldo-bruno-bastos.jpg",
  },
  {
    name: "Getúlio de Moura Cerqueira",
    image: "getulio-de-moura-cerqueira.jpg",
  },
  // {
  //   name: "Fernando Vicente Fontes",
  //   image: "fernando-vicente-fontes.jpg",
  // },
  {
    name: "Idelfonso Novas",
    image: "ildelfonso-novaes.jpg",
  },
  {
    name: "Jair Azevedo Lima Filho",
    image: "no-photo.jpg",
  },
  {
    name: "João Paulo Silva",
    image: "joao-paulo-silva.jpg",
  },
  {
    name: "Luis Barroso Loques",
    image: "luis-barroso-loques.jpg",
  },
  {
    name: "Mout Ugliosi Telles",
    image: "mout-ugliosi-telles.jpg",
  },
  {
    name: "Nilton José Bueno",
    image: "nilton-jose-bueno.jpg",
  },
  {
    name: "Vera Lucia Vidigal Moreira",
    image: "vera-lucia-vidigal-moreira.jpg",
  },
  {
    name: "Reinaldo Hidalgo Ferreira",
    image: "no-photo.jpg",
  },
  {
    name: "Robson Batista dos Santos",
    image: "robson-batista-santos.jpg",
  },
  {
    name: "Ronaldo Alcedo Reis Alves",
    image: "ronaldo-alcedo-reis.jpg",
  },
  {
    name: "Valmir Sérgio Ribeiro",
    image: "valmir-sergio-ribeiro.jpg",
  },
  {
    name: "Wlader Dutra Miranda",
    image: "wlader-dutra-miranda.jpg",
  },
  {
    name: "Espedito Missel do Carmo",
    image: "espedito-missel-do-carmo.jpg",
  },
];
