import styled from "styled-components";
import { StylesConfig, GroupBase } from "react-select";
import { Colors } from "styles/contracts";
import { FieldContainer } from "styles/components";
import { OptionBase } from "contracts/Common";
export { FieldError, FieldLabel } from "styles/components";

// Select style
interface SelectStyle
  extends StylesConfig<OptionBase, false, GroupBase<OptionBase>> {}

export const DefaultSelectStyle: SelectStyle = {
  container: (provided) => ({
    ...provided,
    height: "3rem",
    order: 2,
  }),
  control: (provided) => ({
    ...provided,
    height: "inherit",
    borderColor: Colors.Light5,
    borderRadius: "6px",
    background: Colors.Light2,
    outline: "none",
    boxShadow: "none",
    ":hover": {
      borderColor: Colors.Light5,
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "0 16px",
    fontSize: "inherit",
  }),
  menuList: (provided) => ({
    ...provided,
    padding: 0,
    background: "inherit",
    borderColor: Colors.Light5,
    borderRadius: "6px",
  }),
  option: (provided, state) => ({
    ...provided,
    background: state.isFocused ? Colors.Dark5 : "inherit",
    color: state.isFocused ? Colors.Light1 : "inherit",
    fontSize: "inherit",
    ":hover": {
      background: Colors.Dark5,
    },
  }),
};

export const Container = styled(FieldContainer)``;
