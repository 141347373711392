import React, { useRef, useCallback, useEffect } from "react";
import { useField } from "@unform/core";

import * as S from "./styles";

type Props = JSX.IntrinsicElements["input"] & {
  name: string;
  id?: string;
  label?: string;
  placeholder?: string;
};

export const Input: React.FC<Props> = ({
  name,
  id,
  label,
  placeholder = " ",
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  const LabelComponent = useCallback((): JSX.Element => {
    if (!label) return <></>;
    return <S.FieldLabel htmlFor={id || fieldName}>{label}</S.FieldLabel>;
  }, [fieldName, id, label]);

  const ErrorComponent = useCallback((): JSX.Element => {
    if (!error) return <></>;
    return <S.FieldError>{error}</S.FieldError>;
  }, [error]);

  useEffect(() => {
    registerField({
      name: fieldName,
      path: "value",
      ref: inputRef.current,
      setValue(ref: any, value: string) {
        ref.value = value;
      },
      clearValue(ref: any) {
        ref.value = "";
      },
    });
  }, [fieldName, registerField]);

  return (
    <S.Container>
      <LabelComponent />
      <input
        name={fieldName}
        id={id || fieldName}
        ref={inputRef}
        defaultValue={defaultValue}
        placeholder={placeholder}
        {...rest}
      />
      <ErrorComponent />
    </S.Container>
  );
};
