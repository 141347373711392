import { format, isValid } from "date-fns";
import { ptBR } from "date-fns/locale";

export class Formatter {
  public static date(
    dateString: string,
    options = { format: "dd/MM/yyyy" }
  ): string {
    return format(this.getValidDate(dateString), options.format, {
      locale: ptBR,
    });
  }

  public static dayOfWeek(
    dateString: string,
    options = { format: "EEEE" }
  ): string {
    return format(this.getValidDate(dateString), options.format, {
      locale: ptBR,
    });
  }

  private static getValidDate(dateString: string): Date {
    const validString = dateString.replace(" ", "T");
    if (!isValid(new Date(validString))) {
      throw new Error(
        `A string ${dateString} não pode ser convertida em um objeto do tipo Date`
      );
    }
    return new Date(validString);
  }
}
