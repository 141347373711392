import styled from "styled-components";
import { ReactComponent as Phone } from "assets/icons/phone.svg";
import { ReactComponent as Calendar } from "assets/icons/calendar.svg";
import { Colors, Fonts } from "styles/contracts";

export const Sidebar = styled.aside`
  position: fixed;
  z-index: 9;
  display: flex;
  flex-direction: column;
  max-width: 400px;
  width: 100%;
  height: 100vh;
  top: 0;
  left: -400px;
  background: ${Colors.Light2};
  transition: left 300ms linear;
  &.active {
    left: 0;
    box-shadow: 0 0 8px 100vw #00000066, 0 4px 8px 4px #00000033;
  }
`;

// sidebar header
export const SidebarHeader = styled.div`
  height: 52px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const CloseButton = styled.button`
  width: 44px;
  height: 44px;
  font-size: 24px;
  font-family: ${Fonts.NunitoSemiBold};
  margin-left: auto;
`;

// sidebar body
export const SidebarBody = styled.div`
  flex: 1 0 calc(100% - 52px - 110px);
  overflow-y: auto;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  ::-webkit-scrollbar:vertical {
    width: 10px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    border: 2px solid ${Colors.Light2};
    background-color: ${Colors.Light5};
  }
  ::-webkit-scrollbar-track {
    background-color: ${Colors.Light2};
    border-radius: 4px;
  }
`;

// sidebar footer
export const SidebarFooter = styled.div`
  height: 110px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 16px;
  gap: 8px;
  background: ${Colors.Dark3};
  color: ${Colors.Light2};
  border-top: 2px solid ${Colors.Light5};
`;

export const CalendarContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0 12px;
`;

export const CalendarIcon = styled(Calendar)`
  path {
    fill: currentColor;
  }
`;

export const PhoneContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0 12px;
`;

export const PhoneIcon = styled(Phone)`
  path {
    fill: currentColor;
  }
`;
