import React from "react";

import * as S from "./styles";

export const Main: React.FC = () => {
  return (
    <S.Main>
      <S.Restrainer>
        <S.MainContainer>
          <S.Title>Sede Social</S.Title>
          <S.Text>
            Nossa sede social está está localizada em um edifício com 8 andares
            na área comercial mais valorizada da cidade.
          </S.Text>
          <S.Text>
            <span>Endereço:</span> Rua General Oswaldo pinto da Veiga, 231,
            Sobre loja - 23 lojas, Edificio Guilherme Guinle, Vila Santa
            Cecília.
          </S.Text>
          <S.Text>
            <span>Térreo:</span> 5 lojas
            <br />
            <span>3° andar:</span> Salão de festas
            <br />
            <span>4° andar:</span> Restaurante, Cozinha e Bar
            <br />
            <span>5° andar:</span> Salão de Sinuca, Salão de Balé e Sala de
            Reuniões
            <br />
            <span>6°, 7° e 8° andares</span> 45 Salas Comerciais
          </S.Text>
          <S.Text>
            <span>Telefone:</span> (24) 2107-8269
          </S.Text>
          <S.Text>
            <span>Horário de funcionamento das lojas:</span> 08h:30 às 18h:30
            (pode variar de acordo com o horário comercial).
          </S.Text>
          <S.Text>
            <span>Horário de funcionamento do prédio:</span> Prédio (em função
            dos cursos): fechamento as 22h:30.
          </S.Text>
        </S.MainContainer>
      </S.Restrainer>
    </S.Main>
  );
};
